import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Progress, Row } from "antd";
import { MailOutlined, ProfileOutlined, RiseOutlined } from "@ant-design/icons";
import Pluralize from "react-pluralize";
import Loading from "../../components/Loading";

import { campaignSettingsSumOverviewData, campaignStatsOverviewData } from "../../appRedux/actions/CampaignOverview";

const OverviewRow = ({ dataset }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const welcomeStats = useSelector(({ campaignOverviewData }) => campaignOverviewData.welcomeStats.data);
  const welcomeStatsErrors = useSelector(({ campaignOverviewData }) => campaignOverviewData.welcomeStats.error);
  const welcomeStatsLoading = useSelector(({ campaignOverviewData }) => campaignOverviewData.welcomeStats.loading);
  const settingsSum = useSelector(({ campaignOverviewData }) => campaignOverviewData.settingsSum.data);
  const settingsSumErrors = useSelector(({ campaignOverviewData }) => campaignOverviewData.settingsSum.error);
  const settingsSumLoading = useSelector(({ campaignOverviewData }) => campaignOverviewData.settingsSum.loading);

  const mounted = useRef(false);

  useEffect(() => {
    if (
      welcomeStatsErrors &&
      welcomeStats &&
      settingsSum &&
      settingsSumErrors &&
      (dataset.name in welcomeStatsErrors || dataset.name in welcomeStats) &&
      (dataset.name in settingsSumErrors || dataset.name in settingsSum)
    ) {
      setIsLoading(false);
    }
  }, [settingsSumErrors, welcomeStatsErrors]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) getData(dataset);
    return () => {
      mounted.current = false;
    };
  }, []);

  const getData = (dataset) => {
    dispatch(campaignSettingsSumOverviewData(dataset));
    dispatch(campaignStatsOverviewData(dataset));
  };

  if (isLoading) {
    return (
      <Row className="gx-mt-4">
        <Col xl={24} lg={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Loading />
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  if (isError) {
    return <p>No data</p>;
  }

  return (
    <Row className="gx-mt-4">
      <Col xl={24} lg={24}>
        <div className="gx-card">
          <div className="gx-card-body">
            <Row>
              <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                <div className="gx-wel-ema gx-pt-xl-2">
                  <h1 className="gx-mb-3">{dataset.readableName}</h1>
                  <p className="gx-fs-sm gx-text-uppercase"></p>
                  {dataset.name in welcomeStats ? (
                    <ul className="gx-list-group">
                      <li>
                        <MailOutlined />
                        <span>
                          <Pluralize singular="Campaign" plural={false} count={welcomeStats[dataset.name]?.campaigns} />
                        </span>
                      </li>
                      <li>
                        <ProfileOutlined />
                        <span>
                          <Pluralize
                            singular="Insertion Order"
                            plural={false}
                            count={welcomeStats[dataset.name]?.insertion_orders}
                          />
                        </span>
                      </li>
                      <li>
                        <RiseOutlined />
                        <span>
                          <Pluralize
                            singular="Line Item"
                            plural={false}
                            count={welcomeStats[dataset.name]?.line_items}
                          />
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </Col>

              <Col xl={18} lg={24} md={24} sm={24} xs={24}>
                <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-5">
                  <Row type="flex" align="middle">
                    {dataset.name in settingsSum ? (
                      <>
                        <Col xs={12} md={6}>
                          <div>
                            <h6 className="ant-card-head-title text-center">Settings checked</h6>
                          </div>
                          <Progress
                            width={80}
                            className="ant-progress-fix"
                            type="circle"
                            strokeColor="#000000"
                            status={"normal"}
                            percent={
                              ((settingsSum[dataset.name]?.bad +
                                settingsSum[dataset.name]?.good +
                                settingsSum[dataset.name]?.na) *
                                100) /
                              settingsSum[dataset.name]?.total
                            }
                            format={() => {
                              return (
                                settingsSum[dataset.name]?.bad +
                                settingsSum[dataset.name]?.good +
                                settingsSum[dataset.name]?.na
                              );
                            }}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <div>
                            <h6 className="ant-card-head-title text-center">Best Practice Settings</h6>
                          </div>
                          <Progress
                            width={80}
                            className="ant-progress-fix"
                            type="circle"
                            strokeColor="#93DD3F"
                            status={"normal"}
                            percent={
                              (settingsSum[dataset.name]?.good * 100) /
                              (settingsSum[dataset.name]?.total - settingsSum[dataset.name]?.na)
                            }
                            format={() => {
                              return settingsSum[dataset.name]?.good;
                            }}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <div>
                            <h6 className="ant-card-head-title text-center">Settings to Check</h6>
                          </div>
                          <Progress
                            width={80}
                            className="ant-progress-fix"
                            type="circle"
                            strokeColor="#DA3B26"
                            status={"normal"}
                            percent={
                              (settingsSum[dataset.name]?.bad * 100) /
                              (settingsSum[dataset.name]?.total - settingsSum[dataset.name]?.na)
                            }
                            format={() => {
                              return settingsSum[dataset.name]?.bad;
                            }}
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <div>
                            <h6 className="ant-card-head-title text-center">NA Settings</h6>
                          </div>
                          <Progress
                            width={80}
                            className="ant-progress-fix"
                            type="circle"
                            strokeColor="#5BC1BE"
                            status={"normal"}
                            percent={(settingsSum[dataset.name]?.na * 100) / settingsSum[dataset.name]?.total}
                            format={() => {
                              return settingsSum[dataset.name]?.na;
                            }}
                          />
                        </Col>{" "}
                      </>
                    ) : null}
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default OverviewRow;
