import {
  FETCH_ORGANIZATION_PARTNERS_BEGIN,
  FETCH_ORGANIZATION_PARTNERS_SUCCESS,
  FETCH_ORGANIZATION_PARTNERS_FAILURE,
  FETCH_ADVERTISERS_BEGIN,
  FETCH_ADVERTISERS_SUCCESS,
  FETCH_ADVERTISERS_FAILURE,
  FETCH_ADVERTISERS_CREATE_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  advertisers: {
    loading: true,
    status: null,
    error: null,
    data: [],
  },
  organizationPartners: {
    loading: false,
    status: null,
    error: null,
    data: [],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ADVERTISERS_BEGIN: {
      return {
        ...state,
        advertisers: {
          loading: true,
          status: null,
          error: null,
        },
      };
    }
    case FETCH_ADVERTISERS_SUCCESS: {
      return {
        ...state,
        advertisers: {
          loading: false,
          status: "success",
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_ADVERTISERS_CREATE_SUCCESS: {
      return {
        ...state,
        advertisers: {
          loading: true,
          status: "created",
          error: null,
        },
      };
    }

    case FETCH_ADVERTISERS_FAILURE: {
      return {
        ...state,
        advertisers: {
          loading: false,
          status: "failure",
          error: action.payload,
        },
      };
    }

    case FETCH_ORGANIZATION_PARTNERS_BEGIN: {
      return {
        ...state,
        organizationPartners: {
          loading: true,
          status: null,
          error: null,
        },
      };
    }
    case FETCH_ORGANIZATION_PARTNERS_SUCCESS: {
      return {
        ...state,
        organizationPartners: {
          loading: false,
          status: "success",
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_ORGANIZATION_PARTNERS_FAILURE: {
      return {
        ...state,
        organizationPartners: {
          loading: false,
          status: "failure",
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
