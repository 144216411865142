import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Alert } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import logo from "./../assets/images/logo-dark.png";
import { userResetPassword } from "../appRedux/actions/Auth";

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [token, setToken] = useState();
  const [status, setStatus] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setToken(props.match.params.token);
  }, []);

  const handleSubmit = (values) => {
    setSubmitting(true);
    values.token = token;
    dispatch(userResetPassword(values)).then((data) => {
      setSubmitting(false);
      setStatus(data);
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} alt="" className="main-logo" />
        </div>
        <div className="gx-login-content">
          {status ? <Alert message={status.response} type={status.type} showIcon className="gx-mb-3" /> : ""}
          {!status || status.type === "error" ? (
            <>
              <div className="gx-mb-4">
                <h2>Reset Password</h2>
                <p>
                  <IntlMessages id="appModule.enterPasswordReset" />
                </p>
              </div>

              <Form autocomplete="off" form={form} onFinish={handleSubmit} className="gx-login-form gx-form-row0">
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    { validator: validateToNextPassword },
                  ]}
                >
                  <Input type="password" placeholder="New Password" />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  rules={[
                    { required: true, message: "Please confirm your password!" },
                    { validator: compareToFirstPassword },
                  ]}
                >
                  <Input placeholder="Retype New Password" type="password" autoComplete="new-password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    <IntlMessages id="app.userAuth.reset" />
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <Link to="/signin" className="ant-btn ant-btn-primary">
              <IntlMessages id="app.userAuth.signIn" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

// const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default ResetPassword;
