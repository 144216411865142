import React from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { valueWithUnits } from "./../../util/Helpers";

const ReversedValueAxis = ({ data, attr }) => {
  const sorted = data
    ? data.sort(function (a, b) {
        return a[attr.main] - b[attr.main];
      })
    : false;

  const config = {
    type: "serial",
    theme: "light",
    legend: {
      equalWidths: false,
      useGraphSettings: true,
      valueAlign: "left",
      valueWidth: 120,
    },
    dataProvider: sorted,

    valueAxes: [
      {
        id: "distanceAxis",
        axisAlpha: 0,
        gridAlpha: 0,
        position: "left",
        title: "Total spend",
      },
      {
        id: "LeftAxis",
        axisAlpha: 0,
        gridAlpha: 0,
        labelsEnabled: false,
        position: "right",
      },
      {
        id: "RightAxis",
        axisAlpha: 0,
        gridAlpha: 0,
        inside: true,
        position: "right",
        title: attr.right.label,
      },
    ],
    graphs: [
      {
        alphaField: "alpha",
        balloonText: valueWithUnits("[[value]]", "currency"),
        dashLengthField: "dashLength",
        fillAlphas: 1,
        legendPeriodValueText: ` - `,
        legendValueText: valueWithUnits("[[value]]", "currency"),
        title: "Total spend",
        type: "column",
        valueField: "TotalSpend",
        valueAxis: "TotalSpendAxis",
        fillColors: "#8f98a6",
        fillColorsField: "#8f98a6",
        lineColor: "#8f98a6",
      },
      {
        balloonText: valueWithUnits("[[value]]", attr.left.units),
        bullet: "round",
        bulletBorderAlpha: 1,
        useLineColorForBulletBorder: true,
        bulletColor: "#FFFFFF",
        bulletSizeField: "townSize",
        dashLengthField: "dashLength",
        labelPosition: "right",
        legendValueText: valueWithUnits("[[value]]", attr.left.units),
        title: attr.left.label,
        fillAlphas: 0,
        valueField: attr.left.label,
        valueAxis: "LeftAxis",
        bulletBorderColor: "#ED7D31",
        legendColor: "#ED7D31",
        lineColor: "#ED7D31",
      },
      {
        balloonText: valueWithUnits("[[value]]", attr.right.units),
        bullet: "square",
        bulletBorderAlpha: 1,
        bulletBorderThickness: 1,
        dashLengthField: "dashLength",
        legendValueText: valueWithUnits("[[value]]", attr.right.units),
        title: attr.right.label,
        fillAlphas: 0,
        valueField: attr.right.label,
        valueAxis: "RightAxis",
      },
    ],
    chartCursor: {
      categoryBalloonDateFormat: "DD",
      cursorAlpha: 0.1,
      cursorColor: "#000000",
      fullWidth: true,
      valueBalloonsEnabled: false,
      zoomable: false,
    },
    dataDateFormat: "D",
    categoryField: attr.main,
    categoryAxis: {
      parseDates: false,
      autoGridCount: false,
      axisColor: "#555555",
      gridAlpha: 0.1,
      gridColor: "#FFFFFF",
      gridCount: 50,
    },
  };

  return (
    <div className="App">
      <AmCharts.React style={{ width: "100%", height: "350px" }} options={config} />
    </div>
  );
};

export default ReversedValueAxis;
