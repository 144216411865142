// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_REFRESH_TOKEN_SET = "user_refresh_token_set";

//Advertisers
export const FETCH_ADVERTISERS_BEGIN = "FETCH_ADVERTISERS_BEGIN";
export const FETCH_ADVERTISERS_FAILURE = "FETCH_ADVERTISERS_FAILURE";
export const FETCH_ADVERTISERS_SUCCESS = "FETCH_ADVERTISERS_SUCCESS";
export const FETCH_ADVERTISERS_CREATE_SUCCESS = "FETCH_ADVERTISERS_CREATE_SUCCESS";

//Advertisers
export const FETCH_ORGANIZATION_PARTNERS_BEGIN = "FETCH_ORGANIZATION_PARTNERS_BEGIN";
export const FETCH_ORGANIZATION_PARTNERS_FAILURE = "FETCH_ORGANIZATION_PARTNERS_FAILURE";
export const FETCH_ORGANIZATION_PARTNERS_SUCCESS = "FETCH_ORGANIZATION_PARTNERS_SUCCESS";
export const FETCH_ORGANIZATION_PARTNERS_CREATE_SUCCESS = "FETCH_ORGANIZATION_PARTNERS_CREATE_SUCCESS";

//Refresh
export const FETCH_REFRESH_BEGIN = "FETCH_REFRESH_BEGIN";
export const FETCH_REFRESH_SUCCESS = "FETCH_REFRESH_SUCCESS";
export const FETCH_REFRESH_FAILURE = "FETCH_REFRESH_FAILURE";

//OAuth
export const FETCH_OAUTH_LINK_BEGIN = "FETCH_OAUTH_LINK_BEGIN";
export const FETCH_OAUTH_LINK_SUCCESS = "FETCH_OAUTH_LINK_SUCCESS";
export const FETCH_OAUTH_LINK_FAILURE = "FETCH_OAUTH_LINK_FAILURE";

// Filters
export const CLIENT_DATASET_BEGIN = "CLIENT_DATASET_BEGIN";
export const CLIENT_DATASET_SUCCESS = "CLIENT_DATASET_SUCCESS";
export const CLIENT_DATASET_FAILURE = "CLIENT_DATASET_FAILURE";
export const CLIENT_ORGANIZATION_BEGIN = "CLIENT_ORGANIZATION_BEGIN";
export const CLIENT_ORGANIZATION_SUCCESS = "CLIENT_ORGANIZATION_SUCCESS";
export const CLIENT_ORGANIZATION_FAILURE = "CLIENT_ORGANIZATION_FAILURE";

export const TIME_RANGE_BEGIN = "TIME_RANGE_BEGIN";
export const TIME_RANGE_SUCCESS = "TIME_RANGE_SUCCESS";
export const TIME_RANGE_FAILURE = "TIME_RANGE_FAILURE";

export const INSERTION_ORDER_BEGIN = "INSERTION_ORDER_BEGIN";
export const INSERTION_ORDER_SUCCESS = "INSERTION_ORDER_SUCCESS";
export const INSERTION_ORDER_FAILURE = "INSERTION_ORDER_FAILURE";

export const CAMPAIGN_INSERTION_ORDER_BEGIN = "CAMPAIGN_INSERTION_ORDER_BEGIN";
export const CAMPAIGN_INSERTION_ORDER_SUCCESS = "CAMPAIGN_INSERTION_ORDER_SUCCESS";
export const CAMPAIGN_INSERTION_ORDER_FAILURE = "CAMPAIGN_INSERTION_ORDER_FAILURE";

export const CAMPAIGN_GROUPED_INSERTION_ORDER_BEGIN = "CAMPAIGN_GROUPED_INSERTION_ORDER_BEGIN";
export const CAMPAIGN_GROUPED_INSERTION_ORDER_SUCCESS = "CAMPAIGN_GROUPED_INSERTION_ORDER_SUCCESS";
export const CAMPAIGN_GROUPED_INSERTION_ORDER_FAILURE = "CAMPAIGN_GROUPED_INSERTION_ORDER_FAILURE";

export const LINE_ITEM_BEGIN = "LINE_ITEM_BEGIN";
export const LINE_ITEM_SUCCESS = "LINE_ITEM_SUCCESS";
export const LINE_ITEM_FAILURE = "LINE_ITEM_FAILURE";

export const CAMPAIGN_ID_BEGIN = "CAMPAIGN_ID_BEGIN";
export const CAMPAIGN_ID_SUCCESS = "CAMPAIGN_ID_SUCCESS";
export const CAMPAIGN_ID_FAILURE = "CAMPAIGN_ID_FAILURE";

export const SET_LINE_ITEM = "SET_LINE_ITEM";
export const SET_INSERTION_ORDER = "SET_INSERTION_ORDER";
export const SET_CAMPAIGN_INSERTION_ORDER = "SET_CAMPAIGN_INSERTION_ORDER";
export const SET_CAMPAIGN_GROUPED_INSERTION_ORDER = "SET_CAMPAIGN_GROUPED_INSERTION_ORDER";
export const SET_CLIENT_DATASET = "SET_CLIENT_DATASET";
export const SET_CLIENT_ORGANIZATION = "SET_CLIENT_ORGANIZATION";
export const SET_TIME_RANGE = "SET_TIME_RANGE";
export const SET_LOCAL_FILTERS = "SET_LOCAL_FILTERS";
export const SET_CAMPAIGN_ID = "SET_CAMPAIGN_ID";

export const GET_PERIODS_BEGIN = "GET_PERIODS_BEGIN";
export const GET_PERIODS_SUCCESS = "GET_PERIODS_SUCCESS";
export const GET_PERIODS_FAILURE = "GET_PERIODS_FAILURE";
export const SET_PERIOD = "SET_PERIOD";

//Overview
export const OVERVIEW_SET_LOADED = "OVERVIEW_SET_LOADED";
export const OVERVIEW_SET_FILTERS = "OVERVIEW_SET_FILTERS";

export const FETCH_OVERVIEW_TOP_CHART_BEGIN = "FETCH_OVERVIEW_TOP_CHART_BEGIN";
export const FETCH_OVERVIEW_TOP_CHART_SUCCESS = "FETCH_OVERVIEW_TOP_CHART_SUCCESS";
export const FETCH_OVERVIEW_TOP_CHART_FAILURE = "FETCH_OVERVIEW_TOP_CHART_FAILURE";

export const FETCH_OVERVIEW_WOW_SCORES_BEGIN = "FETCH_OVERVIEW_WOW_SCORES_BEGIN";
export const FETCH_OVERVIEW_WOW_SCORES_SUCCESS = "FETCH_OVERVIEW_WOW_SCORES_SUCCESS";
export const FETCH_OVERVIEW_WOW_SCORES_FAILURE = "FETCH_OVERVIEW_WOW_SCORES_FAILURE";

export const FETCH_OVERVIEW_STATS_BEGIN = "FETCH_OVERVIEW_STATS_BEGIN";
export const FETCH_OVERVIEW_STATS_SUCCESS = "FETCH_OVERVIEW_STATS_SUCCESS";
export const FETCH_OVERVIEW_STATS_FAILURE = "FETCH_OVERVIEW_STATS_FAILURE";

export const FETCH_OVERVIEW_DIMENSION_BEGIN = "FETCH_OVERVIEW_DIMENSION_BEGIN";
export const FETCH_OVERVIEW_DIMENSION_SUCCESS = "FETCH_OVERVIEW_DIMENSION_SUCCESS";
export const FETCH_OVERVIEW_DIMENSION_FAILURE = "FETCH_OVERVIEW_DIMENSION_FAILURE";

export const FETCH_OVERVIEW_MAP_SCORE_BEGIN = "FETCH_OVERVIEW_MAP_SCORE_BEGIN";
export const FETCH_OVERVIEW_MAP_SCORE_SUCCESS = "FETCH_OVERVIEW_MAP_SCORE_SUCCESS";
export const FETCH_OVERVIEW_MAP_SCORE_FAILURE = "FETCH_OVERVIEW_MAP_SCORE_FAILURE";

export const FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_BEGIN = "FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_BEGIN";
export const FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_SUCCESS = "FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_SUCCESS";
export const FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_FAILURE = "FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_FAILURE";

//Creative
export const CREATIVE_SET_LOADED = "CREATIVE_SET_LOADED";
export const CREATIVE_SET_FILTERS = "CREATIVE_SET_FILTERS";

export const FETCH_CREATIVE_STATS_BEGIN = "FETCH_CREATIVE_STATS_BEGIN";
export const FETCH_CREATIVE_STATS_SUCCESS = "FETCH_CREATIVE_STATS_SUCCESS";
export const FETCH_CREATIVE_STATS_FAILURE = "FETCH_CREATIVE_STATS_FAILURE";

export const FETCH_CREATIVE_WOW_SCORES_BEGIN = "FETCH_CREATIVE_WOW_SCORES_BEGIN";
export const FETCH_CREATIVE_WOW_SCORES_SUCCESS = "FETCH_CREATIVE_WOW_SCORES_SUCCESS";
export const FETCH_CREATIVE_WOW_SCORES_FAILURE = "FETCH_CREATIVE_WOW_SCORES_FAILURE";
export const FETCH_CREATIVE_WOW_SCORES_END = "FETCH_CREATIVE_WOW_SCORES_END";

export const FETCH_CREATIVE_DEVICE_TABLE_METRICS_BEGIN = "FETCH_CREATIVE_DEVICE_TABLE_METRICS_BEGIN";
export const FETCH_CREATIVE_DEVICE_TABLE_METRICS_SUCCESS = "FETCH_CREATIVE_DEVICE_TABLE_METRICS_SUCCESS";
export const FETCH_CREATIVE_DEVICE_TABLE_METRICS_FAILURE = "FETCH_CREATIVE_DEVICE_TABLE_METRICS_FAILURE";

export const FETCH_CREATIVE_TOP_METRICS_BEGIN = "FETCH_CREATIVE_TOP_METRICS_BEGIN";
export const FETCH_CREATIVE_TOP_METRICS_SUCCESS = "FETCH_CREATIVE_TOP_METRICS_SUCCESS";
export const FETCH_CREATIVE_TOP_METRICS_FAILURE = "FETCH_CREATIVE_TOP_METRICS_FAILURE";

export const FETCH_CREATIVE_DEVICE_TYPE_METRICS_BEGIN = "FETCH_CREATIVE_DEVICE_TYPE_METRICS_BEGIN";
export const FETCH_CREATIVE_DEVICE_TYPE_METRICS_SUCCESS = "FETCH_CREATIVE_DEVICE_TYPE_METRICS_SUCCESS";
export const FETCH_CREATIVE_DEVICE_TYPE_METRICS_FAILURE = "FETCH_CREATIVE_DEVICE_TYPE_METRICS_FAILURE";

export const FETCH_CREATIVE_CREATIVE_SIZE_METRICS_BEGIN = "FETCH_CREATIVE_CREATIVE_SIZE_METRICS_BEGIN";
export const FETCH_CREATIVE_CREATIVE_SIZE_METRICS_SUCCESS = "FETCH_CREATIVE_CREATIVE_SIZE_METRICS_SUCCESS";
export const FETCH_CREATIVE_CREATIVE_SIZE_METRICS_FAILURE = "FETCH_CREATIVE_CREATIVE_SIZE_METRICS_FAILURE";

export const FETCH_CREATIVE_CREATIVE_TYPE_METRICS_BEGIN = "FETCH_CREATIVE_CREATIVE_TYPE_METRICS_BEGIN";
export const FETCH_CREATIVE_CREATIVE_TYPE_METRICS_SUCCESS = "FETCH_CREATIVE_CREATIVE_TYPE_METRICS_SUCCESS";
export const FETCH_CREATIVE_CREATIVE_TYPE_METRICS_FAILURE = "FETCH_CREATIVE_CREATIVE_TYPE_METRICS_FAILURE";

//Geography
export const GEOGRAPHY_SET_LOADED = "GEOGRAPHY_SET_LOADED";
export const GEOGRAPHY_SET_FILTERS = "GEOGRAPHY_SET_FILTERS";

export const FETCH_GEOGRAPHY_STATS_BEGIN = "FETCH_GEOGRAPHY_STATS_BEGIN";
export const FETCH_GEOGRAPHY_STATS_SUCCESS = "FETCH_GEOGRAPHY_STATS_SUCCESS";
export const FETCH_GEOGRAPHY_STATS_FAILURE = "FETCH_GEOGRAPHY_STATS_FAILURE";

export const FETCH_GEOGRAPHY_WOW_SCORES_BEGIN = "FETCH_GEOGRAPHY_WOW_SCORES_BEGIN";
export const FETCH_GEOGRAPHY_WOW_SCORES_SUCCESS = "FETCH_GEOGRAPHY_WOW_SCORES_SUCCESS";
export const FETCH_GEOGRAPHY_WOW_SCORES_FAILURE = "FETCH_GEOGRAPHY_WOW_SCORES_FAILURE";
export const FETCH_GEOGRAPHY_WOW_SCORES_END = "FETCH_GEOGRAPHY_WOW_SCORES_END";

export const FETCH_GEOGRAPHY_TOP_METRICS_BEGIN = "FETCH_GEOGRAPHY_TOP_METRICS_BEGIN";
export const FETCH_GEOGRAPHY_TOP_METRICS_SUCCESS = "FETCH_GEOGRAPHY_TOP_METRICS_SUCCESS";
export const FETCH_GEOGRAPHY_TOP_METRICS_FAILURE = "FETCH_GEOGRAPHY_TOP_METRICS_FAILURE";

export const FETCH_GEOGRAPHY_CITY_TABLE_METRICS_BEGIN = "FETCH_GEOGRAPHY_CITY_TABLE_METRICS_BEGIN";
export const FETCH_GEOGRAPHY_CITY_TABLE_METRICS_SUCCESS = "FETCH_GEOGRAPHY_CITY_TABLE_METRICS_SUCCESS";
export const FETCH_GEOGRAPHY_CITY_TABLE_METRICS_FAILURE = "FETCH_GEOGRAPHY_CITY_TABLE_METRICS_FAILURE";

export const FETCH_GEOGRAPHY_CITY_METRICS_BEGIN = "FETCH_GEOGRAPHY_CITY_METRICS_BEGIN";
export const FETCH_GEOGRAPHY_CITY_METRICS_SUCCESS = "FETCH_GEOGRAPHY_CITY_METRICS_SUCCESS";
export const FETCH_GEOGRAPHY_CITY_METRICS_FAILURE = "FETCH_GEOGRAPHY_CITY_METRICS_FAILURE";

//Strategy
export const STRATEGY_SET_LOADED = "STRATEGY_SET_LOADED";
export const STRATEGY_SET_FILTERS = "STRATEGY_SET_FILTERS";

export const FETCH_STRATEGY_STATS_BEGIN = "FETCH_STRATEGY_STATS_BEGIN";
export const FETCH_STRATEGY_STATS_SUCCESS = "FETCH_STRATEGY_STATS_SUCCESS";
export const FETCH_STRATEGY_STATS_FAILURE = "FETCH_STRATEGY_STATS_FAILURE";

export const FETCH_STRATEGY_WOW_SCORES_BEGIN = "FETCH_STRATEGY_WOW_SCORES_BEGIN";
export const FETCH_STRATEGY_WOW_SCORES_SUCCESS = "FETCH_STRATEGY_WOW_SCORES_SUCCESS";
export const FETCH_STRATEGY_WOW_SCORES_FAILURE = "FETCH_STRATEGY_WOW_SCORES_FAILURE";
export const FETCH_STRATEGY_WOW_SCORES_END = "FETCH_STRATEGY_WOW_SCORES_END";

export const FETCH_STRATEGY_TOP_METRICS_BEGIN = "FETCH_STRATEGY_TOP_METRICS_BEGIN";
export const FETCH_STRATEGY_TOP_METRICS_SUCCESS = "FETCH_STRATEGY_TOP_METRICS_SUCCESS";
export const FETCH_STRATEGY_TOP_METRICS_FAILURE = "FETCH_STRATEGY_TOP_METRICS_FAILURE";

export const FETCH_STRATEGY_ORDER_TABLE_METRICS_BEGIN = "FETCH_STRATEGY_ORDER_TABLE_METRICS_BEGIN";
export const FETCH_STRATEGY_ORDER_TABLE_METRICS_SUCCESS = "FETCH_STRATEGY_ORDER_TABLE_METRICS_SUCCESS";
export const FETCH_STRATEGY_ORDER_TABLE_METRICS_FAILURE = "FETCH_STRATEGY_ORDER_TABLE_METRICS_FAILURE";

export const FETCH_STRATEGY_INSERTION_ORDER_BEGIN = "FETCH_STRATEGY_INSERTION_ORDER_BEGIN";
export const FETCH_STRATEGY_INSERTION_ORDER_SUCCESS = "FETCH_STRATEGY_INSERTION_ORDER_SUCCESS";
export const FETCH_STRATEGY_INSERTION_ORDER_FAILURE = "FETCH_STRATEGY_INSERTION_ORDER_FAILURE";

export const FETCH_STRATEGY_LINE_ITEM_BEGIN = "FETCH_STRATEGY_LINE_ITEM_BEGIN";
export const FETCH_STRATEGY_LINE_ITEM_SUCCESS = "FETCH_STRATEGY_LINE_ITEM_SUCCESS";
export const FETCH_STRATEGY_LINE_ITEM_FAILURE = "FETCH_STRATEGY_LINE_ITEM_FAILURE";

//Temporal
export const TEMPORAL_SET_LOADED = "TEMPORAL_SET_LOADED";
export const TEMPORAL_SET_FILTERS = "TEMPORAL_SET_FILTERS";

export const FETCH_TEMPORAL_STATS_BEGIN = "FETCH_TEMPORAL_STATS_BEGIN";
export const FETCH_TEMPORAL_STATS_SUCCESS = "FETCH_TEMPORAL_STATS_SUCCESS";
export const FETCH_TEMPORAL_STATS_FAILURE = "FETCH_TEMPORAL_STATS_FAILURE";

export const FETCH_TEMPORAL_WOW_SCORES_BEGIN = "FETCH_TEMPORAL_WOW_SCORES_BEGIN";
export const FETCH_TEMPORAL_WOW_SCORES_SUCCESS = "FETCH_TEMPORAL_WOW_SCORES_SUCCESS";
export const FETCH_TEMPORAL_WOW_SCORES_FAILURE = "FETCH_TEMPORAL_WOW_SCORES_FAILURE";
export const FETCH_TEMPORAL_WOW_SCORES_END = "FETCH_TEMPORAL_WOW_SCORES_END";

export const FETCH_TEMPORAL_TOP_METRICS_BEGIN = "FETCH_TEMPORAL_TOP_METRICS_BEGIN";
export const FETCH_TEMPORAL_TOP_METRICS_SUCCESS = "FETCH_TEMPORAL_TOP_METRICS_SUCCESS";
export const FETCH_TEMPORAL_TOP_METRICS_FAILURE = "FETCH_TEMPORAL_TOP_METRICS_FAILURE";

export const FETCH_TEMPORAL_TOD_METRICS_BEGIN = "FETCH_TEMPORAL_TOD_METRICS_BEGIN";
export const FETCH_TEMPORAL_TOD_METRICS_SUCCESS = "FETCH_TEMPORAL_TOD_METRICS_SUCCESS";
export const FETCH_TEMPORAL_TOD_METRICS_FAILURE = "FETCH_TEMPORAL_TOD_METRICS_FAILURE";

export const FETCH_TEMPORAL_DOW_METRICS_BEGIN = "FETCH_TEMPORAL_DOW_METRICS_BEGIN";
export const FETCH_TEMPORAL_DOW_METRICS_SUCCESS = "FETCH_TEMPORAL_DOW_METRICS_SUCCESS";
export const FETCH_TEMPORAL_DOW_METRICS_FAILURE = "FETCH_TEMPORAL_DOW_METRICS_FAILURE";

export const FETCH_TEMPORAL_DOW_TOD_METRICS_BEGIN = "FETCH_TEMPORAL_DOW_TOD_METRICS_BEGIN";
export const FETCH_TEMPORAL_DOW_TOD_METRICS_SUCCESS = "FETCH_TEMPORAL_DOW_TOD_METRICS_SUCCESS";
export const FETCH_TEMPORAL_DOW_TOD_METRICS_FAILURE = "FETCH_TEMPORAL_DOW_TOD_METRICS_FAILURE";

//Inventory
export const INVENTORY_SET_LOADED = "INVENTORY_SET_LOADED";
export const INVENTORY_SET_FILTERS = "INVENTORY_SET_FILTERS";

export const FETCH_INVENTORY_STATS_BEGIN = "FETCH_INVENTORY_STATS_BEGIN";
export const FETCH_INVENTORY_STATS_SUCCESS = "FETCH_INVENTORY_STATS_SUCCESS";
export const FETCH_INVENTORY_STATS_FAILURE = "FETCH_INVENTORY_STATS_FAILURE";

export const FETCH_INVENTORY_WOW_SCORES_BEGIN = "FETCH_INVENTORY_WOW_SCORES_BEGIN";
export const FETCH_INVENTORY_WOW_SCORES_SUCCESS = "FETCH_INVENTORY_WOW_SCORES_SUCCESS";
export const FETCH_INVENTORY_WOW_SCORES_FAILURE = "FETCH_INVENTORY_WOW_SCORES_FAILURE";
export const FETCH_INVENTORY_WOW_SCORES_END = "FETCH_INVENTORY_WOW_SCORES_END";

export const FETCH_INVENTORY_AOU_TABLE_METRICS_BEGIN = "FETCH_INVENTORY_AOU_TABLE_METRICS_BEGIN";
export const FETCH_INVENTORY_AOU_TABLE_METRICS_SUCCESS = "FETCH_INVENTORY_AOU_TABLE_METRICS_SUCCESS";
export const FETCH_INVENTORY_AOU_TABLE_METRICS_FAILURE = "FETCH_INVENTORY_AOU_TABLE_METRICS_FAILURE";

export const FETCH_INVENTORY_TOP_METRICS_BEGIN = "FETCH_INVENTORY_TOP_METRICS_BEGIN";
export const FETCH_INVENTORY_TOP_METRICS_SUCCESS = "FETCH_INVENTORY_TOP_METRICS_SUCCESS";
export const FETCH_INVENTORY_TOP_METRICS_FAILURE = "FETCH_INVENTORY_TOP_METRICS_FAILURE";

export const FETCH_INVENTORY_AOU_METRICS_BEGIN = "FETCH_INVENTORY_AOU_METRICS_BEGIN";
export const FETCH_INVENTORY_AOU_METRICS_SUCCESS = "FETCH_INVENTORY_AOU_METRICS_SUCCESS";
export const FETCH_INVENTORY_AOU_METRICS_FAILURE = "FETCH_INVENTORY_AOU_METRICS_FAILURE";

export const FETCH_INVENTORY_CREATIVE_SIZE_METRICS_BEGIN = "FETCH_INVENTORY_CREATIVE_SIZE_METRICS_BEGIN";
export const FETCH_INVENTORY_CREATIVE_SIZE_METRICS_SUCCESS = "FETCH_INVENTORY_CREATIVE_SIZE_METRICS_SUCCESS";
export const FETCH_INVENTORY_CREATIVE_SIZE_METRICS_FAILURE = "FETCH_INVENTORY_CREATIVE_SIZE_METRICS_FAILURE";

export const FETCH_INVENTORY_EXCHANGE_METRICS_BEGIN = "FETCH_INVENTORY_EXCHANGE_METRICS_BEGIN";
export const FETCH_INVENTORY_EXCHANGE_METRICS_SUCCESS = "FETCH_INVENTORY_EXCHANGE_METRICS_SUCCESS";
export const FETCH_INVENTORY_EXCHANGE_METRICS_FAILURE = "FETCH_INVENTORY_EXCHANGE_METRICS_FAILURE";

//Campaign
export const CAMPAIGN_SET_LOADED = "CAMPAIGN_SET_LOADED";
export const CAMPAIGN_SET_FILTERS = "CAMPAIGN_SET_FILTERS";

export const FETCH_CAMPAIGN_STATS_BEGIN = "FETCH_CAMPAIGN_STATS_BEGIN";
export const FETCH_CAMPAIGN_STATS_SUCCESS = "FETCH_CAMPAIGN_STATS_SUCCESS";
export const FETCH_CAMPAIGN_STATS_FAILURE = "FETCH_CAMPAIGN_STATS_FAILURE";
export const FETCH_CAMPAIGN_STATS_CANCEL = "FETCH_CAMPAIGN_STATS_CANCEL";

export const FETCH_CAMPAIGN_SETTINGS_SUM_BEGIN = "FETCH_CAMPAIGN_SETTINGS_BEGIN";
export const FETCH_CAMPAIGN_SETTINGS_SUM_SUCCESS = "FETCH_CAMPAIGN_SETTINGS_SUCCESS";
export const FETCH_CAMPAIGN_SETTINGS_SUM_FAILURE = "FETCH_CAMPAIGN_SETTINGS_FAILURE";
export const FETCH_CAMPAIGN_SETTINGS_SUM_CANCEL = "FETCH_CAMPAIGN_SETTINGS_SUM_CANCEL";

export const FETCH_CAMPAIGN_CATEGORIES_BEGIN = "FETCH_CAMPAIGN_CATEGORIES_BEGIN";
export const FETCH_CAMPAIGN_CATEGORIES_SUCCESS = "FETCH_CAMPAIGN_CATEGORIES_SUCCESS";
export const FETCH_CAMPAIGN_CATEGORIES_FAILURE = "FETCH_CAMPAIGN_CATEGORIES_FAILURE";
export const FETCH_CAMPAIGN_CATEGORIES_CANCEL = "FETCH_CAMPAIGN_CATEGORIES_CANCEL";

export const FETCH_CAMPAIGN_LAST_MODIFIED_BEGIN = "FETCH_CAMPAIGN_LAST_MODIFIED_BEGIN";
export const FETCH_CAMPAIGN_LAST_MODIFIED_SUCCESS = "FETCH_CAMPAIGN_LAST_MODIFIED_SUCCESS";
export const FETCH_CAMPAIGN_LAST_MODIFIED_FAILURE = "FETCH_CAMPAIGN_LAST_MODIFIED_FAILURE";

//Campaign overview
export const FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_BEGIN = "FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_BEGIN";
export const FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_SUCCESS = "FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_SUCCESS";
export const FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_FAILURE = "FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_FAILURE";

export const FETCH_CAMPAIGN_STATS_OVERVIEW_BEGIN = "FETCH_CAMPAIGN_STATS_OVERVIEW_BEGIN";
export const FETCH_CAMPAIGN_STATS_OVERVIEW_SUCCESS = "FETCH_CAMPAIGN_STATS_OVERVIEW_SUCCESS";
export const FETCH_CAMPAIGN_STATS_OVERVIEW_FAILURE = "FETCH_CAMPAIGN_STATS_OVERVIEW_FAILURE";

export const CAMPAIGN_SET_DOWNLOADING = "CAMPAIGN_SET_DOWNLOADING";

export const SET_DATASET_VISIBLE = "SET_DATASET_VISIBLE";

export const FETCH_DATASET_SCORE_BEGIN = "FETCH_DATASET_SCORE_BEGIN";
export const FETCH_DATASET_SCORE_SUCCESS = "FETCH_DATASET_SCORE_SUCCESS";
export const FETCH_DATASET_SCORE_FAILURE = "FETCH_DATASET_SCORE_FAILURE";
