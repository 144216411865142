import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import NavigationTop from "./../Navigation";
import SeeKpi from "./tabs/SeeKpi";
import ThinkKpi from "./tabs/ThinkKpi";
import DoKpi from "./tabs/DoKpi";
import { push } from "connected-react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PageTop from "../Top";
import Loading from "../../components/Loading";
import {
  temporalWelcomeData,
  temporalTopMetricsData,
  temporalWowScoresData,
  temporalTodMetricsData,
  temporalDowMetricsData,
  temporalDowTodMetricsData,
  setFilters,
  setLoaded,
} from "../../appRedux/actions/Temporal";
import { getLastUrlPart } from "./../../util/Helpers";
import { ClockCircleOutlined } from "@ant-design/icons";

const navigationItems = [
  { label: "See KPIs", path: "/temporal/see" },
  { label: "Think KPIs", path: "/temporal/think" },
  { label: "Do KPIs", path: "/temporal/do" },
];

const Temporal = ({ navigateTo }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(location.pathname);
  const [welcomeData, setWelcomeData] = useState();
  const [lastUrlPart, setLasUrlPart] = useState();

  const loadingWelcome = useSelector(({ temporalData }) => temporalData.welcomeStats.loading);
  const welcomeStats = useSelector(({ temporalData }) => temporalData.welcomeStats.data);
  const loadingWowScores = useSelector(({ temporalData }) => temporalData.wowScoresStats.loading);
  const wowScores = useSelector(({ temporalData }) => temporalData.wowScoresStats.data);
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const filterPeriod = useSelector(({ filtersData }) => filtersData.period.current);
  const filterLineItem = useSelector(({ filtersData }) => filtersData.lineItem.current);
  const filterInsertionOrder = useSelector(({ filtersData }) => filtersData.insertionOrder.current);
  const filterCampaignId = useSelector(({ filtersData }) => filtersData.campaignId.current);
  const filterTimeRange = useSelector(({ filtersData }) => filtersData.timeRange.current);
  const currentFilters = useSelector(({ temporalData }) => temporalData.filters);
  const loaded = useSelector(({ temporalData }) => temporalData.loaded);

  useEffect(() => {
    setCurrent(location.pathname);
    setLasUrlPart(getLastUrlPart().toUpperCase());
  }, [location]);

  useEffect(() => {
    if (!loaded || (loaded && filterClientDataset && filterClientDataset !== currentFilters.clientDataset)) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (
      loaded &&
      filterClientDataset &&
      filterClientDataset !== currentFilters.clientDataset &&
      currentFilters.clientDataset
    ) {
      getData();
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
    if (!currentFilters.clientDataset && filterClientDataset) {
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
  }, [filterClientDataset]);

  const getData = () => {
    dispatch(temporalTodMetricsData());
    dispatch(temporalDowMetricsData());
    dispatch(temporalDowTodMetricsData());
    dispatch(temporalWelcomeData());
    dispatch(temporalTopMetricsData());
    dispatch(
      temporalWowScoresData([
        {
          index: "map",
          query: "DOW,TOD,DOWTOD",
        },
        {
          index: "dow",
          query: "DOW",
        },
        {
          index: "tod",
          query: "TOD",
        },
        {
          index: "dowtod",
          query: "DOWTOD",
        },
      ])
    );
    dispatch(setLoaded());
  };

  useEffect(() => {
    if (loaded && filterLineItem && currentFilters.lineItem !== filterLineItem) {
      dispatch(setFilters({ lineItem: filterLineItem }));
      getData();
    }

    if (loaded && filterInsertionOrder && currentFilters.lineItem !== filterInsertionOrder) {
      dispatch(setFilters({ lineItem: filterInsertionOrder }));
      getData();
    }

    if (loaded && filterTimeRange && currentFilters.timeRange !== filterTimeRange) {
      dispatch(setFilters({ timeRange: filterTimeRange }));
      getData();
    }

    if (loaded && filterCampaignId && currentFilters.campaignId !== filterCampaignId) {
      dispatch(setFilters({ campaignId: filterCampaignId }));
      getData();
    }
  }, [filterLineItem, filterInsertionOrder, filterTimeRange, filterCampaignId]);

  useEffect(() => {
    if (loaded && filterPeriod && currentFilters.period !== filterPeriod) {
      dispatch(setFilters({ period: filterPeriod }));
      dispatch(
        temporalWowScoresData([
          {
            index: "map",
            query: "DOW,TOD,DOWTOD",
          },
          {
            index: "dow",
            query: "DOW",
          },
          {
            index: "tod",
            query: "TOD",
          },
          {
            index: "dowtod",
            query: "DOWTOD",
          },
        ])
      );
    }
  }, [filterPeriod]);

  useEffect(() => {
    if (typeof welcomeStats !== "undefined" && welcomeStats.length !== 0) {
      setWelcomeData({
        title: "Welcome!",
        subTitle: "The temporal review is of",
        items: [
          {
            icon: <ClockCircleOutlined />,
            title: { singular: "Day of the Week", plural: "Days of the Week" },
            count: welcomeStats.dow,
          },
          {
            icon: <ClockCircleOutlined />,
            title: { singular: "Hour of the Day", plural: "Hours of the Day" },
            count: welcomeStats.tod,
          },
          {
            icon: <ClockCircleOutlined />,
            title: {
              singular: "Hour and Days of the Week",
              plural: "Hour and Days of the Week",
            },
            count: welcomeStats.dowtod,
          },
        ],
      });
    }
  }, [loadingWelcome]);

  return (
    <div>
      <NavigationTop navigationItems={navigationItems} navigateTo={navigateTo} current={current} />
      {!loadingWelcome && !loadingWowScores && welcomeData ? (
        <PageTop
          welcomeData={welcomeData}
          wowScores={{
            data: wowScores,
            mainChart: { part: "map", section: "MAP" },
            asideCharts: [
              { part: "dow", section: lastUrlPart, name: "Day of Week" },
              { part: "tod", section: lastUrlPart, name: "Hour of Day" },
              { part: "dowtod", section: lastUrlPart, name: "Hour and Day of week" },
            ],
          }}
          chartAxes={true}
        />
      ) : (
        <Loading card />
      )}

      <Route exact path="/temporal">
        <Redirect to="/temporal/see" />
      </Route>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={300}>
          <Switch location={location}>
            <Route path="/temporal/see" children={() => <SeeKpi />} />
            <Route path="/temporal/think" children={() => <ThinkKpi />} />
            <Route path="/temporal/do" children={() => <DoKpi />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(Temporal);
