import {
  CLIENT_DATASET_BEGIN,
  CLIENT_DATASET_SUCCESS,
  CLIENT_DATASET_FAILURE,
  SET_CLIENT_DATASET,
  CLIENT_ORGANIZATION_BEGIN,
  CLIENT_ORGANIZATION_SUCCESS,
  CLIENT_ORGANIZATION_FAILURE,
  SET_CLIENT_ORGANIZATION,
  SET_PERIOD,
  GET_PERIODS_BEGIN,
  GET_PERIODS_SUCCESS,
  GET_PERIODS_FAILURE,
  SET_INSERTION_ORDER,
  INSERTION_ORDER_BEGIN,
  INSERTION_ORDER_SUCCESS,
  INSERTION_ORDER_FAILURE,
  SET_CAMPAIGN_INSERTION_ORDER,
  SET_CAMPAIGN_GROUPED_INSERTION_ORDER,
  CAMPAIGN_INSERTION_ORDER_BEGIN,
  CAMPAIGN_INSERTION_ORDER_SUCCESS,
  CAMPAIGN_INSERTION_ORDER_FAILURE,
  CAMPAIGN_GROUPED_INSERTION_ORDER_BEGIN,
  CAMPAIGN_GROUPED_INSERTION_ORDER_SUCCESS,
  CAMPAIGN_GROUPED_INSERTION_ORDER_FAILURE,
  LINE_ITEM_BEGIN,
  LINE_ITEM_SUCCESS,
  LINE_ITEM_FAILURE,
  SET_LINE_ITEM,
  TIME_RANGE_BEGIN,
  TIME_RANGE_SUCCESS,
  TIME_RANGE_FAILURE,
  SET_TIME_RANGE,
  CAMPAIGN_ID_BEGIN,
  CAMPAIGN_ID_SUCCESS,
  CAMPAIGN_ID_FAILURE,
  SET_CAMPAIGN_ID,
} from "../../constants/ActionTypes";

import axios from "util/Api";
import { store } from "./../../NextApp";

export const getPeriods = () => {
  return (dispatch) => {
    dispatch({ type: GET_PERIODS_BEGIN });
    dispatch({
      type: GET_PERIODS_SUCCESS,
      payload: [
        {
          key: "Week",
          value: "Week",
          active: false,
        },
        {
          key: "Month",
          value: "Month",
          active: false,
        },
        {
          key: "Quarter",
          value: "Quarter",
          active: false,
        },
        {
          key: "All",
          value: "All Time",
          active: false,
        },
      ],
    });
    axios
      .get("api/client/active-time-period")
      .then(({ data }) => {
        dispatch({ type: GET_PERIODS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: GET_PERIODS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const setTimeRange = (data) => {
  return {
    type: SET_TIME_RANGE,
    payload: data,
  };
};

export const getTimeRange = () => {
  return (dispatch) => {
    dispatch({ type: TIME_RANGE_BEGIN });
    axios
      .get("api/client/time-range")
      .then(({ data }) => {
        dispatch({ type: TIME_RANGE_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: TIME_RANGE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getLineItem = (sdf = false) => {
  return (dispatch) => {
    dispatch({ type: LINE_ITEM_BEGIN });

    let url = "api/client/line-item/list";
    if (sdf) url = "api/sdf/line-items";

    axios
      .get(url)
      .then(({ data }) => {
        dispatch({ type: LINE_ITEM_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: LINE_ITEM_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const setLineItem = (data) => {
  let lineItem = store.getState().filtersData.lineItem.data;
  return {
    type: SET_LINE_ITEM,
    payload: lineItem.filter(function (item) {
      return data ? data.includes(item.key) : data;
    }),
  };
};

export const getClientDataset = () => {
  return (dispatch) => {
    dispatch({ type: CLIENT_ORGANIZATION_BEGIN });
    dispatch({ type: CLIENT_DATASET_BEGIN });

    axios
      .get("api/client/organization")
      .then(({ data }) => {
        dispatch({ type: CLIENT_ORGANIZATION_SUCCESS, payload: data });
        dispatch({ type: CLIENT_DATASET_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: CLIENT_DATASET_FAILURE,
          payload: error.message,
        });
        dispatch({
          type: CLIENT_ORGANIZATION_FAILURE,
          payload: error.message,
        });
      });
  };
};

// export const getClientOrganization = () => {
//   return (dispatch) => {
//     dispatch({ type: CLIENT_ORGANIZATION_BEGIN });

//     axios
//       .get("api/client/organization")
//       .then(({ data }) => {
//         dispatch({ type: CLIENT_ORGANIZATION_SUCCESS, payload: data });
//       })
//       .catch(function (error) {
//         dispatch({
//           type: CLIENT_ORGANIZATION_FAILURE,
//           payload: error.message,
//         });
//
//       });
//   };
// };

export const getInsertionOrder = () => {
  return (dispatch) => {
    dispatch({ type: INSERTION_ORDER_BEGIN });
    axios
      .get("api/client/insertion-order/list")
      .then(({ data }) => {
        dispatch({ type: INSERTION_ORDER_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: INSERTION_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const setInsertionOrder = (data) => {
  let insertionOrder = store.getState().filtersData.insertionOrder.data;

  return {
    type: SET_INSERTION_ORDER,
    payload: insertionOrder.filter(function (item) {
      return data ? data.includes(item.key) : data;
    }),
  };
};

// Changed to grouped version below
export const getCampaignInsertionOrder = () => {
  return (dispatch) => {
    dispatch({ type: CAMPAIGN_INSERTION_ORDER_BEGIN });

    axios
      .get("api/sdf/insertion-orders")
      .then(({ data }) => {
        dispatch({ type: CAMPAIGN_INSERTION_ORDER_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: CAMPAIGN_INSERTION_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getCampaignGroupedInsertionOrder = (status) => {
  return (dispatch) => {
    dispatch({ type: CAMPAIGN_GROUPED_INSERTION_ORDER_BEGIN });
    const param = !status || status == "all" ? "" : `?status=${status}`;
    axios
      .get(`api/sdf/grouped-insertion-orders${param}`)
      .then(({ data }) => {
        dispatch({ type: CAMPAIGN_GROUPED_INSERTION_ORDER_SUCCESS, payload: Object.values(data) });
      })
      .catch(function (error) {
        dispatch({
          type: CAMPAIGN_GROUPED_INSERTION_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const setCampaignInsertionOrder = (data) => {
  let campaignInsertionOrder = store.getState().campaignFiltersData.campaignInsertionOrder.data;

  return {
    type: SET_CAMPAIGN_INSERTION_ORDER,
    payload: campaignInsertionOrder.filter(function (item) {
      return data ? data.includes(item.key) : data;
    }),
  };
};

export const setCampaignGroupedInsertionOrder = (data) => {
  let campaignInsertionOrder = store.getState().campaignFiltersData.campaignInsertionOrder.data;

  return {
    type: SET_CAMPAIGN_GROUPED_INSERTION_ORDER,
    payload: campaignInsertionOrder.filter(function (item) {
      return data ? data.includes(item.key) : data;
    }),
  };
};

export const setClientDataset = (data) => {
  let organizations = store.getState().filtersData.clientOrganization.current;
  let clients = organizations;

  localStorage.setItem("dataSetCurrent", JSON.stringify(data));

  return {
    type: SET_CLIENT_DATASET,
    payload: { current: clients.datasets.find((client) => client.name === data), data: clients },
  };
};

export const setClientOrganization = (data) => {
  let organizations = store.getState().filtersData.clientOrganization.data;
  localStorage.setItem("organizationCurrent", JSON.stringify(data));
  return {
    type: SET_CLIENT_ORGANIZATION,
    payload: organizations.find((organization) => organization.name === data),
  };
};

export const setPeriod = (data) => {
  return {
    type: SET_PERIOD,
    payload: data,
  };
};

export const getCampaignId = (sdf = false, status) => {
  return (dispatch) => {
    dispatch({ type: CAMPAIGN_ID_BEGIN });
    const param = !status || status == "all" ? "" : `?status=${status}`;

    let url = "api/client/campaign/list";
    if (sdf) url = `api/sdf/campaigns${param}`;

    axios
      .get(url)
      .then(({ data }) => {
        dispatch({ type: CAMPAIGN_ID_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: CAMPAIGN_ID_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const setCampaignId = (data) => {
  let campaignId = store.getState().filtersData.campaignId.data;
  return {
    type: SET_CAMPAIGN_ID,
    payload: campaignId.filter(function (item) {
      return data ? data.includes(item.key) : data;
    }),
  };
};
