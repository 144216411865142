import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import IntlMessages from "util/IntlMessages";
import { userForgotPassword } from "../appRedux/actions/Auth";

import logo from "./../assets/images/logo-dark.png";
import logoWhite from "./../assets/images/logo-white.png";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [information, setInformation] = useState(false);

  const handleSubmit = (values) => {
    setSubmitting(true);

    dispatch(userForgotPassword(values)).then((data) => {
      setInformation(data[0]);
      setSubmitting(false);
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} alt="" className="main-logo" />
          <img src={logoWhite} alt="" className="main-logo main-logo-white" />
        </div>
        <div className="gx-login-content">
          {!information ? (
            <>
              <div className="gx-mb-4">
                <h2>Forgot Your Password ?</h2>
                <p>
                  <IntlMessages id="app.userAuth.forgot" />
                </p>
              </div>

              <Form layout="vertical" onFinish={handleSubmit} className="gx-login-form gx-form-row0">
                <Form.Item
                  name="email"
                  rules={[
                    { type: "email", message: "The input is not valid E-mail!" },
                    { required: true, message: "Please input your E-mail!" },
                  ]}
                >
                  <Input type="email" placeholder="E-Mail Address" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={submitting} className=" btn-primary-alter">
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <div>{information}</div>
          )}
        </div>
      </div>
    </div>
  );
};

// const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default ForgotPassword;
