import {
  FETCH_INVENTORY_STATS_BEGIN,
  FETCH_INVENTORY_STATS_SUCCESS,
  FETCH_INVENTORY_STATS_FAILURE,
  FETCH_INVENTORY_WOW_SCORES_BEGIN,
  FETCH_INVENTORY_WOW_SCORES_SUCCESS,
  FETCH_INVENTORY_WOW_SCORES_FAILURE,
  FETCH_INVENTORY_WOW_SCORES_END,
  FETCH_INVENTORY_AOU_TABLE_METRICS_BEGIN,
  FETCH_INVENTORY_AOU_TABLE_METRICS_SUCCESS,
  FETCH_INVENTORY_AOU_TABLE_METRICS_FAILURE,
  FETCH_INVENTORY_TOP_METRICS_BEGIN,
  FETCH_INVENTORY_TOP_METRICS_SUCCESS,
  FETCH_INVENTORY_TOP_METRICS_FAILURE,
  FETCH_INVENTORY_AOU_METRICS_BEGIN,
  FETCH_INVENTORY_AOU_METRICS_SUCCESS,
  FETCH_INVENTORY_AOU_METRICS_FAILURE,
  FETCH_INVENTORY_CREATIVE_SIZE_METRICS_BEGIN,
  FETCH_INVENTORY_CREATIVE_SIZE_METRICS_SUCCESS,
  FETCH_INVENTORY_CREATIVE_SIZE_METRICS_FAILURE,
  FETCH_INVENTORY_EXCHANGE_METRICS_BEGIN,
  FETCH_INVENTORY_EXCHANGE_METRICS_SUCCESS,
  FETCH_INVENTORY_EXCHANGE_METRICS_FAILURE,
  INVENTORY_SET_FILTERS,
  INVENTORY_SET_LOADED,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { wowTransformData } from "../../util/Helpers";
import { applyGlobalFilters } from "./../../util/Filters";

export const setLoaded = () => {
  return {
    type: INVENTORY_SET_LOADED,
  };
};

export const setFilters = (data) => {
  return {
    type: INVENTORY_SET_FILTERS,
    payload: data,
  };
};

export const inventoryWelcomeData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_STATS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/inventory/counts", "inventoryData", {
          lineItem: true,
          insertionOrder: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_INVENTORY_STATS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_INVENTORY_STATS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const inventoryWowScoresData = (arr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_WOW_SCORES_BEGIN });
    let index = 0;

    const fullArray = arr.map((item) => {
      let queryString = applyGlobalFilters("api/inventory/scores", "inventoryData", {
        period: true,
        selected: item.query,
        lineItem: true,
        insertionOrder: true,
        campaignId: true,
      });
      return {
        ...item,
        url: queryString,
      };
    });

    for (let i = 0; i < fullArray.length; i++) {
      axios
        .get(fullArray[i].url)
        .then(({ data }) => {
          index++;

          dispatch({
            type: FETCH_INVENTORY_WOW_SCORES_SUCCESS,
            payload: { [fullArray[i].index]: wowTransformData(data) },
          });
          if (index >= fullArray.length) {
            dispatch({
              type: FETCH_INVENTORY_WOW_SCORES_END,
            });
          }
        })
        .catch(function (error) {
          console.log(i);
          dispatch({
            type: FETCH_INVENTORY_WOW_SCORES_FAILURE,
            payload: error.message,
          });
        });
    }
  };
};

export const inventoryAouTableMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_AOU_TABLE_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/inventory/metrics/app-url/table", "inventoryData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_INVENTORY_AOU_TABLE_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_INVENTORY_AOU_TABLE_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const inventoryTopMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_TOP_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/inventory/metrics/top", "inventoryData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_INVENTORY_TOP_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_INVENTORY_TOP_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const inventoryAouMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_AOU_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/inventory/metrics/app-url", "inventoryData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_INVENTORY_AOU_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_INVENTORY_AOU_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const inventoryCreativeSizeMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_CREATIVE_SIZE_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/inventory/metrics/creative-size", "inventoryData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_INVENTORY_CREATIVE_SIZE_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_INVENTORY_CREATIVE_SIZE_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const inventoryExchangeMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_INVENTORY_EXCHANGE_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/inventory/metrics/exchange", "inventoryData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_INVENTORY_EXCHANGE_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_INVENTORY_EXCHANGE_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};
