import React from "react";
import { Card } from "antd";

const Loading = ({ card, className = "" }) => {
  return card ? (
    <Card className={`gx-card ${className}`}>Loading...</Card>
  ) : (
    <div className={className}>Loading...</div>
  );
};

export default Loading;
