import React from "react";
import ChangePassword from "./ChangePassword";
import OAuth from "./OAuth";
import AddAdvertisers from "./AddAdvertisers";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Error404 from "../Error404";

const User = () => {
  const location = useLocation();

  return (
    <div className="center-box">
      <Route exact path="/user">
        <Redirect to="/user/change-password" />
      </Route>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={300}>
          <Switch location={location}>
            <Route path="/user/change-password" children={() => <ChangePassword />} />
            <Route path="/user/oauth" children={() => <OAuth />} />
            <Route path="/user/add-advertisers" children={() => <AddAdvertisers />} />
            <Route component={Error404} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default User;
