import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_TOKEN_SET,
  USER_REFRESH_TOKEN_SET,
  FORGET_PASSWORD_START,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "../../constants/ActionTypes";
import { ROLE_SUPER_ADMIN, ROLE_DV360, ROLE_SDF } from "../../constants/Roles";
import axios from "util/Api";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const parseJwt = (token) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");

  return JSON.parse(window.atob(base64));
};

export const getUser = () => {
  let user = localStorage.getItem("user");
  if (!user) {
    return {
      firstName: "unknown",
      lastName: "",
      roles: [],
      organizations: [],
    };
  }

  return JSON.parse(user);
};

export const hasUserRole = (role) => {
  let user = getUser();

  if (user.roles.includes(ROLE_SUPER_ADMIN)) {
    return true;
  }

  return user.roles.includes(role);
};

export const getUserDefaultPage = () => {
  if (hasUserRole(ROLE_DV360)) {
    return "/overview";
  }

  if (hasUserRole(ROLE_SDF)) {
    return "/settings-qa";
  }
};

export const userSignIn = ({ username, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("api/login_check", {
        username: username,
        password: password,
      })
      .then(({ data }) => {
        if (data.token) {
          localStorage.setItem("token", JSON.stringify(data.token));
          localStorage.setItem("refresh_token", JSON.stringify(data.refresh_token));

          axios.defaults.headers.common = {
            Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
          };

          if (data.user) {
            localStorage.setItem("user", JSON.stringify(data.user));
          }

          setTimeout(() => {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: USER_TOKEN_SET, payload: data.token });
            dispatch({
              type: USER_REFRESH_TOKEN_SET,
              payload: data.refresh_token,
            });
          }, 100);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: FETCH_ERROR, payload: error.response.data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        }

        console.log("Error****:", error);
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};

export const userForgotPassword = ({ email }) => {
  return (dispatch) => {
    dispatch({ type: FORGET_PASSWORD_START });

    return axios
      .post("api/resetting/send-email", {
        email: email,
      })
      .then(({ data }) => {
        dispatch({ type: FORGET_PASSWORD_SUCCESS });
        return data;
      })
      .catch(function (error) {
        dispatch({ type: FORGET_PASSWORD_ERROR, payload: error.message });
      });
  };
};

export const userResetPassword = ({ password, confirm, token }) => {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_START });

    return axios
      .post("api/resetting/reset", {
        password,
        repeat_password: confirm,
        token,
      })
      .then(({ data }) => {
        dispatch({ type: RESET_PASSWORD_SUCCESS });
        return { type: "success", response: data };
      })
      .catch(function (error) {
        dispatch({ type: RESET_PASSWORD_ERROR, payload: error.message });

        return { type: "error", response: error.response.data };
      });
  };
};

export const userChangePassword = ({ oldPassword, password, confirm }) => {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_START });

    return axios
      .post("/api/resetting/set", {
        old_password: oldPassword,
        password,
        repeat_password: confirm,
      })
      .then(({ data }) => {
        dispatch({ type: RESET_PASSWORD_SUCCESS });
        return { type: "success", response: data };
      })
      .catch(function (error) {
        dispatch({ type: RESET_PASSWORD_ERROR, payload: error.message });

        return { type: "error", response: error.response.data };
      });
  };
};

export const refreshToken = (originalReq, resolve, dispatch) => {
  let res = fetch(`${process.env.REACT_APP_API_URL}api/token/refresh`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify({
      refresh_token: JSON.parse(localStorage.getItem("refresh_token")),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (JSON.stringify(res.refresh_token) === "undefined") {
        localStorage.setItem("token", JSON.stringify(res.token));
        localStorage.setItem("refresh_token", JSON.stringify(res.refresh_token));
        originalReq.headers.Authorization = `Bearer ${res.token}`;
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.reload();
      }

      return axios(originalReq);
    })
    .catch(function (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      window.location.reload();
    });
  resolve(res);
};
