import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import ThemeSwitcher from "./ThemeSwitcher";
import FilterClients from "../../components/custom/FilterClients";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { ROLE_DV360, ROLE_SDF } from "../../constants/Roles";
import IntlMessages from "../../util/IntlMessages";
import { hasUserRole } from "../../appRedux/actions/Auth";

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
  const datasetVisible = useSelector(({ dataset }) => dataset.visible);
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  let selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[0];
  selectedKeys = defaultOpenKeys;

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <ThemeSwitcher />
          <div className="gx-mt-3">
            <FilterClients />
          </div>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {datasetVisible.scoreEnabled === true && (
              <Menu.SubMenu title="Score">
                <Menu.Item key="overview" hidden={!hasUserRole(ROLE_DV360)}>
                  <Link to="/overview">
                    <i className="icon icon-dasbhoard" />
                    <IntlMessages id="sidebar.overview" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="creative" hidden={!hasUserRole(ROLE_DV360)}>
                  <Link to="/creative">
                    <i className="icon icon-important" />
                    <IntlMessages id="sidebar.creative" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="geography" hidden={!hasUserRole(ROLE_DV360)}>
                  <Link to="/geography">
                    <i className="icon icon-map-styled" />
                    <IntlMessages id="sidebar.geography" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="strategy" hidden={!hasUserRole(ROLE_DV360)}>
                  <Link to="/strategy">
                    <i className="icon icon-chart-line" />
                    <IntlMessages id="sidebar.strategy" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="temporal" hidden={!hasUserRole(ROLE_DV360)}>
                  <Link to="/temporal">
                    <i className="icon icon-dasbhoard" />
                    <IntlMessages id="sidebar.temporal" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="inventory" hidden={!hasUserRole(ROLE_DV360)}>
                  <Link to="/inventory">
                    <i className="icon icon-callout" />
                    <IntlMessages id="sidebar.inventory" />
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            <Menu.SubMenu title="Assure">
              <Menu.Item key="settings-qa" hidden={!hasUserRole(ROLE_SDF)}>
                <Link to="/settings-qa">
                  <i className="icon icon-megaphone" />
                  <IntlMessages id="sidebar.settingsQA" />
                </Link>
              </Menu.Item>
              <Menu.Item key="settings-overview" hidden={!hasUserRole(ROLE_SDF)}>
                <Link to="/settings-overview">
                  <i className="icon icon-megaphone" />
                  <IntlMessages id="sidebar.settingsOverview" />
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Build">
              <Menu.Item key="name-generator" hidden={!hasUserRole(ROLE_SDF)}>
                <Link to="/name-generator">
                  <i className="icon icon-editor" />
                  <IntlMessages id="sidebar.nameGenerator" />
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <div className="gx-sidebar-separator"></div>
            <Menu.Item key="privacy-policy">
              <Link to="/map/privacy-policy">
                <IntlMessages id="sidebar.privacyPolicy" />
              </Link>
            </Menu.Item>
            <Menu.Item key="terms-and-conditions">
              <Link to="/map/terms-and-conditions">
                <IntlMessages id="sidebar.termsAndConditions" />
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
