import { FETCH_OAUTH_LINK_BEGIN, FETCH_OAUTH_LINK_SUCCESS, FETCH_OAUTH_LINK_FAILURE } from "../../constants/ActionTypes";

const INIT_STATE = {
  oAuth: {
    loading: true,
    status: null,
    error: null,
    data: [],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_OAUTH_LINK_BEGIN: {
      return {
        ...state,
        oAuth: {
          loading: true,
          status: null,
          error: null,
        },
      };
    }
    case FETCH_OAUTH_LINK_SUCCESS: {
      return {
        ...state,
        oAuth: {
          loading: false,
          status: "success",
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_OAUTH_LINK_FAILURE: {
      return {
        ...state,
        oAuth: {
          loading: false,
          status: "failure",
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
