import {
  FETCH_DATASET_SCORE_BEGIN,
  FETCH_DATASET_SCORE_SUCCESS,
  FETCH_DATASET_SCORE_FAILURE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  visible: { scoreEnabled: true },
  error: null,
  loading: true,
};

export const DatasetActions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DATASET_SCORE_BEGIN: {
      return {
        current: localStorage.getItem("dataSetCurrent") ? JSON.parse(localStorage.getItem("dataSetCurrent")) : null,
        visible: true,
        error: null,
        loading: true,
      };
    }
    case FETCH_DATASET_SCORE_SUCCESS: {
      return {
        visible: action.payload,
        error: null,
        loading: false,
      };
    }

    case FETCH_DATASET_SCORE_FAILURE: {
      return {
        visible: true,
        error: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
};
