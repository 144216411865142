import React from "react";
import Widget from "components/Widget/index";

const GrowthCard = ({ title, children, styleName, desc, bgColor, textColor, icon, value }) => {
  return (
    <Widget styleName={`gx-card-full gx-bg-${bgColor}`}>
      <div className="gx-actchart gx-px-4 gx-pt-4 gx-mb-3 gx-text-grey">
        <div className="ant-row ant-row-flex-middle">
          <div className="ant-col">
            <h2 className="gx-fs-xxl gx-font-weight-medium gx-mb-1">{value}%</h2>
            <p className={`gx-text-${textColor}`}>{desc}</p>
          </div>
          <div className="ant-col gx-ml-auto gx-mb-auto gx-fs-xl">
            <h2 className={`gx-fs-xxl gx-font-weight-medium gx-mb-1 gx-chart-${styleName}`}>
              {title}% <i className={`icon icon-menu-up gx-fs-sm`} />
            </h2>
          </div>
        </div>
      </div>
      {children}
    </Widget>
  );
};

export default GrowthCard;
