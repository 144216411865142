import {
  FETCH_TEMPORAL_STATS_BEGIN,
  FETCH_TEMPORAL_STATS_SUCCESS,
  FETCH_TEMPORAL_STATS_FAILURE,
  FETCH_TEMPORAL_WOW_SCORES_BEGIN,
  FETCH_TEMPORAL_WOW_SCORES_SUCCESS,
  FETCH_TEMPORAL_WOW_SCORES_FAILURE,
  FETCH_TEMPORAL_WOW_SCORES_END,
  FETCH_TEMPORAL_TOP_METRICS_BEGIN,
  FETCH_TEMPORAL_TOP_METRICS_SUCCESS,
  FETCH_TEMPORAL_TOP_METRICS_FAILURE,
  FETCH_TEMPORAL_TOD_METRICS_BEGIN,
  FETCH_TEMPORAL_TOD_METRICS_SUCCESS,
  FETCH_TEMPORAL_TOD_METRICS_FAILURE,
  FETCH_TEMPORAL_DOW_METRICS_BEGIN,
  FETCH_TEMPORAL_DOW_METRICS_SUCCESS,
  FETCH_TEMPORAL_DOW_METRICS_FAILURE,
  FETCH_TEMPORAL_DOW_TOD_METRICS_BEGIN,
  FETCH_TEMPORAL_DOW_TOD_METRICS_SUCCESS,
  FETCH_TEMPORAL_DOW_TOD_METRICS_FAILURE,
  TEMPORAL_SET_FILTERS,
  TEMPORAL_SET_LOADED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loaded: false,
  filters: {
    clientDataset: null,
    period: "Week",
    charts: [],
  },
  welcomeStats: {
    loading: false,
    data: [],
    error: null,
  },
  wowScoresStats: {
    loading: true,
    data: [],
    error: null,
  },
  topMetrics: {
    loading: false,
    data: [],
    error: null,
  },
  todMetrics: {
    loading: false,
    data: [],
    error: null,
  },
  dowMetrics: {
    loading: false,
    data: [],
    error: null,
  },
  dowTodMetrics: {
    loading: false,
    data: [],
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEMPORAL_SET_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }
    case TEMPORAL_SET_FILTERS: {
      return {
        ...state,
        filters: Object.assign(state.filters, action.payload),
      };
    }
    case FETCH_TEMPORAL_DOW_TOD_METRICS_BEGIN: {
      return {
        ...state,
        dowTodMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_TEMPORAL_DOW_TOD_METRICS_SUCCESS: {
      return {
        ...state,
        dowTodMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_TEMPORAL_DOW_TOD_METRICS_FAILURE: {
      return {
        ...state,
        dowTodMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_TEMPORAL_DOW_METRICS_BEGIN: {
      return {
        ...state,
        dowMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_TEMPORAL_DOW_METRICS_SUCCESS: {
      return {
        ...state,
        dowMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_TEMPORAL_DOW_METRICS_FAILURE: {
      return {
        ...state,
        dowMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_TEMPORAL_TOD_METRICS_BEGIN: {
      return {
        ...state,
        todMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_TEMPORAL_TOD_METRICS_SUCCESS: {
      return {
        ...state,
        todMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_TEMPORAL_TOD_METRICS_FAILURE: {
      return {
        ...state,
        todMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_TEMPORAL_STATS_BEGIN: {
      return {
        ...state,
        welcomeStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_TEMPORAL_STATS_SUCCESS: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_TEMPORAL_STATS_FAILURE: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_TEMPORAL_TOP_METRICS_BEGIN: {
      return {
        ...state,
        topMetrics: {
          loading: true,
          error: null,
        },
      };
    }

    case FETCH_TEMPORAL_TOP_METRICS_SUCCESS: {
      return {
        ...state,
        topMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_TEMPORAL_TOP_METRICS_FAILURE: {
      return {
        ...state,
        topMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_TEMPORAL_WOW_SCORES_BEGIN: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: {},
        },
      };
    }
    case FETCH_TEMPORAL_WOW_SCORES_SUCCESS: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: Object.assign(state.wowScoresStats.data, action.payload),
        },
      };
    }

    case FETCH_TEMPORAL_WOW_SCORES_FAILURE: {
      return {
        ...state,
        wowScoresStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_TEMPORAL_WOW_SCORES_END: {
      return {
        ...state,
        wowScoresStats: {
          ...state.wowScoresStats,
          loading: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
