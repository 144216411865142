import React from "react";
import { useSelector } from "react-redux";
import OverviewRow from "./OverviewRow";

const SettingsOverview = () => {
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.data);

  return (
    <div>
      {filterClientDataset &&
        filterClientDataset.map((dataset) => {
          return <OverviewRow dataset={dataset} key={dataset.name} />;
        })}
    </div>
  );
};

export default SettingsOverview;
