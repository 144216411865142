import React, { useState, useEffect } from "react";
import { Select, Row, Col, Input, Button, Radio, DatePicker, Card } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const { Option } = Select;

const CampaignNamingGenerator = ({ copyToClipboard, sanitizeName, unlockGenerator, canGenerate }) => {
  const [namingGenerator, setNamingGenerator] = useState([""]);
  const [date, setDate] = useState();
  const [freeform, setFreeform] = useState("");
  const [businessUnit, setBusinessUnit] = useState({});

  const onChangeBusinessUnit = (event) => {
    setBusinessUnit({ value: event.target.value, parsed: event.target.value.replace(/\s+/g, "").replace(/_/g, "") });
  };

  const onChangeFreeform = (event) => {
    setFreeform(event.target.value);
  };

  const onChangeDate = (date, dateString) => {
    setDate(`${date.year()}_Q${date.quarter().toString()}_${date.format("MMM")}`);
  };

  const addNamingGenerator = () => {
    setNamingGenerator((prevState) => [...prevState, ""]);
  };

  const removeNamingGenerator = (index) => {
    let temp = [...namingGenerator];
    temp.splice(index, 1);
    setNamingGenerator(temp);
  };

  const updateNamingGenerator = (index) => {
    let temp = [...namingGenerator];
    let ff = freeform.length > 0 ? `_${freeform}` : "";
    temp[index] = `${businessUnit.parsed}_${date}${ff}`.replace(/\s+/g, "");
    setNamingGenerator(temp);
  };

  useEffect(() => {
    if (businessUnit.length !== 0 && date && canGenerate.adv) {
      unlockGenerator("campaign", true);
    } else {
      unlockGenerator("campaign", false);
    }
  }, [businessUnit, date, canGenerate.adv]);

  return (
    <Card title="Campaign Naming Generator" className="gx-card">
      <Row>
        <Col xs={8}>
          <Input placeholder="Business Unit" onChange={onChangeBusinessUnit} />
        </Col>
        <Col xs={8}>
          <DatePicker picker="month" onChange={onChangeDate} placeholder="Start Date" style={{ width: "100%" }} />
        </Col>
        <Col xs={8} className="gx-mb-1">
          <Input placeholder="Freeform" onChange={onChangeFreeform} />
        </Col>
        <Col xs={24} className="gx-mb-1" style={{ alignItems: "center" }}>
          {namingGenerator.map((item, index) => {
            return (
              <Row className="gx-mb-2 gx-mt-3" key={index} style={{ width: "100%" }}>
                <Col xl={6} lg={7} md={7} sm={10} xs={24}>
                  <Button
                    type="primary"
                    style={{ width: "100%", margin: 0 }}
                    disabled={!canGenerate.campaign}
                    onClick={() => updateNamingGenerator(index)}
                  >
                    Campaign Naming Generator
                  </Button>
                </Col>

                <Col xl={14} lg={13} md={12} sm={9} xs={16}>
                  <Input
                    placeholder="Output naming"
                    value={namingGenerator[index]}
                    disabled
                    style={{ background: "#fff" }}
                  />
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={7}>
                  <Button
                    type="primary"
                    style={{ width: "100%", margin: 0 }}
                    disabled={namingGenerator[index].length == 0}
                    onClick={(e) => {
                      copyToClipboard(e, namingGenerator[index]);
                    }}
                  >
                    Copy
                  </Button>
                </Col>

                <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{ display: "flex", alignItems: "center" }}>
                  <MinusCircleOutlined onClick={() => removeNamingGenerator(index)} />
                </Col>
              </Row>
            );
          })}
          <Button type="dashed" onClick={() => addNamingGenerator()} block icon={<PlusOutlined />}>
            Add Campaign generator
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default CampaignNamingGenerator;
