import { FETCH_REFRESH_BEGIN, FETCH_REFRESH_SUCCESS, FETCH_REFRESH_FAILURE } from "../../constants/ActionTypes";

import axios from "util/Api";

export const refresh = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_REFRESH_BEGIN });

    axios
      .get("api/sdf/refresh")
      .then(() => {
        dispatch({ type: FETCH_REFRESH_SUCCESS });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_REFRESH_FAILURE,
          payload: error.message,
        });
      });
  };
};
