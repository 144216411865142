import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd";
import GrowthCardCustom from "./../components/custom/GrowthCardCustom";
import Widget from "components/Widget/index";
import Loading from "./../components/Loading";

import { XAxis, YAxis, Bar, BarChart, Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip } from "recharts";
import WelComeCard from "./Welcome";
import { valueWithUnits } from "./../util/Helpers";

import { overviewTopChartData } from "./../appRedux/actions/Overview";

const PageTop = ({ width, welcomeData, wowScores, chartAxes = null }) => {
  const [mapScore, setMapScore] = useState();
  const dispatch = useDispatch();

  const siteVisitLoading = useSelector(({ overviewData }) => overviewData.topChartStats.loading);
  const siteVisit = useSelector(({ overviewData }) => overviewData.topChartStats.data);
  const currentFilters = useSelector(({ filtersData }) => filtersData);
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const filterPeriod = useSelector(({ filtersData }) => filtersData.period.current);

  useEffect(() => {
    if (typeof siteVisit === "undefined" || siteVisit.length === 0) {
      dispatch(overviewTopChartData());
    }
  }, []);

  useEffect(() => {
    dispatch(overviewTopChartData());
  }, [filterClientDataset]);

  useEffect(() => {
    setMapScore(wowScores.data[wowScores.mainChart.part]);
  }, [wowScores]);

  return (
    <>
      <Row className="gx-mt-4">
        <Col xl={14} lg={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                  <WelComeCard data={welcomeData} />
                </Col>

                <Col xl={18} lg={24} md={24} sm={24} xs={24}>
                  <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                    <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Total spend by advertiser</h6>
                    <ResponsiveContainer width="100%" height={184}>
                      {!siteVisitLoading && siteVisit && siteVisit[0] ? (
                        <AreaChart
                          data={siteVisit}
                          style={{ Area: { bgColor: "red" } }}
                          margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
                        >
                          <Tooltip
                            formatter={function (value, name) {
                              return valueWithUnits(value, "currency");
                            }}
                            labelFormatter={function (value) {
                              return `${currentFilters.period.current}: ${value}`;
                            }}
                          />
                          <CartesianGrid horizontal={false} strokeDasharray="3 3" />
                          <XAxis dataKey="name" hide={!chartAxes} />
                          {Object.getOwnPropertyNames(siteVisit[0])?.map((item, index) => {
                            if ("name" !== item) {
                              return (
                                <Area type="monotone" key={index} dataKey={item} stroke="#44546a" fill="#44546a" />
                              );
                            }
                          })}
                        </AreaChart>
                      ) : (
                        <Loading />
                      )}
                    </ResponsiveContainer>
                    {chartAxes ? (
                      <p className="gx-text-center" style={{ marginBottom: "-15px" }}>
                        {filterPeriod ? (filterPeriod !== "all" ? `${filterPeriod}s` : "") : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xl={4} sm={8} xs={24}>
          {mapScore
            ? Object.keys(mapScore)
                .filter((item) => mapScore[item].name === wowScores.mainChart.section)
                .map((item, index) => {
                  return (
                    <GrowthCardCustom
                      key={index}
                      bgColor="white"
                      title={mapScore[item].diff}
                      icon={+mapScore[item].diff < 0 ? "icon-menu-up" : "icon-menu-down"}
                      children={
                        <ResponsiveContainer width="100%" height={177}>
                          <AreaChart data={mapScore[item].weeks} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                            <XAxis hide="true" dataKey="name" />
                            <YAxis hide="true" />
                            <Tooltip
                              formatter={function (value, name) {
                                return `${value}%`;
                              }}
                              labelFormatter={function (value) {
                                return `${currentFilters.period.current}: ${value}`;
                              }}
                            />
                            <defs>
                              <linearGradient id="color2" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="5%" stopColor="#44546A" stopOpacity={0.9} />
                                <stop offset="95%" stopColor="#023047" stopOpacity={0.9} />
                              </linearGradient>
                            </defs>
                            <Area
                              type="monotone"
                              strokeWidth={0}
                              dataKey="value"
                              stackId="2"
                              stroke="#4D95F3"
                              fill="url(#color2)"
                              fillOpacity={1}
                              name="MAP Score"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      }
                      styleName={mapScore[item].diff < 0 ? "down" : "up"}
                      desc="MAP Score"
                      value={mapScore[item].weeks[mapScore[item].weeks.length - 1].value}
                    />
                  );
                })
            : null}
        </Col>
        <Col xl={6} sm={16}>
          {wowScores && wowScores.asideCharts
            ? wowScores.asideCharts.map((config) => {
                return Object.keys(wowScores.data[config.part])
                  .filter((part) => {
                    return wowScores.data[config.part][part].name === config.section;
                  })
                  .map((item) => {
                    return (
                      <Widget key={item} styleName="gx-mb-2 gx-pb-0 gx-card-widget-small">
                        <div className="gx-d-flex">
                          <p className="gx-text-uppercase gx-fs-sm gx-mb-0">
                            {`LAST ${currentFilters.period.current} ${config.name} SCORE`}
                          </p>
                          <p className="gx-ml-auto gx-text-primary gx-mb-0">
                            {Math.abs(wowScores.data[config.part][item].diff) || 0}%
                            {wowScores.data[config.part][item].diff < 0 ? (
                              <i className="icon icon-menu-down gx-fs-sm" />
                            ) : (
                              <i className="icon icon-menu-up gx-fs-sm" />
                            )}
                          </p>
                        </div>
                        <Row className="ant-row-flex-middle">
                          <Col lg={14} md={12} sm={24} xs={24} className="gx-pr-2">
                            <div className="gx-pt-2">
                              <h2 className="gx-fs-xl gx-font-weight-medium gx-mb-1 gx-text-black">
                                {
                                  wowScores.data[config.part][item].weeks[
                                    wowScores.data[config.part][item].weeks.length - 1
                                  ].value
                                }
                                %
                              </h2>
                              <p className="gx-mb-2 gx-fs-sm gx-mb-md-0">{`${currentFilters.period.current} on ${currentFilters.period.current} ${config.name} Score`}</p>
                            </div>
                          </Col>
                          <Col lg={10} md={12} sm={24} xs={24}>
                            <ResponsiveContainer width="100%" height={30}>
                              <BarChart
                                data={wowScores.data[config.part][item].weeks}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                              >
                                <Tooltip
                                  formatter={function (value, name) {
                                    return `${value}%`;
                                  }}
                                  labelFormatter={function (value) {
                                    return `${currentFilters.period.current}: ${value}`;
                                  }}
                                />
                                <XAxis hide="true" dataKey="name" />
                                <YAxis hide="true" />
                                <defs>
                                  <linearGradient id="color08" x1="0" y1="1" x2="0" y2="0">
                                    <stop offset="5%" stopColor="#ED7D31" stopOpacity={0.9} />
                                    <stop offset="95%" stopColor="#f1b084" stopOpacity={0.9} />
                                  </linearGradient>
                                </defs>
                                <Bar dataKey="value" fill="url(#color08)" barSize={4} name={`${config.name} Score`} />
                              </BarChart>
                            </ResponsiveContainer>
                          </Col>
                        </Row>
                      </Widget>
                    );
                  });
              })
            : null}
        </Col>
      </Row>
    </>
  );
};

export default PageTop;
