import moment from "moment";
import { store } from "./../NextApp";

export const wowTransformData = (data) => {
  const keys = getUniqKays(data);
  let wowData = [];
  for (let i = 0; i < keys.length; i++) {
    let weeksValues = getWeeksValues(data, keys[i]);
    wowData.push({
      name: keys[i],
      weeks: weeksValues,
      diff:
        weeksValues.length >= 2
          ? relDiff(weeksValues[weeksValues.length - 1].value, weeksValues[weeksValues.length - 2].value)
          : 0,
    });
  }

  return wowData;
};

const getWeeksValues = (data, key) => {
  let weeks = [];
  Object.keys(data).map((item) => {
    weeks.push({
      name: item,
      value: data[item][key],
    });
    return true;
  });
  return weeks;
};

const getUniqKays = (data) => {
  let keysArray = [];

  Object.keys(data).map((item) => {
    Object.keys(data[item]).map((key) => {
      if (!keysArray.includes(key)) {
        keysArray.push(key);
      }
      return true;
    });
    return true;
  });

  return keysArray;
};

const relDiff = (a, b) => {
  return ((100 * (a - b)) / ((a + b) / 2)).toFixed(0);
};

// Add key for table data and replace name for children rows
export const prepareTableData = (data, replacedKey, replacedValue) => {
  let result = Object.entries(data)
    .flat()
    .filter((item) => typeof item !== "string")
    .map((item, index) => {
      return {
        ...item,
        children: item.children
          ? item.children.map((child, subIndex) => {
              return {
                ...child,
                [replacedKey]: child[replacedValue],
                key: `${index}-${subIndex}`,
              };
            })
          : null,
        key: index,
      };
    });

  return result;
};

export const getLastUrlPart = () => {
  var pageURL = window.location.href;
  var lastURLSegment = pageURL.substr(pageURL.lastIndexOf("/") + 1);

  return lastURLSegment;
};

export const valueWithUnits = (value, units) => {
  let currency = store.getState().filtersData.clientDataset.current;
  if (currency) {
    switch (units) {
      case "currency":
        return value ? `${currency.currencySymbol}${value.toLocaleString("en-GB")}` : "";
        break;

      default:
        return `${value.toLocaleString("en-GB")}${units ? units : ""}`;
    }
  }
};

export const handleFileDownload = (data) => {
  const hiddenElement = document.createElement("a");

  hiddenElement.href = `data:text/csv;base64,${data}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = "export.csv";
  hiddenElement.click();
};
