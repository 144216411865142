import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Input } from "antd";
import Loading from "../Loading";
import { getLineItem, setLineItem } from "../../appRedux/actions/Filters";

const InputGroup = Input.Group;
const Option = Select.Option;

const FilterClients = ({ sdf = false, setIsSelected }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("Active");

  const lineItem = useSelector(({ filtersData }) => filtersData.lineItem);

  const onDatasetChange = (value) => {
    setIsSelected(value.length > 0 ? true : false);
    dispatch(setLineItem(value));
  };

  const getCurrentFilter = () => {
    return lineItem.current ? lineItem.current.map((item) => item.key) : false;
  };

  // useEffect(() => {
  //   // if (!lineItem.data) {
  //   dispatch(getLineItem(sdf));
  //   // }
  // }, []);

  useEffect(() => {
    if (status !== null) {
      dispatch(getLineItem(sdf, status == "All" ? "" : status));
    }
  }, [status]);

  const handleStatusChange = (val) => {
    setStatus(val);
  };

  return (
    <>
      {lineItem.loading ? (
        <Loading className="gx-my-2" />
      ) : lineItem.data ? (
        <InputGroup compact style={{ display: "flex", alignItems: "flex-start" }}>
          <Select defaultValue={status} onChange={handleStatusChange} style={{ width: "100px" }}>
            <Option value="All">All</Option>
            <Option value="Active">Active</Option>
            <Option value="Paused">Paused</Option>
            <Option value="Archived">Archived</Option>
            <Option value="Deleted">Deleted</Option>
          </Select>
          <Select
            mode="multiple"
            dropdownMatchSelectWidth={false}
            placeholder="Line item"
            onChange={onDatasetChange}
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            style={{
              minWidth: "130px",
              maxHeight: "130px",
              maxWidth: "calc(100% - 100px)",
            }}
            value={getCurrentFilter() || undefined}
          >
            {lineItem.data.map((item, index) => (
              <Select.Option key={index} value={item.key}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </InputGroup>
      ) : (
        <div className="gx-my-2">No data</div>
      )}
    </>
  );
};

export default FilterClients;
