import { combineReducers } from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Overview from "./Overview";
import Creative from "./Creative";
import Geography from "./Geography";
import Strategy from "./Strategy";
import Temporal from "./Temporal";
import Inventory from "./Inventory";
import Filters from "./Filters";
import Refresh from "./Refresh";
import OAuth from "./OAuth";
import Advertiser from "./Advertiser";
import CampaignFilters from "./CampaignFilters";
import Campaign from "./Campaign";
import CampaignOverview from "./CampaignOverview";
import { connectRouter } from "connected-react-router";
import { DatasetActions } from "./Dataset";

export default (history) =>
  combineReducers({
    dataset: DatasetActions,
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    overviewData: Overview,
    creativeData: Creative,
    geographyData: Geography,
    strategyData: Strategy,
    temporalData: Temporal,
    inventoryData: Inventory,
    filtersData: Filters,
    refreshData: Refresh,
    oauthLinkData: OAuth,
    advertisersData: Advertiser,
    campaignFiltersData: CampaignFilters,
    campaignData: Campaign,
    campaignOverviewData: CampaignOverview,
  });
