import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import RemoveFilter from "../components/custom/RemoveFilter";

const TableMetrics = ({ columns, data, options, setFilters }) => {
  const dispatch = useDispatch();

  const onClickRow = (record) => {
    return {
      onClick: () => {
        if (options.chart.key instanceof Array) {
          for (var i = options.chart.key.length - 1; i >= 0; i--) {
            if (options.chart.key[i] in record) {
              dispatch(
                setFilters({
                  charts: [...options.filters.charts, { [options.chart.filter[i]]: record[options.chart.key[i]] }],
                })
              );
              break;
            }
          }
        } else {
          dispatch(
            setFilters({ charts: [...options.filters.charts, { [options.chart.filter]: record[options.chart.key] }] })
          );
        }
      },
    };
  };

  return (
    <>
      {options.filters.charts.find((filter) => options.chart.filter in filter) ? (
        <RemoveFilter
          setFilters={setFilters}
          filtersData={options.filters.charts}
          removedFilter={options.chart.filter}
        />
      ) : (
        false
      )}
      <Table
        onRow={onClickRow}
        className="gx-table-responsive"
        columns={columns}
        dataSource={data}
        pagination={{
          pageSize: 9,
          showTotal: (total) => `Total ${total} items`,
        }}
        size="middle"
      />
    </>
  );
};

export default TableMetrics;
