import React, { useState, useEffect } from "react";
import { Select, Row, Col, Input, Button, Radio, Card } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const { Option } = Select;

const markets = [
  { code: "AT", name: "Austria" },
  { code: "BE", name: "Belgium" },
  { code: "BG", name: "Bulgaria" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czechia" },
  { code: "DE", name: "Germany" },
  { code: "DK", name: "Denmark" },
  { code: "EE", name: "Estonia" },
  { code: "ES", name: "Spain" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GR", name: "Greece" },
  { code: "HR", name: "Croatia" },
  { code: "HU", name: "Hungary" },
  { code: "IE", name: "Ireland" },
  { code: "IT", name: "Italy" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "LV", name: "Latvia" },
  { code: "MT", name: "Malta" },
  { code: "NL", name: "Netherlands" },
  { code: "PO", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "RO", name: "Romania" },
  { code: "SE", name: "Sweden" },
  { code: "SI", name: "Slovenia" },
  { code: "SK", name: "Slovakia" },
  { code: "UK", name: "United Kingdom" },
];

const channels = [
  "Affiliate",
  "Direct Display Adserving",
  "Search",
  "Search Display",
  "Social",
  "Programmatic",
  "Standardised",
];
const platforms = {
  programmatic: ["Adform", "Amazon", "DV360", "TheTradeDesk", "MediaMath", "Xandr", "Standardised"],
  search: ["Search Ads 360", "Google Ads", "Bing", "Standardised"],
  search_display: ["Google Display Network", "Bing Display Network", "Standardised"],
  direct_display_adserving: ["Google Campaign Manager", "Sizmek", "Flashtalking", "Adform", "Standardised"],
  social: ["Facebook", "Instagram", "Tik-Tok", "LinkedIn", "Snap"],
  affiliate: ["Click Tracking"],
  standardised: ["Custom"],
};

const AdvNamingGenerator = ({ copyToClipboard, sanitizeName, unlockGenerator, canGenerate }) => {
  const [advertiserName, setAdvertiserName] = useState("");
  const [freeform, setFreeform] = useState("");

  const [market, setMarket] = useState();

  const [channel, setChannel] = useState();
  const [platform, setPlatform] = useState();

  const [disableGenerateAdvertiser, setDisableGenerateAdvertiser] = useState(true);

  const [namingGenerator, setNamingGenerator] = useState([""]);

  const onChangeAdvertiserName = (event) => {
    setAdvertiserName(event.target.value);
  };

  const onChangeFreeform = (event) => {
    setFreeform(event.target.value);
  };

  const onChangeChannel = (value) => {
    setChannel(value.target.value);
  };

  const onChangePlatform = (value) => {
    setPlatform(value);
  };

  useEffect(() => {
    if (advertiserName.length !== 0 && market) {
      unlockGenerator("adv", true);
    } else {
      unlockGenerator("adv", false);
    }
  }, [advertiserName, market]);

  const onChangeMarket = (value) => {
    setMarket(value);
  };

  const addNamingGenerator = () => {
    setNamingGenerator((prevState) => [...prevState, ""]);
  };

  const removeNamingGenerator = (index) => {
    let temp = [...namingGenerator];
    temp.splice(index, 1);
    setNamingGenerator(temp);
  };

  const updateNamingGenerator = (index) => {
    let temp = [...namingGenerator];
    let ff = freeform.length > 0 ? `_${freeform}` : "";
    temp[index] = `${advertiserName}_${market}${ff}`.replace(/\s+/g, "");
    setNamingGenerator(temp);
  };

  return (
    <Card title="Adv Naming Generator" className="gx-card">
      <Row>
        <Col xs={16}>
          <RadioGroup onChange={onChangeChannel}>
            {channels.map((item, index) => (
              <RadioButton key={index} value={item}>
                {item}
              </RadioButton>
            ))}
          </RadioGroup>
        </Col>
        <Col xs={8} className="gx-mb-1">
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Platform/Tech"
            onChange={onChangePlatform}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {channel
              ? platforms[sanitizeName(channel)].map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))
              : null}
          </Select>
        </Col>

        <Col xs={8} className="gx-mb-1">
          <Input placeholder="Advertiser name" onChange={onChangeAdvertiserName} />
        </Col>
        <Col xs={8} className="gx-mb-1">
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Market"
            onChange={onChangeMarket}
            filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          >
            {markets.map((item) => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={8} className="gx-mb-1">
          <Input placeholder="Freeform" onChange={onChangeFreeform} />
        </Col>

        <Col xs={24} className="gx-mb-1" style={{ alignItems: "center" }}>
          {namingGenerator.map((item, index) => {
            return (
              <Row className="gx-mb-2 gx-mt-3" key={index} style={{ width: "100%" }}>
                <Col xl={6} lg={7} md={7} sm={10} xs={24}>
                  <Button
                    type="primary"
                    style={{ width: "100%", margin: 0 }}
                    disabled={!canGenerate.adv}
                    onClick={() => updateNamingGenerator(index)}
                  >
                    Adv Naming Generator
                  </Button>
                </Col>

                <Col xl={14} lg={13} md={12} sm={9} xs={16}>
                  <Input
                    placeholder="Output naming"
                    value={namingGenerator[index]}
                    disabled
                    style={{ background: "#fff" }}
                  />
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={7}>
                  <Button
                    type="primary"
                    style={{ width: "100%", margin: 0 }}
                    disabled={namingGenerator[index].length == 0}
                    onClick={(e) => {
                      copyToClipboard(e, namingGenerator[index]);
                    }}
                  >
                    Copy
                  </Button>
                </Col>

                <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{ display: "flex", alignItems: "center" }}>
                  <MinusCircleOutlined onClick={() => removeNamingGenerator(index)} />
                </Col>
              </Row>
            );
          })}
          <Button type="dashed" onClick={() => addNamingGenerator()} block icon={<PlusOutlined />}>
            Add Naming generator
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AdvNamingGenerator;
