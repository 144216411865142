import React, { useState, useEffect } from "react";
import { Select, Row, Col, Input, Button, Radio, Cascader, Card } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const { Option } = Select;

const options = [
  {
    value: "RTB",
    label: "Real-Time Bidding",
    children: [
      {
        value: "DIS",
        label: "Display",
      },
      {
        value: "VID",
        label: "Video",
      },
      {
        value: "DISVID",
        label: "Display & Video",
      },
    ],
  },
  {
    value: "STY",
    label: "Story",
    children: [
      {
        value: "DIS",
        label: "Display",
      },
      {
        value: "VID",
        label: "Video",
      },
      {
        value: "DISVID",
        label: "Display & Video",
      },
    ],
  },
  {
    value: "TV",
    label: "TV",
    children: [
      {
        value: "VID",
        label: "Video",
      },
    ],
  },
];

const mainKPIs = [
  { key: "CPM", label: "CPM - Cost per thousand impressions" },
  { key: "vCPM", label: "vCPM - Cost per thousand viewable impressions" },
  { key: "VBTY", label: "Viewability - Percentage of impressions viewable" },
  { key: "CPIAVC", label: "CPIAVC - Cost per impression audible and visible at completion" },
  { key: "CTR", label: "CTR - Click through rate" },
  { key: "CPC", label: "CPC - Cost per click" },
  { key: "CCVR", label: "Click CVR- Conversion percentage per click" },
  { key: "ICVR", label: "Impression CVR - Conversions percentage per impression" },
  { key: "CPA", label: "CPA - Cost per action" },
  { key: "CPV", label: "CPV - Cost per video view" },
  { key: "CPCL", label: "CPCL - Cost per completed audio listen" },
  { key: "CRV", label: "Completion Rate (Video) - Percentage of video views completed" },
  { key: "CRA", label: "Completion Rate (Audio) - Percentage of audio listens completed" },
  { key: "VTR", label: "VTR - Video view rate" },
  { key: "TOS10", label: "TOS10 - Cost per video impression, on screen for at least 10 seconds" },
  { key: "CPCV", label: "CPCV - Cost per completed video view" },
  { key: "GCPE", label: "GMAIL CPE - Cost per gmail engagement" },
];

const InsertionNamingGenerator = ({ copyToClipboard, sanitizeName, unlockGenerator, canGenerate }) => {
  const [insertionOrderType, setInsertionOrderType] = useState();
  const [namingGenerator, setNamingGenerator] = useState([""]);

  const [mainKPI, setMainKPI] = useState();
  const [freeform, setFreeform] = useState("");

  const onInsertionOrderChange = (value) => {
    setInsertionOrderType(value.join("_"));
  };

  const onChangeMainKPI = (value) => {
    console.log(value);
    setMainKPI(value);
  };

  const onChangeFreeform = (event) => {
    setFreeform(event.target.value);
  };

  const addNamingGenerator = () => {
    setNamingGenerator((prevState) => [...prevState, ""]);
  };

  const removeNamingGenerator = (index) => {
    let temp = [...namingGenerator];
    temp.splice(index, 1);
    setNamingGenerator(temp);
  };

  const updateNamingGenerator = (index) => {
    let temp = [...namingGenerator];
    let ff = freeform.length > 0 ? `_${freeform}` : "";
    temp[index] = `${insertionOrderType}_${mainKPI}${ff}`.replace(/\s+/g, "");
    setNamingGenerator(temp);
  };

  useEffect(() => {
    if (insertionOrderType !== 0 && mainKPI && canGenerate.campaign) {
      unlockGenerator("insertion", true);
    } else {
      unlockGenerator("insertion", false);
    }
  }, [insertionOrderType, mainKPI, canGenerate.campaign]);

  return (
    <Card title="Insertion Order Naming Generator" className="gx-card">
      <Row>
        <Col xs={8}>
          <Cascader options={options} onChange={onInsertionOrderChange} placeholder="Insertion Order Type" />
        </Col>
        <Col xs={8} className="gx-mb-1">
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="KPI"
            onChange={onChangeMainKPI}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {mainKPIs.map((item, index) => (
              <Option key={index} value={item.key}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={8} className="gx-mb-1">
          <Input placeholder="Freeform" onChange={onChangeFreeform} />
        </Col>
        <Col xs={24} className="gx-mb-1" style={{ alignItems: "center" }}>
          {namingGenerator.map((item, index) => {
            return (
              <Row className="gx-mb-2 gx-mt-3" key={index} style={{ width: "100%" }}>
                <Col xl={6} lg={7} md={7} sm={10} xs={24}>
                  <Button
                    type="primary"
                    style={{ width: "100%", margin: 0 }}
                    disabled={!canGenerate.insertion}
                    onClick={() => updateNamingGenerator(index)}
                  >
                    Insertion Naming Generator
                  </Button>
                </Col>

                <Col xl={14} lg={13} md={12} sm={9} xs={16}>
                  <Input
                    placeholder="Output naming"
                    value={namingGenerator[index]}
                    disabled
                    style={{ background: "#fff" }}
                  />
                </Col>
                <Col xl={3} lg={3} md={4} sm={4} xs={7}>
                  <Button
                    type="primary"
                    style={{ width: "100%", margin: 0 }}
                    disabled={namingGenerator[index].length == 0}
                    onClick={(e) => {
                      copyToClipboard(e, namingGenerator[index]);
                    }}
                  >
                    Copy
                  </Button>
                </Col>

                <Col xl={1} lg={1} md={1} sm={1} xs={1} style={{ display: "flex", alignItems: "center" }}>
                  <MinusCircleOutlined onClick={() => removeNamingGenerator(index)} />
                </Col>
              </Row>
            );
          })}
          <Button type="dashed" onClick={() => addNamingGenerator()} block icon={<PlusOutlined />}>
            Add Insertion Order Generator
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default InsertionNamingGenerator;
