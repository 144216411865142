import {
  SET_CAMPAIGN_INSERTION_ORDER,
  SET_CAMPAIGN_GROUPED_INSERTION_ORDER,
  CAMPAIGN_INSERTION_ORDER_BEGIN,
  CAMPAIGN_INSERTION_ORDER_SUCCESS,
  CAMPAIGN_INSERTION_ORDER_FAILURE,
  CAMPAIGN_GROUPED_INSERTION_ORDER_BEGIN,
  CAMPAIGN_GROUPED_INSERTION_ORDER_SUCCESS,
  CAMPAIGN_GROUPED_INSERTION_ORDER_FAILURE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  campaignInsertionOrder: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN_INSERTION_ORDER_BEGIN: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: true,
          error: null,
        },
      };
    }
    case CAMPAIGN_INSERTION_ORDER_SUCCESS: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case CAMPAIGN_INSERTION_ORDER_FAILURE: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_CAMPAIGN_INSERTION_ORDER: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: false,
          error: null,
          data: state.campaignInsertionOrder.data,
          current: action.payload,
        },
      };
    }

    case CAMPAIGN_GROUPED_INSERTION_ORDER_BEGIN: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: true,
          error: null,
        },
      };
    }
    case CAMPAIGN_GROUPED_INSERTION_ORDER_SUCCESS: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case CAMPAIGN_GROUPED_INSERTION_ORDER_FAILURE: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_CAMPAIGN_GROUPED_INSERTION_ORDER: {
      return {
        ...state,
        campaignInsertionOrder: {
          loading: false,
          error: null,
          data: state.campaignInsertionOrder.data,
          current: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
