import React from "react";
import { Row, Col, Radio } from "antd";
import FilterPeriod from "../components/custom/FilterPeriod";
import FilterInsertionOrder from "../components/custom/FilterInsertionOrder";
import FilterLineItem from "../components/custom/FilterLineItem";
import FilterTimeRange from "../components/custom/FilterTimeRange";
import FilterCampaignId from "../components/custom/FilterCampaignId";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const NavigationTop = ({ navigateTo, navigationItems, menu = true }) => {
  const handleClick = (value) => {
    navigateTo(value.target.value);
  };

  return (
    <Row type="flex" gutter={[0, 0]} className="gx-mt-4 gx-mx-3">
      <Col>
        <Row gutter={[8, 8]}>
          {menu ? (
            <Col>
              <RadioGroup buttonStyle="solid" defaultValue={navigationItems[0].path} onChange={handleClick}>
                {navigationItems.map((item, index) => (
                  <RadioButton value={item.path} index={index} key={item.path}>
                    {item.label}
                  </RadioButton>
                ))}
              </RadioGroup>
            </Col>
          ) : (
            false
          )}

          <Col>
            <FilterPeriod />
          </Col>
        </Row>
      </Col>

      <Col flex="auto" className="gx-my-2 gx-my-lg-0 gx-ml-auto gx-ml-lg-3">
        <Row type="flex" justify="end">
          <Col span={5} className="gx-mr-2 gx-px-0 gx-my-lg-0 ">
            <FilterCampaignId />
          </Col>
          <Col span={5} className="gx-mr-2 gx-px-0 gx-my-lg-0 ">
            <FilterInsertionOrder />
          </Col>
          <Col span={5} className="gx-mr-2 gx-px-0 gx-my-lg-0 ">
            <FilterLineItem />
          </Col>
          <Col span={7} className="gx-mr-0 gx-px-0 gx-my-lg-0">
            <FilterTimeRange />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NavigationTop;
