import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import Loading from "../Loading";
import { getInsertionOrder, setInsertionOrder } from "../../appRedux/actions/Filters";

const FilterClients = () => {
  const dispatch = useDispatch();

  const insertionOrder = useSelector(({ filtersData }) => filtersData.insertionOrder);

  const onDatasetChange = (value) => {
    dispatch(setInsertionOrder(value));
  };

  const getCurrentFilter = () => {
    return insertionOrder.current ? insertionOrder.current.map((item) => item.key) : false;
  };

  useEffect(() => {
    if (!insertionOrder.data) {
      dispatch(getInsertionOrder());
    }
  }, []);

  return (
    <>
      {insertionOrder.loading ? (
        <Loading className="gx-my-2" />
      ) : insertionOrder.data ? (
        <Select
          mode="multiple"
          dropdownMatchSelectWidth={false}
          placeholder="Insertion order"
          onChange={onDatasetChange}
          optionFilterProp="children"
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{ width: "100%" }}
          value={getCurrentFilter() || undefined}
        >
          {insertionOrder.data.map((item, index) => (
            <Select.Option key={index} value={item.key}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <div className="gx-my-2">No data</div>
      )}
    </>
  );
};

export default FilterClients;
