import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import Table from "../../Table";
import Card from "../../../components/CardBox";
import ZoomableBubbleChart from "./../../ZoomableBubbleChart";
import PageTopSummary from "./../../TopCharts";
import Loading from "../../../components/Loading";
import { prepareTableData } from "./../../../util/Helpers";
import { getColumns } from "../../../util/Table";
import { setFilters } from "../../../appRedux/actions/Strategy";

const SeeKpi = () => {
  const loadingTopMetrics = useSelector(({ strategyData }) => strategyData.topMetrics.loading);
  const topMetrics = useSelector(({ strategyData }) => strategyData.topMetrics.data);
  const [summaryData, setSummaryData] = useState([]);
  const [tableColumns, setTableColumns] = useState();
  const loadingOrderTableMetrics = useSelector(({ strategyData }) => strategyData.orderTableMetrics.loading);
  const orderTableMetrics = useSelector(({ strategyData }) => strategyData.orderTableMetrics.data);

  const loadingInsertionOrderMetrics = useSelector(({ strategyData }) => strategyData.insertionOrderMetrics.loading);
  const insertionOrderMetrics = useSelector(({ strategyData }) => strategyData.insertionOrderMetrics.data);

  const loadingLineItemMetrics = useSelector(({ strategyData }) => strategyData.lineItemMetrics.loading);
  const lineItemMetrics = useSelector(({ strategyData }) => strategyData.lineItemMetrics.data);

  const currentFilters = useSelector(({ strategyData }) => strategyData.filters);

  useEffect(() => {
    if (typeof topMetrics !== "undefined" && topMetrics.length !== 0) {
      setSummaryData([
        {
          title: "TOTAL SPEND",
          value: topMetrics.total_spend,
          units: "currency",
        },
        {
          title: "IMPRESSIONS",
          value: topMetrics.impressions_sum,
        },
        {
          title: "VIEWABLE IMPRESSIONS",
          value: topMetrics.viewable_impressions_sum,
        },
        {
          title: "CLICKS",
          value: topMetrics.clicks_sum,
        },
        {
          title: "CPM",
          value: topMetrics.CPM,
          units: "currency",
        },
        {
          title: "vCPM",
          value: topMetrics.vCPM,
          units: "currency",
        },
      ]);
    }
  }, [loadingTopMetrics]);

  useEffect(() => {
    setTableColumns(getColumns(["InsertionOrder", "TotalSpend", "Impressions", "ViewableImpressions", "CPM", "vCPM"]));
  }, []);

  return (
    <div>
      {!loadingTopMetrics ? <PageTopSummary summaryData={summaryData} /> : <Loading card />}
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card">
            {!loadingOrderTableMetrics ? (
              <Table
                columns={tableColumns}
                data={prepareTableData(orderTableMetrics, "InsertionOrder", "LineItem")}
                options={{
                  filters: currentFilters,
                  chart: { key: ["InsertionOrder", "LineItem"], filter: ["insertionOrder", "lineItem"] },
                }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="INSERTION ORDER">
            {!loadingInsertionOrderMetrics ? (
              <ZoomableBubbleChart
                data={insertionOrderMetrics}
                axis={{
                  x: { label: "CPM", units: "currency" },
                  y: { label: "vCPM", units: "currency" },
                  title: "InsertionOrder",
                }}
                options={{ filters: currentFilters, chart: { key: "InsertionOrder", filter: "insertionOrder" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
          <Card className="gx-card" heading="LINE ITEM">
            {!loadingLineItemMetrics ? (
              <ZoomableBubbleChart
                data={lineItemMetrics}
                axis={{
                  x: { label: "CPM", units: "currency" },
                  y: { label: "vCPM", units: "currency" },
                  title: "LineItem",
                }}
                options={{ filters: currentFilters, chart: { key: "LineItem", filter: "lineItem" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SeeKpi;
