import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import Creative from "./Creative";
import Geography from "./Geography";
import Temporal from "./Temporal";
import Strategy from "./Strategy";
import Inventory from "./Inventory";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import User from "./User";
import Error404 from "./Error404";
import SettingsQA from "./SettingsQA";
import SettingsOverview from "./SettingsQA/Overview";
import NameGenerator from "./NameGenerator";
import { useDispatch, useSelector } from "react-redux";
import { getDatasetScore } from "../appRedux/actions/DataSetScore";
import { useEffect } from "react";

const App = ({ match }) => {
  const dispatch = useDispatch();
  const clientDataset = useSelector(({ filtersData }) => filtersData.clientDataset);
  const datasetVisible = useSelector(({ dataset }) => dataset.visible);

  useEffect(() => {
    if (clientDataset.data && clientDataset.current) dispatch(getDatasetScore(clientDataset.current.id));
  }, [clientDataset]);

  useEffect(() => {
    const substrings = ["overview", "creative", "geography", "strategy", "temporal", "inventory"];
    if (
      datasetVisible.scoreEnabled === false &&
      substrings.some((substring) => window.location.href.includes(substring))
    ) {
      window.location.href = "/settings-qa";
    }
  }, [datasetVisible]);

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}overview`} component={asyncComponent(() => import("./Overview"))} />
        <Route path={`${match.url}creative`} component={Creative} />
        <Route path={`${match.url}geography`} component={Geography} />
        <Route path={`${match.url}strategy`} component={Strategy} />
        <Route path={`${match.url}temporal`} component={Temporal} />
        <Route path={`${match.url}inventory`} component={Inventory} />
        <Route path={`${match.url}map/privacy-policy`} component={PrivacyPolicy} />
        <Route path={`${match.url}settings-qa`} component={SettingsQA} />
        <Route path={`${match.url}settings-overview`} component={SettingsOverview} />
        <Route path={`${match.url}map/terms-and-conditions`} component={Terms} />
        <Route path={`${match.url}user`} component={User} />
        <Route path={`${match.url}name-generator`} component={NameGenerator} />
        <Route component={Error404} />
      </Switch>
    </div>
  );
};

export default App;
