import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import Table from "../../Table";
import Map from "../Map";
import Card from "../../../components/CardBox";
import ZoomableBubbleChart from "./../../ZoomableBubbleChart";
import PageTopSummary from "./../../TopCharts";
import Loading from "../../../components/Loading";
import { prepareTableData } from "../../../util/Helpers";
import { getColumns } from "../../../util/Table";
import { setFilters } from "../../../appRedux/actions/Geography";

const SeeKpi = () => {
  const loadingTopMetrics = useSelector(({ geographyData }) => geographyData.topMetrics.loading);
  const topMetrics = useSelector(({ geographyData }) => geographyData.topMetrics.data);
  const [summaryData, setSummaryData] = useState([]);
  const [tableColumns, setTableColumns] = useState();

  const loadingCityTableMetrics = useSelector(({ geographyData }) => geographyData.cityTableMetrics.loading);
  const cityTableMetrics = useSelector(({ geographyData }) => geographyData.cityTableMetrics.data);

  const loadingCityMetrics = useSelector(({ geographyData }) => geographyData.cityMetrics.loading);
  const cityMetrics = useSelector(({ geographyData }) => geographyData.cityMetrics.data);

  const currentFilters = useSelector(({ geographyData }) => geographyData.filters);

  useEffect(() => {
    setTableColumns(getColumns(["City", "TotalSpend", "Impressions", "ViewableImpressions", "CPM", "vCPM"]));
  }, []);

  useEffect(() => {
    if (typeof topMetrics !== "undefined" && topMetrics.length !== 0) {
      setSummaryData([
        {
          title: "TOTAL SPEND",
          value: topMetrics.total_spend,
          units: "currency",
        },
        {
          title: "IMPRESSIONS",
          value: topMetrics.impressions_sum,
        },
        {
          title: "VIEWABLE IMPRESSIONS",
          value: topMetrics.viewable_impressions_sum,
        },
        {
          title: "CLICKS",
          value: topMetrics.clicks_sum,
        },
        {
          title: "CPM",
          value: topMetrics.CPM,
          units: "currency",
        },
        {
          title: "vCPM",
          value: topMetrics.vCPM,
          units: "currency",
        },
      ]);
    }
  }, [loadingTopMetrics]);

  return (
    <div>
      {!loadingTopMetrics ? <PageTopSummary summaryData={summaryData} /> : <Loading card />}

      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card">
            {!loadingCityTableMetrics ? (
              <Table
                columns={tableColumns}
                data={prepareTableData(cityTableMetrics)}
                options={{ filters: currentFilters, chart: { key: "City", filter: "city" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="CITY">
            {!loadingCityMetrics ? (
              <ZoomableBubbleChart
                data={cityMetrics}
                axis={{
                  x: { label: "CPM", units: "currency" },
                  y: { label: "vCPM", units: "currency" },
                  title: "City",
                }}
                options={{ filters: currentFilters, chart: { key: "City", filter: "city" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
          <Row gutter={10} type="flex" justify="space-between" align="bottom">
            <Col xl={12} xs={24} className="gx-col-full">
              <Card className="gx-card" heading="CPM">
                {!loadingCityMetrics ? <Map name="CPM" data={cityMetrics} /> : <Loading />}
              </Card>
            </Col>
            <Col xl={12} xs={24} className="gx-col-full">
              <Card className="gx-card" heading="vCPM">
                {!loadingCityMetrics ? <Map name="vCPM" data={cityMetrics} /> : <Loading />}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SeeKpi;
