import {
  CLIENT_DATASET_BEGIN,
  CLIENT_DATASET_SUCCESS,
  CLIENT_DATASET_FAILURE,
  SET_CLIENT_DATASET,
  CLIENT_ORGANIZATION_BEGIN,
  CLIENT_ORGANIZATION_SUCCESS,
  CLIENT_ORGANIZATION_FAILURE,
  SET_CLIENT_ORGANIZATION,
  SET_PERIOD,
  SET_INSERTION_ORDER,
  INSERTION_ORDER_BEGIN,
  INSERTION_ORDER_SUCCESS,
  INSERTION_ORDER_FAILURE,
  LINE_ITEM_BEGIN,
  LINE_ITEM_SUCCESS,
  LINE_ITEM_FAILURE,
  SET_LINE_ITEM,
  CAMPAIGN_ID_BEGIN,
  CAMPAIGN_ID_SUCCESS,
  CAMPAIGN_ID_FAILURE,
  SET_CAMPAIGN_ID,
  TIME_RANGE_BEGIN,
  TIME_RANGE_SUCCESS,
  TIME_RANGE_FAILURE,
  SET_TIME_RANGE,
  GET_PERIODS_BEGIN,
  GET_PERIODS_SUCCESS,
  GET_PERIODS_FAILURE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  clientDataset: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
  clientOrganization: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
  period: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
  insertionOrder: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
  lineItem: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
  campaignId: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
  timeRange: {
    loading: true,
    current: null,
    data: null,
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TIME_RANGE_BEGIN: {
      return {
        ...state,
        timeRange: {
          loading: true,
          error: null,
        },
      };
    }
    case TIME_RANGE_SUCCESS: {
      return {
        ...state,
        timeRange: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case TIME_RANGE_FAILURE: {
      return {
        ...state,
        timeRange: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_TIME_RANGE: {
      return {
        ...state,
        timeRange: {
          loading: false,
          error: null,
          data: state.timeRange.data,
          current: action.payload,
        },
      };
    }

    case LINE_ITEM_BEGIN: {
      return {
        ...state,
        lineItem: {
          loading: true,
          error: null,
        },
      };
    }
    case LINE_ITEM_SUCCESS: {
      return {
        ...state,
        lineItem: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case LINE_ITEM_FAILURE: {
      return {
        ...state,
        lineItem: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_LINE_ITEM: {
      return {
        ...state,
        lineItem: {
          loading: false,
          error: null,
          data: state.lineItem.data,
          current: action.payload,
        },
      };
    }

    case INSERTION_ORDER_BEGIN: {
      return {
        ...state,
        insertionOrder: {
          loading: true,
          error: null,
        },
      };
    }
    case INSERTION_ORDER_SUCCESS: {
      return {
        ...state,
        insertionOrder: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case INSERTION_ORDER_FAILURE: {
      return {
        ...state,
        insertionOrder: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_INSERTION_ORDER: {
      return {
        ...state,
        insertionOrder: {
          loading: false,
          error: null,
          data: state.insertionOrder.data,
          current: action.payload,
        },
      };
    }

    case CLIENT_DATASET_BEGIN: {
      return {
        ...state,
        clientDataset: {
          loading: true,
          error: null,
        },
      };
    }
    case CLIENT_DATASET_SUCCESS: {
      let datasets = action.payload[0].datasets;
      let dataset = action.payload[0].datasets[0];

      if (localStorage.dataSetCurrent) {
        const client = JSON.parse(localStorage.dataSetCurrent);
        datasets = action.payload.find((product) => product.datasets.some((item) => item.name === client));
        dataset = datasets.datasets.find((item) => item.name === client);

        if (!!datasets.datasets && !!datasets.datasets.length) {
          datasets = datasets.datasets;
        }
      }

      return {
        ...state,
        clientDataset: {
          loading: false,
          error: null,
          data: datasets,
          current: dataset,
        },
      };
    }

    case CLIENT_DATASET_FAILURE: {
      return {
        ...state,
        clientDataset: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_CLIENT_DATASET: {
      return {
        ...state,
        clientDataset: {
          loading: false,
          error: null,
          data: action.payload.data,
          current: action.payload.current,
        },
      };
    }

    case CLIENT_ORGANIZATION_BEGIN: {
      return {
        ...state,
        clientOrganization: {
          loading: true,
          error: null,
        },
      };
    }
    case CLIENT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        clientOrganization: {
          loading: false,
          error: null,
          data: action.payload,
          current: localStorage.organizationCurrent
            ? action.payload.find((client) => client.name === JSON.parse(localStorage.organizationCurrent))
            : action.payload[0],
        },
      };
    }

    case CLIENT_ORGANIZATION_FAILURE: {
      return {
        ...state,
        clientOrganization: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_CLIENT_ORGANIZATION: {
      return {
        ...state,
        clientOrganization: {
          loading: false,
          error: null,
          data: state.clientOrganization.data,
          current: action.payload,
        },
      };
    }

    case SET_PERIOD: {
      return {
        ...state,
        period: {
          loading: false,
          error: null,
          current: action.payload,
          data: state.period.data,
        },
      };
    }

    case GET_PERIODS_BEGIN: {
      return {
        ...state,
        period: {
          loading: true,
          error: null,
        },
      };
    }

    case GET_PERIODS_SUCCESS: {
      return {
        ...state,
        period: {
          loading: false,
          error: null,
          current: action.payload[0].key,
          data: action.payload,
        },
      };
    }

    case GET_PERIODS_FAILURE: {
      return {
        ...state,
        period: {
          loading: false,
          error: action.payload,
        },
      };
    }

    case CAMPAIGN_ID_BEGIN: {
      return {
        ...state,
        campaignId: {
          loading: true,
          error: null,
        },
      };
    }

    case CAMPAIGN_ID_SUCCESS: {
      return {
        ...state,
        campaignId: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case CAMPAIGN_ID_FAILURE: {
      return {
        ...state,
        campaignId: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    }

    case SET_CAMPAIGN_ID: {
      return {
        ...state,
        campaignId: {
          loading: false,
          error: null,
          data: state.campaignId.data,
          current: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
