import React from "react";
import { Link } from "react-router-dom";
import TermsContent from "./content";
import logo from "./../../assets/images/logo-dark.png";

const CopyrightPublic = () => {
  return (
    <div className="overflow-scroll">
      <div class="gx-mt-4" style={{ textAlign: "center", marginBottom: "20px" }}>
        <Link to="/overview">
          <img src={logo} alt="" className="main-logo" />
        </Link>
      </div>
      <div className="gx-mx-4">
        <TermsContent />
      </div>
    </div>
  );
};

export default CopyrightPublic;
