import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Row, Col } from "antd";
import PageTopSummary from "./../../TopCharts";
import Loading from "../../../components/Loading";
import Table from "./../../Table";
import Card from "../../../components/CardBox";
import ZoomableBubbleChart from "./../../ZoomableBubbleChart";
import { prepareTableData } from "../../../util/Helpers";
import { getColumns } from "../../../util/Table";
import { setFilters } from "../../../appRedux/actions/Inventory";

const DoKpi = () => {
  const [tableColumns, setTableColumns] = useState();
  const [summaryData, setSummaryData] = useState([]);

  const loadingAouTableMetrics = useSelector(({ inventoryData }) => inventoryData.aouTableMetrics.loading);
  const aouTableMetrics = useSelector(({ inventoryData }) => inventoryData.aouTableMetrics.data);

  const topMetrics = useSelector(({ inventoryData }) => inventoryData.topMetrics.data);
  const loadingTopMetrics = useSelector(({ inventoryData }) => inventoryData.topMetrics.loading);

  const loadingAouMetrics = useSelector(({ inventoryData }) => inventoryData.aouMetrics.loading);
  const aouMetrics = useSelector(({ inventoryData }) => inventoryData.aouMetrics.data);

  const loadingExchangeMetrics = useSelector(({ inventoryData }) => inventoryData.exchangeMetrics.loading);
  const exchangeMetrics = useSelector(({ inventoryData }) => inventoryData.exchangeMetrics.data);

  const loadingCreativeSizeMetrics = useSelector(({ inventoryData }) => inventoryData.creativeSizeMetrics.loading);
  const creativeSizeMetrics = useSelector(({ inventoryData }) => inventoryData.creativeSizeMetrics.data);

  const currentFilters = useSelector(({ inventoryData }) => inventoryData.filters);

  useEffect(() => {
    setTableColumns(getColumns(["AppURL", "TotalSpend", "Clicks", "TotalConversions", "CR", "CPA"]));
  }, []);

  useEffect(() => {
    if (typeof topMetrics !== "undefined" && topMetrics.length !== 0) {
      setSummaryData([
        {
          title: "TOTAL SPEND",
          value: topMetrics.total_spend,
          units: "currency",
        },
        {
          title: "IMPRESSIONS",
          value: topMetrics.impressions_sum,
        },
        {
          title: "VIEWABLE IMPRESSIONS",
          value: topMetrics.viewable_impressions_sum,
        },
        {
          title: "CONVERSIONS",
          value: topMetrics.total_conversions_sum,
        },
        { title: "CR", value: topMetrics.CR, units: "currency" },
        { title: "CPA", value: topMetrics.CPA, units: "currency" },
      ]);
    }
  }, [loadingTopMetrics]);

  return (
    <div>
      {!loadingTopMetrics ? <PageTopSummary summaryData={summaryData} /> : <Loading card />}
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card">
            {!loadingAouTableMetrics ? (
              <Table
                columns={tableColumns}
                data={prepareTableData(aouTableMetrics, "AppURL", "Exchange")}
                options={{
                  filters: currentFilters,
                  chart: { key: ["AppURL", "Exchange"], filter: ["appUrl", "exchange"] },
                }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="EXCHANGE">
            {!loadingExchangeMetrics ? (
              <ZoomableBubbleChart
                data={exchangeMetrics}
                axis={{
                  x: { label: "CPA", units: "currency" },
                  y: { label: "CR", units: "%" },
                  title: "Exchange",
                }}
                options={{ filters: currentFilters, chart: { key: "Exchange", filter: "exchange" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="TOP 50 URLS BY SPEND">
            {!loadingAouMetrics ? (
              <ZoomableBubbleChart
                data={aouMetrics}
                axis={{
                  x: { label: "CPA", units: "currency" },
                  y: { label: "CR", units: "%" },
                  title: "AppURL",
                }}
                options={{ filters: currentFilters, chart: { key: "AppURL", filter: "appUrl" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="CREATIVE SIZE">
            {!loadingCreativeSizeMetrics ? (
              <ZoomableBubbleChart
                data={creativeSizeMetrics}
                axis={{
                  x: { label: "CPA", units: "currency" },
                  y: { label: "CR", units: "%" },
                  title: "CreativeSize",
                }}
                options={{ filters: currentFilters, chart: { key: "CreativeSize", filter: "creativeSize" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DoKpi;
