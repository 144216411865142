import {
  CAMPAIGN_SET_FILTERS,
  CAMPAIGN_SET_LOADED,
  FETCH_CAMPAIGN_CATEGORIES_BEGIN,
  FETCH_CAMPAIGN_CATEGORIES_FAILURE,
  FETCH_CAMPAIGN_CATEGORIES_SUCCESS,
  FETCH_CAMPAIGN_CATEGORIES_CANCEL,
  FETCH_CAMPAIGN_SETTINGS_SUM_BEGIN,
  FETCH_CAMPAIGN_SETTINGS_SUM_FAILURE,
  FETCH_CAMPAIGN_SETTINGS_SUM_SUCCESS,
  FETCH_CAMPAIGN_SETTINGS_SUM_CANCEL,
  FETCH_CAMPAIGN_STATS_BEGIN,
  FETCH_CAMPAIGN_STATS_FAILURE,
  FETCH_CAMPAIGN_STATS_SUCCESS,
  FETCH_CAMPAIGN_STATS_CANCEL,
  FETCH_CAMPAIGN_LAST_MODIFIED_BEGIN,
  FETCH_CAMPAIGN_LAST_MODIFIED_FAILURE,
  FETCH_CAMPAIGN_LAST_MODIFIED_SUCCESS,
  CAMPAIGN_SET_DOWNLOADING,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { applyGlobalFilters } from "../../util/Filters";
import { store } from "./../../NextApp";
import { handleFileDownload, handleFileDownload2 } from "../../util/Helpers";

export const setLoaded = () => {
  return {
    type: CAMPAIGN_SET_LOADED,
  };
};

export const setFilters = (data) => {
  return {
    type: CAMPAIGN_SET_FILTERS,
    payload: data,
  };
};

export const campaignStatsData = (signal) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN_STATS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/sdf/counts", "campaignData", {
          lineItem: true,
          campaignId: true,
          campaignInsertionOrder: true,
        }),
        { signal: signal }
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_CAMPAIGN_STATS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        if (error.message === "canceled") {
          dispatch({
            type: FETCH_CAMPAIGN_STATS_CANCEL,
          });
        } else {
          dispatch({
            type: FETCH_CAMPAIGN_STATS_FAILURE,
            payload: error.message,
          });
        }
      });
  };
};

export const campaignSettingsSumData = (signal) => {
  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN_SETTINGS_SUM_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/sdf/settings", "campaignData", {
          lineItem: true,
          campaignId: true,
          campaignInsertionOrder: true,
        }),
        { signal: signal }
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_CAMPAIGN_SETTINGS_SUM_SUCCESS, payload: data });
      })
      .catch(function (error) {
        if (error.message === "canceled") {
          dispatch({
            type: FETCH_CAMPAIGN_SETTINGS_SUM_CANCEL,
          });
        } else {
          dispatch({
            type: FETCH_CAMPAIGN_SETTINGS_SUM_FAILURE,
            payload: error.message,
          });
        }
      });
  };
};

export const campaignCategoriesData = (signal) => {
  const currentDataset = store.getState().filtersData.clientDataset.current;

  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN_CATEGORIES_BEGIN });
    let counter = 0;
    let payload = [];
    if (currentDataset.queriesIds.length > 0) {
      currentDataset.queriesIds.forEach((id) => {
        const filterString = applyGlobalFilters("api/sdf/category", "campaignData", {
          lineItem: true,
          campaignId: true,
          campaignInsertionOrder: true,
        });
        let queryId = "?queryId=" + id;
        if (filterString.includes("?")) queryId = "&queryId=" + id;

        axios
          .get(filterString + queryId, { signal: signal })
          .then(({ data }) => {
            counter++;
            payload = [...payload, ...data];
            if (counter === currentDataset.queriesIds.length)
              dispatch({ type: FETCH_CAMPAIGN_CATEGORIES_SUCCESS, payload: payload });
          })
          .catch(function (error) {
            if (error.message === "canceled") {
              dispatch({
                type: FETCH_CAMPAIGN_CATEGORIES_CANCEL,
              });
            } else {
              dispatch({
                type: FETCH_CAMPAIGN_CATEGORIES_FAILURE,
                payload: error.message,
              });
            }
          });
      });
    } else {
      axios
        .get(
          applyGlobalFilters("api/sdf/category", "campaignData", {
            lineItem: true,
            campaignId: true,
            campaignInsertionOrder: true,
          }),
          { signal: signal }
        )
        .then(({ data }) => {
          dispatch({ type: FETCH_CAMPAIGN_CATEGORIES_SUCCESS, payload: data });
        })
        .catch(function (error) {
          if (error.message === "canceled") {
            dispatch({
              type: FETCH_CAMPAIGN_CATEGORIES_CANCEL,
            });
          } else {
            dispatch({
              type: FETCH_CAMPAIGN_CATEGORIES_FAILURE,
              payload: error.message,
            });
          }
        });
    }
  };
};

export const campaignLastModified = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN_LAST_MODIFIED_BEGIN });

    axios
      .get(applyGlobalFilters("api/sdf/last-modified", "campaignData"))
      .then(({ data }) => {
        dispatch({ type: FETCH_CAMPAIGN_LAST_MODIFIED_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_CAMPAIGN_LAST_MODIFIED_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const campaignCSVFile = () => {
  return (dispatch) => {
    dispatch({ type: CAMPAIGN_SET_DOWNLOADING, payload: true });
    axios
      .get(
        applyGlobalFilters("api/sdf/export-csv", false, {
          lineItem: true,
          campaignId: true,
          campaignInsertionOrder: true,
        })
      )
      .then(({ data }) => {
        handleFileDownload(data);

        dispatch({ type: CAMPAIGN_SET_DOWNLOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: CAMPAIGN_SET_DOWNLOADING, payload: false });
      });
  };
};
