import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import Loading from "../Loading";
import { getTimeRange, setTimeRange } from "../../appRedux/actions/Filters";
import moment from "moment";

const { RangePicker } = DatePicker;

const FilterTimeRange = () => {
  const dispatch = useDispatch();

  const timeRange = useSelector(({ filtersData }) => filtersData.timeRange);

  const dateFormat = "YYYY/MM/DD";

  const onDatasetChange = (value) => {
    dispatch(setTimeRange(value));
  };

  const getCurrentFilter = () => {
    return timeRange.current ? timeRange.current : undefined;
  };

  useEffect(() => {
    if (!timeRange.data) {
      dispatch(getTimeRange());
    }
  }, []);

  return (
    <>
      {timeRange.loading ? (
        <Loading className="gx-my-2" />
      ) : timeRange.data ? (
        <RangePicker
          style={{ width: "100%" }}
          defaultValue={getCurrentFilter()}
          defaultPickerValue={[moment(timeRange.data.minDate), moment(timeRange.data.maxDate)]}
          onChange={onDatasetChange}
          format={dateFormat}
          disabledDate={(d) => !d || d.isAfter(timeRange.data.maxDate) || d.isSameOrBefore(timeRange.data.minDate)}
        />
      ) : (
        <div className="gx-my-2">No data</div>
      )}
    </>
  );
};

export default FilterTimeRange;
