import React from "react";
import { Row, Col } from "antd";
import Metrics from "./../components/Metrics";
import { valueWithUnits } from "./../util/Helpers";

const PageTopSummary = ({ width, navigateTo, summaryData }) => {
  return (
    <>
      <Row gutter={[12, 12]}>
        {summaryData.map((item, index) => {
          return (
            <Col key={index} xl={4} md={8} sm={12} xs={24}>
              <Metrics title={item.title} styleName="ant-card-small gx-dot-arrow-hover">
                <Row className="ant-row-flex-middle">
                  <Col xl={13} lg={12} md={24} sm={12} xs={12} className="gx-pr-0">
                    <span className="gx-mb-1 gx-fs-xl gx-font-weight-medium">
                      {valueWithUnits(item.value, item.units)}
                    </span>
                  </Col>
                  <Col
                    xl={11}
                    lg={12}
                    md={24}
                    sm={12}
                    xs={12}
                    className={item.url ? "gx-pr-5 gx-pl-0" : "gx-pl-0"}
                  ></Col>
                </Row>
                {item.url ? (
                  <div className="gx-dot-arrow" onClick={() => navigateTo(item.url)}>
                    <div className="gx-bg-primary gx-hover-arrow">
                      <i className="icon icon-long-arrow-right gx-text-white" />
                    </div>
                    <div className="gx-dot">
                      <i className="icon icon-ellipse-v gx-text-primary" />
                    </div>
                  </div>
                ) : null}
              </Metrics>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default PageTopSummary;
