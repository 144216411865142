import {
  FETCH_OVERVIEW_STATS_BEGIN,
  FETCH_OVERVIEW_STATS_SUCCESS,
  FETCH_OVERVIEW_STATS_FAILURE,
  FETCH_OVERVIEW_DIMENSION_BEGIN,
  FETCH_OVERVIEW_DIMENSION_SUCCESS,
  FETCH_OVERVIEW_DIMENSION_FAILURE,
  FETCH_OVERVIEW_MAP_SCORE_BEGIN,
  FETCH_OVERVIEW_MAP_SCORE_SUCCESS,
  FETCH_OVERVIEW_MAP_SCORE_FAILURE,
  FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_BEGIN,
  FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_SUCCESS,
  FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_FAILURE,
  FETCH_OVERVIEW_TOP_CHART_BEGIN,
  FETCH_OVERVIEW_TOP_CHART_SUCCESS,
  FETCH_OVERVIEW_TOP_CHART_FAILURE,
  FETCH_OVERVIEW_WOW_SCORES_BEGIN,
  FETCH_OVERVIEW_WOW_SCORES_SUCCESS,
  FETCH_OVERVIEW_WOW_SCORES_FAILURE,
  OVERVIEW_SET_FILTERS,
  OVERVIEW_SET_LOADED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loaded: false,
  filters: {
    clientDataset: null,
    period: "Week",
  },
  welcomeStats: {
    loading: true,
    data: [],
    error: null,
  },
  topChartStats: {
    loading: true,
    data: [],
    error: null,
  },
  wowScoresStats: {
    loading: true,
    data: [],
    error: null,
  },
  dimensionStats: {
    loading: false,
    error: null,
    data: [],
  },
  mapStats: {
    loading: false,
    error: null,
    data: [],
  },
  dimensionsMapStats: {
    loading: false,
    error: null,
    data: [],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OVERVIEW_SET_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }
    case OVERVIEW_SET_FILTERS: {
      return {
        ...state,
        filters: Object.assign(state.filters, action.payload),
      };
    }
    case FETCH_OVERVIEW_STATS_BEGIN: {
      return {
        ...state,
        welcomeStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_OVERVIEW_STATS_SUCCESS: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_STATS_FAILURE: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_OVERVIEW_TOP_CHART_BEGIN: {
      return {
        ...state,
        topChartStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_OVERVIEW_TOP_CHART_SUCCESS: {
      return {
        ...state,
        topChartStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_TOP_CHART_FAILURE: {
      return {
        ...state,
        topChartStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_OVERVIEW_WOW_SCORES_BEGIN: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: {},
        },
      };
    }
    case FETCH_OVERVIEW_WOW_SCORES_SUCCESS: {
      return {
        ...state,
        wowScoresStats: {
          loading: false,
          error: null,
          data: Object.assign(state.wowScoresStats.data, action.payload),
        },
      };
    }

    case FETCH_OVERVIEW_WOW_SCORES_FAILURE: {
      return {
        ...state,
        wowScoresStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_OVERVIEW_DIMENSION_BEGIN: {
      return {
        ...state,
        dimensionStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_OVERVIEW_DIMENSION_SUCCESS: {
      return {
        ...state,
        dimensionStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_DIMENSION_FAILURE: {
      return {
        ...state,
        dimensionStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_OVERVIEW_MAP_SCORE_BEGIN: {
      return {
        ...state,
        mapStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_OVERVIEW_MAP_SCORE_SUCCESS: {
      return {
        ...state,
        mapStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_MAP_SCORE_FAILURE: {
      return {
        ...state,
        mapStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_BEGIN: {
      return {
        ...state,
        dimensionsMapStats: {
          loading: true,
          error: null,
        },
      };
    }

    case FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_SUCCESS: {
      return {
        ...state,
        dimensionsMapStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_FAILURE: {
      return {
        ...state,
        dimensionsMapStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};
