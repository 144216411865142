import {
  FETCH_INVENTORY_STATS_BEGIN,
  FETCH_INVENTORY_STATS_SUCCESS,
  FETCH_INVENTORY_STATS_FAILURE,
  FETCH_INVENTORY_WOW_SCORES_BEGIN,
  FETCH_INVENTORY_WOW_SCORES_SUCCESS,
  FETCH_INVENTORY_WOW_SCORES_FAILURE,
  FETCH_INVENTORY_WOW_SCORES_END,
  FETCH_INVENTORY_AOU_TABLE_METRICS_BEGIN,
  FETCH_INVENTORY_AOU_TABLE_METRICS_SUCCESS,
  FETCH_INVENTORY_AOU_TABLE_METRICS_FAILURE,
  FETCH_INVENTORY_TOP_METRICS_BEGIN,
  FETCH_INVENTORY_TOP_METRICS_SUCCESS,
  FETCH_INVENTORY_TOP_METRICS_FAILURE,
  FETCH_INVENTORY_AOU_METRICS_BEGIN,
  FETCH_INVENTORY_AOU_METRICS_SUCCESS,
  FETCH_INVENTORY_AOU_METRICS_FAILURE,
  FETCH_INVENTORY_CREATIVE_SIZE_METRICS_BEGIN,
  FETCH_INVENTORY_CREATIVE_SIZE_METRICS_SUCCESS,
  FETCH_INVENTORY_CREATIVE_SIZE_METRICS_FAILURE,
  FETCH_INVENTORY_EXCHANGE_METRICS_BEGIN,
  FETCH_INVENTORY_EXCHANGE_METRICS_SUCCESS,
  FETCH_INVENTORY_EXCHANGE_METRICS_FAILURE,
  INVENTORY_SET_FILTERS,
  INVENTORY_SET_LOADED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loaded: false,
  filters: {
    clientDataset: null,
    period: "Week",
    charts: [],
  },
  welcomeStats: {
    loading: false,
    data: [],
    error: null,
  },
  wowScoresStats: {
    loading: true,
    data: [],
    error: null,
  },
  aouTableMetrics: {
    loading: true,
    data: [],
    error: null,
  },
  topMetrics: {
    loading: false,
    data: [],
    error: null,
  },
  aouMetrics: {
    loading: true,
    data: [],
    error: null,
  },
  creativeSizeMetrics: {
    loading: true,
    data: [],
    error: null,
  },
  exchangeMetrics: {
    loading: true,
    data: [],
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INVENTORY_SET_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }
    case INVENTORY_SET_FILTERS: {
      return {
        ...state,
        filters: Object.assign(state.filters, action.payload),
      };
    }
    case FETCH_INVENTORY_EXCHANGE_METRICS_BEGIN: {
      return {
        ...state,
        exchangeMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_INVENTORY_EXCHANGE_METRICS_SUCCESS: {
      return {
        ...state,
        exchangeMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_EXCHANGE_METRICS_FAILURE: {
      return {
        ...state,
        exchangeMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_INVENTORY_CREATIVE_SIZE_METRICS_BEGIN: {
      return {
        ...state,
        creativeSizeMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_INVENTORY_CREATIVE_SIZE_METRICS_SUCCESS: {
      return {
        ...state,
        creativeSizeMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_CREATIVE_SIZE_METRICS_FAILURE: {
      return {
        ...state,
        creativeSizeMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case FETCH_INVENTORY_AOU_METRICS_BEGIN: {
      return {
        ...state,
        aouMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_INVENTORY_AOU_METRICS_SUCCESS: {
      return {
        ...state,
        aouMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_AOU_METRICS_FAILURE: {
      return {
        ...state,
        aouMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case FETCH_INVENTORY_STATS_BEGIN: {
      return {
        ...state,
        welcomeStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_INVENTORY_STATS_SUCCESS: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_STATS_FAILURE: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_INVENTORY_TOP_METRICS_BEGIN: {
      return {
        ...state,
        topMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_INVENTORY_TOP_METRICS_SUCCESS: {
      return {
        ...state,
        topMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_TOP_METRICS_FAILURE: {
      return {
        ...state,
        topMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_INVENTORY_WOW_SCORES_BEGIN: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: {},
        },
      };
    }
    case FETCH_INVENTORY_WOW_SCORES_SUCCESS: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: Object.assign(state.wowScoresStats.data, action.payload),
        },
      };
    }

    case FETCH_INVENTORY_WOW_SCORES_FAILURE: {
      return {
        ...state,
        wowScoresStats: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }

    case FETCH_INVENTORY_WOW_SCORES_END: {
      return {
        ...state,
        wowScoresStats: {
          ...state.wowScoresStats,
          loading: false,
          error: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_AOU_TABLE_METRICS_BEGIN: {
      return {
        ...state,
        aouTableMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_INVENTORY_AOU_TABLE_METRICS_SUCCESS: {
      return {
        ...state,
        aouTableMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_INVENTORY_AOU_TABLE_METRICS_FAILURE: {
      return {
        ...state,
        aouTableMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    default:
      return state;
  }
};
