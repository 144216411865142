import React from "react";
import { useDispatch } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";

const RemoveFilter = ({ setFilters, filtersData, removedFilter }) => {
  const dispatch = useDispatch();

  const handleRemoveFilter = () => {
    const newFilters = filtersData.filter((filter) => !(removedFilter in filter));
    dispatch(setFilters({ charts: newFilters }));
  };

  return (
    <a className="remove_filter" title="Remove filter" onClick={handleRemoveFilter}>
      <CloseCircleOutlined />
      <span className="info">remove filter</span>
    </a>
  );
};

export default RemoveFilter;
