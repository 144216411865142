import React from "react";
import AmCharts from "@amcharts/amcharts3-react";
import moment from "moment";
import { valueWithUnits } from "./../../util/Helpers";

const XyChartWithDateBasedAxis = ({ data, attr }) => {
  const config = {
    type: "xy",
    theme: "light",
    marginRight: 0,
    dataDateFormat: "YYYY-MM-DD HH",
    startDuration: 1.5,
    trendLines: [],
    balloon: {
      adjustBorderColor: false,
      shadowAlpha: 0,
      fixedPosition: true,
    },
    graphs: [
      {
        balloonText: `<div style='margin:5px;'><b>${attr.value} ${valueWithUnits("[[value]]", attr.units)}</b>`,
        bullet: "round",
        id: "AmGraph-2",
        lineAlpha: 0,
        lineColor: "#8f98a6",
        fillAlphas: 0,
        valueField: attr.value,
        xField: attr.x,
        yField: attr.y,
        animationPlayed: true,
      },
    ],
    valueAxes: [
      {
        id: "ValueAxis-1",
        axisAlpha: 0,
        position: "bottom",
        title: "Hour of Day",
        maximum: 23,
        minimum: 0,
        autoGridCount: false,
        gridCount: 23,
      },
      {
        id: "ValueAxis-2",
        axisAlpha: 0,
        title: "Day of Week",
        maximum: 7,
        minimum: 1,
        labelFunction: (valueText, date, valueAxis) => {
          return moment().day(valueText).format("ddd");
        },
      },
    ],
    allLabels: [],
    titles: [],
    dataProvider: data,

    chartScrollbar: {
      offset: 15,
      scrollbarHeight: 5,
    },

    chartCursor: {
      pan: true,
      cursorAlpha: 0,
      valueLineAlpha: 0,
    },
  };
  return (
    <div className="App">
      <AmCharts.React style={{ width: "100%", height: "350px" }} options={config} />
    </div>
  );
};

export default XyChartWithDateBasedAxis;
