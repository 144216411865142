import {
  FETCH_OAUTH_LINK_BEGIN,
  FETCH_OAUTH_LINK_FAILURE,
  FETCH_OAUTH_LINK_SUCCESS,
} from "../../constants/ActionTypes";

import axios from "util/Api";

export const oauthLink = (organizationId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_OAUTH_LINK_BEGIN });

    axios
      .get("api/oauth/link/" + organizationId)
      .then(({ data }) => {
        dispatch({ type: FETCH_OAUTH_LINK_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OAUTH_LINK_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const saveToken = (code, state) => {
  return () => {
    axios.get("api/oauth/token?code=" + encodeURI(code) + "&state=" + state).catch(function (error) {});
  };
};
