import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Alert } from "antd";
import IntlMessages from "util/IntlMessages";
import { userChangePassword } from "../../appRedux/actions/Auth";

const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    setSubmitting(true);

    dispatch(userChangePassword(values)).then((data) => {
      setSubmitting(false);
      setStatus(data);
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-login-content">
          {status ? <Alert message={status.response} type={status.type} showIcon className="gx-mb-3" /> : ""}

          <div className="gx-mb-4">
            <h2>Change Password</h2>
          </div>

          <Form autocomplete="off" form={form} onFinish={handleSubmit} className="gx-login-form gx-form-row0">
            <Form.Item name="oldPassword" rules={[{ required: true }]}>
              <Input type="oldPassword" placeholder="Old Password" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
                { validator: validateToNextPassword },
              ]}
            >
              <Input type="password" placeholder="New Password" autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              name="confirm"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                { validator: compareToFirstPassword },
              ]}
            >
              <Input placeholder="Retype New Password" type="password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitting}>
                <IntlMessages id="app.userAuth.reset" />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

// const WrappedResetPasswordForm = Form.create()(ChangePassword);

// export default WrappedResetPasswordForm;
export default ChangePassword;
