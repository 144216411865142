import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, Col, Collapse, Progress, Row, Tooltip } from "antd";
import { LinkOutlined, MailOutlined, ProfileOutlined, RiseOutlined } from "@ant-design/icons";
import Pluralize from "react-pluralize";
import Loading from "../../components/Loading";
import FilterCampaignInsertionOrderTable from "../../components/custom/FilterCampaignInsertionOrderTable";

import {
  campaignCategoriesData,
  campaignLastModified,
  campaignSettingsSumData,
  campaignStatsData,
  setFilters,
  setLoaded,
} from "../../appRedux/actions/Campaign";

import CampaignNavigationTop from "../CampaignNavigation";

const Panel = Collapse.Panel;

const SettingsQA = () => {
  const dispatch = useDispatch();

  const [welcomeData, setWelcomeData] = useState([]);

  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const loadingWelcome = useSelector(({ campaignData }) => campaignData.welcomeStats.loading);
  const welcomeStats = useSelector(({ campaignData }) => campaignData.welcomeStats.data);
  const settingsSum = useSelector(({ campaignData }) => campaignData.settingsSum);
  const categories = useSelector(({ campaignData }) => campaignData.categories);
  const loaded = useSelector(({ campaignData }) => campaignData.loaded);
  const currentFilters = useSelector(({ campaignData }) => campaignData.filters);
  const filterCampaignInsertionOrder = useSelector(
    ({ campaignFiltersData }) => campaignFiltersData.campaignInsertionOrder.current
  );
  const filterLineItem = useSelector(({ filtersData }) => filtersData.lineItem.current);
  const filterCampaignId = useSelector(({ filtersData }) => filtersData.campaignId.current);

  const getCountBadge = (good, neutral, bad) => {
    return (
      <>
        {good ? <Badge count={good} style={{ backgroundColor: "#93DD3F" }} /> : ""}
        {neutral ? <Badge count={neutral} style={{ backgroundColor: "#5BC1BE" }} /> : ""}
        {bad ? <Badge count={bad} style={{ backgroundColor: "#DA3B26" }} /> : ""}
      </>
    );
  };

  const getDVLink = (url) => (
    <a href={url} target={"_blank"}>
      <LinkOutlined
        type="link"
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    </a>
  );

  useEffect(() => {
    if (typeof welcomeStats !== "undefined" && welcomeStats.length !== 0) {
      setWelcomeData({
        title: "Welcome!",
        subTitle: "You Have",
        items: [
          {
            icon: <MailOutlined />,
            title: { singular: "Campaign" },
            count: loadingWelcome ? "-" : welcomeStats.campaigns,
          },
          {
            icon: <ProfileOutlined />,
            title: { singular: "Insertion Order" },
            count: loadingWelcome ? "-" : welcomeStats.insertion_orders,
          },
          {
            icon: <RiseOutlined />,
            title: { singular: "Line Item" },
            count: loadingWelcome ? "-" : welcomeStats.line_items,
          },
        ],
      });
    }
  }, [welcomeStats]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (!loaded || (loaded && filterClientDataset && filterClientDataset !== currentFilters.clientDataset)) {
      getData(signal);
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (!currentFilters.clientDataset && filterClientDataset) {
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
    if (
      loaded &&
      ((filterCampaignInsertionOrder && currentFilters.campaignInsertionOrder !== filterCampaignInsertionOrder) ||
        (filterClientDataset && filterClientDataset !== currentFilters.clientDataset && currentFilters.clientDataset))
    ) {
      getData(signal);

      dispatch(
        setFilters({ campaignInsertionOrder: filterCampaignInsertionOrder, clientDataset: filterClientDataset })
      );
    }

    if (loaded && filterCampaignId && currentFilters.campaignId !== filterCampaignId) {
      dispatch(setFilters({ campaignId: filterCampaignId }));
      getData(signal);
    }

    if (loaded && filterLineItem && currentFilters.lineItem !== filterLineItem) {
      dispatch(setFilters({ lineItem: filterLineItem }));
      getData(signal);
    }

    return () => {
      controller.abort();
    };
  }, [filterCampaignInsertionOrder, filterClientDataset, filterLineItem, filterCampaignId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (filterClientDataset) {
      dispatch(campaignCategoriesData(signal));
    }

    return () => {
      controller.abort();
    };
  }, [filterClientDataset, filterCampaignInsertionOrder, filterLineItem, filterCampaignId]);

  const getData = (signal) => {
    dispatch(campaignStatsData(signal));
    dispatch(campaignSettingsSumData(signal));
    dispatch(campaignLastModified());
    dispatch(setLoaded());
  };

  return (
    <div>
      <CampaignNavigationTop sdf={true} />
      <Row className="gx-mt-4">
        <Col xl={24} lg={24}>
          <div className="gx-card">
            <div className="gx-card-body">
              <Row>
                <Col xl={6} lg={24} md={24} sm={24} xs={24}>
                  {!loadingWelcome ? (
                    <div className="gx-wel-ema gx-pt-xl-2">
                      <h1 className="gx-mb-3">Settings QA</h1>
                      <p className="gx-fs-sm gx-text-uppercase"></p>
                      <ul className="gx-list-group">
                        {welcomeData.items
                          ? welcomeData.items.map((item, index) => {
                              return (
                                <li key={index}>
                                  {item.icon}
                                  <span>
                                    <Pluralize
                                      singular={item.title.singular}
                                      plural={item.title.plural ? item.title.plural : false}
                                      count={item.count}
                                    />
                                  </span>
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  ) : (
                    <Loading />
                  )}
                </Col>

                <Col xl={18} lg={24} md={24} sm={24} xs={24}>
                  <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                    <Row type="flex" align="middle">
                      {loaded && !settingsSum.loading ? (
                        <>
                          <Col xs={12} md={6}>
                            <div>
                              <h6 className="ant-card-head-title text-center">Settings checked</h6>
                            </div>
                            <Progress
                              width={80}
                              className="ant-progress-fix"
                              type="circle"
                              strokeColor="#000000"
                              status={"normal"}
                              percent={
                                ((settingsSum.data.bad + settingsSum.data.good + settingsSum.data.na) * 100) /
                                settingsSum.data.total
                              }
                              format={() => {
                                return settingsSum.data.bad + settingsSum.data.good + settingsSum.data.na;
                              }}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <div>
                              <h6 className="ant-card-head-title text-center">Best Practice Settings</h6>
                            </div>
                            <Progress
                              width={80}
                              className="ant-progress-fix"
                              type="circle"
                              strokeColor="#93DD3F"
                              status={"normal"}
                              percent={(settingsSum.data.good * 100) / (settingsSum.data.total - settingsSum.data.na)}
                              format={() => {
                                return settingsSum.data.good;
                              }}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <div>
                              <h6 className="ant-card-head-title text-center">Settings to Check</h6>
                            </div>
                            <Progress
                              width={80}
                              className="ant-progress-fix"
                              type="circle"
                              strokeColor="#DA3B26"
                              status={"normal"}
                              percent={(settingsSum.data.bad * 100) / (settingsSum.data.total - settingsSum.data.na)}
                              format={() => {
                                return settingsSum.data.bad;
                              }}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <div>
                              <h6 className="ant-card-head-title text-center">NA Settings</h6>
                            </div>
                            <Progress
                              width={80}
                              className="ant-progress-fix"
                              type="circle"
                              strokeColor="#5BC1BE"
                              status={"normal"}
                              percent={(settingsSum.data.na * 100) / settingsSum.data.total}
                              format={() => {
                                return settingsSum.data.na;
                              }}
                            />
                          </Col>{" "}
                        </>
                      ) : (
                        <Loading />
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24}>
          <Card title="Insertion order" className="gx-card">
            <FilterCampaignInsertionOrderTable />
          </Card>
        </Col>
      </Row>
      <Row>
        {loaded && !categories.loading
          ? categories.data.map((category, index) => {
              return (
                <Col key={index} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Card title={category.name} className="gx-card">
                    <Row type="flex" align="middle">
                      <Col span={6}>
                        <div className="ant-card-head-title text-center">
                          Settings
                          <br />
                          Checked
                        </div>
                        <Progress
                          width={80}
                          className="ant-progress-fix"
                          type="circle"
                          strokeColor="#000000"
                          status={"normal"}
                          percent={
                            ((category.statistics.good + category.statistics.bad) * 100) /
                            (category.statistics.total - category.statistics.na)
                          }
                          format={() => {
                            return category.statistics.good + category.statistics.bad;
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <div className="ant-card-head-title text-center">
                          Best Practice
                          <br />
                          Settings
                        </div>
                        <Progress
                          width={80}
                          className="ant-progress-fix"
                          type="circle"
                          strokeColor="#93DD3F"
                          status={"normal"}
                          percent={
                            (category.statistics.good * 100) / (category.statistics.total - category.statistics.na)
                          }
                          format={() => {
                            return category.statistics.good;
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <div className="ant-card-head-title text-center">
                          Settings
                          <br />
                          to Check
                        </div>
                        <Progress
                          width={80}
                          className="ant-progress-fix"
                          type="circle"
                          strokeColor="#DA3B26"
                          percent={
                            (category.statistics.bad * 100) / (category.statistics.total - category.statistics.na)
                          }
                          status={"normal"}
                          format={() => {
                            return category.statistics.bad;
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <div className="ant-card-head-title text-center">
                          NA
                          <br />
                          Settings
                        </div>
                        <Progress
                          width={80}
                          className="ant-progress-fix"
                          type="circle"
                          strokeColor="#5BC1BE"
                          percent={(category.statistics.na * 100) / category.statistics.total}
                          status={"normal"}
                          format={() => {
                            return category.statistics.na;
                          }}
                        />
                      </Col>
                    </Row>
                    {category.flags.length ? (
                      <Collapse accordion className="gx-mt-4">
                        {category.flags.map((flag, index) => {
                          return (
                            <Panel key={index} header={flag.text} extra={getCountBadge(flag.good, flag.na, flag.bad)}>
                              <Tooltip title={`${flag.good} good / ${flag.bad} bad / ${flag.na} NA`}>
                                <Progress
                                  percent={((flag.good + flag.bad) * 100) / flag.total}
                                  success={{ percent: (flag.good * 100) / flag.total }}
                                  showInfo={false}
                                  className="custom-progress"
                                />
                              </Tooltip>
                              {flag.flagData.length > 0
                                ? flag.flagData.map((flag, key) => {
                                    return (
                                      <p key={key} className="flag-info">
                                        Check {flag.Level}: {flag.Name} ({flag.ID}) {getDVLink(flag.URL)}
                                      </p>
                                    );
                                  })
                                : null}
                            </Panel>
                          );
                        })}
                      </Collapse>
                    ) : (
                      "No Flags present"
                    )}
                  </Card>
                </Col>
              );
            })
          : "Loading..."}
      </Row>
    </div>
  );
};

export default SettingsQA;
