import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import NavigationTop from "./../Navigation";
import SeeKpi from "./tabs/SeeKpi";
import ThinkKpi from "./tabs/ThinkKpi";
import DoKpi from "./tabs/DoKpi";
import { push } from "connected-react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Error404 from "../Error404";
import PageTop from "../Top";
import Loading from "../../components/Loading";
import { getLastUrlPart } from "./../../util/Helpers";
import { LaptopOutlined, ContainerOutlined, DatabaseOutlined } from "@ant-design/icons";

import {
  creativeWelcomeData,
  creativeTopMetricsData,
  creativeDeviceTableMetricsData,
  creativeWowScoresData,
  creativeDeviceTypeMetricsData,
  creativeCreativeSizeMetricsData,
  creativeCreativeTypeMetricsData,
  setFilters,
  setLoaded,
} from "../../appRedux/actions/Creative";

const navigationItems = [
  { label: "See KPIs", path: "/creative/see" },
  { label: "Think KPIs", path: "/creative/think" },
  { label: "Do KPIs", path: "/creative/do" },
];

const Creative = ({ navigateTo }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);

  const [lastUrlPart, setLasUrlPart] = useState();
  const [current, setCurrent] = useState(location.pathname);
  const [welcomeData, setWelcomeData] = useState();

  const currentFilters = useSelector(({ creativeData }) => creativeData.filters);
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const filterPeriod = useSelector(({ filtersData }) => filtersData.period.current);
  const filterLineItem = useSelector(({ filtersData }) => filtersData.lineItem.current);
  const filterInsertionOrder = useSelector(({ filtersData }) => filtersData.insertionOrder.current);
  const filterCampaignId = useSelector(({ filtersData }) => filtersData.campaignId.current);
  const filterTimeRange = useSelector(({ filtersData }) => filtersData.timeRange.current);
  const loadingWowScores = useSelector(({ creativeData }) => creativeData.wowScoresStats.loading);
  const wowScores = useSelector(({ creativeData }) => creativeData.wowScoresStats.data);
  const loadingWelcome = useSelector(({ creativeData }) => creativeData.welcomeStats.loading);
  const welcomeStats = useSelector(({ creativeData }) => creativeData.welcomeStats.data);
  const loaded = useSelector(({ creativeData }) => creativeData.loaded);
  const filterCharts = useSelector(({ creativeData }) => creativeData.filters.charts);

  useEffect(() => {
    if (typeof welcomeStats !== "undefined" && welcomeStats.length !== 0) {
      setWelcomeData({
        title: "Welcome!",
        subTitle: "The creative review is of",
        items: [
          {
            icon: <LaptopOutlined />,
            title: { singular: "Device" },
            count: welcomeStats.device,
          },
          {
            icon: <ContainerOutlined />,
            title: { singular: "Creative Size" },
            count: welcomeStats.creative_size,
          },
          {
            icon: <DatabaseOutlined />,
            title: { singular: "Creative Type" },
            count: welcomeStats.creative_type,
          },
        ],
      });
    }
  }, [loadingWelcome]);

  useEffect(() => {
    setCurrent(location.pathname);
    setLasUrlPart(getLastUrlPart().toUpperCase());
  }, [location]);

  useEffect(() => {
    if (!loaded || (loaded && filterClientDataset && filterClientDataset !== currentFilters.clientDataset)) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (loaded && filterPeriod && currentFilters.period !== filterPeriod) {
      dispatch(setFilters({ period: filterPeriod }));
      dispatch(
        creativeWowScoresData([
          {
            index: "map",
            query: "Device,CreativeSize,CreativeType",
          },
          {
            index: "device",
            query: "Device",
          },
          {
            index: "creativeSize",
            query: "CreativeSize",
          },
          {
            index: "creativeType",
            query: "CreativeType",
          },
        ])
      );
    }
  }, [filterPeriod]);

  useEffect(() => {
    if (loaded && filterLineItem && currentFilters.lineItem !== filterLineItem) {
      dispatch(setFilters({ lineItem: filterLineItem }));
      getData();
    }

    if (loaded && filterInsertionOrder && currentFilters.lineItem !== filterInsertionOrder) {
      dispatch(setFilters({ lineItem: filterInsertionOrder }));
      getData();
    }

    if (loaded && filterTimeRange && currentFilters.timeRange !== filterTimeRange) {
      dispatch(setFilters({ timeRange: filterTimeRange }));
      getData();
    }

    if (loaded && filterCampaignId && currentFilters.campaignId !== filterCampaignId) {
      dispatch(setFilters({ campaignId: filterCampaignId }));
      getData();
    }
  }, [filterLineItem, filterInsertionOrder, filterTimeRange, filterCampaignId]);

  useEffect(() => {
    if (loaded && filterCharts) {
      dispatch(creativeDeviceTableMetricsData());
      dispatch(creativeDeviceTypeMetricsData());
      dispatch(creativeCreativeSizeMetricsData());
      dispatch(creativeCreativeTypeMetricsData());
      dispatch(creativeTopMetricsData());
    }
  }, [filterCharts]);

  useEffect(() => {
    if (
      loaded &&
      filterClientDataset &&
      filterClientDataset !== currentFilters.clientDataset &&
      currentFilters.clientDataset
    ) {
      getData();
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
    if (!currentFilters.clientDataset && filterClientDataset) {
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
  }, [filterClientDataset]);

  const getData = () => {
    dispatch(creativeWelcomeData());
    dispatch(creativeDeviceTableMetricsData());
    dispatch(creativeTopMetricsData());
    dispatch(
      creativeWowScoresData([
        {
          index: "map",
          query: "Device,CreativeSize,CreativeType",
        },
        {
          index: "device",
          query: "Device",
        },
        {
          index: "creativeSize",
          query: "CreativeSize",
        },
        {
          index: "creativeType",
          query: "CreativeType",
        },
      ])
    );
    dispatch(creativeDeviceTypeMetricsData());
    dispatch(creativeCreativeSizeMetricsData());
    dispatch(creativeCreativeTypeMetricsData());
    dispatch(setLoaded());
  };

  return (
    <div>
      <NavigationTop navigationItems={navigationItems} navigateTo={navigateTo} current={current} />
      {!loadingWelcome && !loadingWowScores && welcomeData && wowScores ? (
        <PageTop
          welcomeData={welcomeData}
          wowScores={{
            data: wowScores,
            mainChart: { part: "map", section: "MAP" },
            asideCharts: [
              { part: "device", section: lastUrlPart, name: "Device" },
              { part: "creativeSize", section: lastUrlPart, name: "Creative Size" },
              { part: "creativeType", section: lastUrlPart, name: "Creative Type" },
            ],
          }}
          chartAxes={true}
        />
      ) : (
        <Loading card />
      )}
      <Route exact path="/creative">
        <Redirect to="/creative/see" />
      </Route>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={300}>
          <Switch location={location}>
            <Route path="/creative/see" children={() => <SeeKpi loaded filterCharts />} />
            <Route path="/creative/think" children={() => <ThinkKpi loaded filterCharts />} />
            <Route path="/creative/do" children={() => <DoKpi loaded filterCharts />} />
            <Route component={Error404} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(Creative);
