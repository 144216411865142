import {
  FETCH_STRATEGY_STATS_BEGIN,
  FETCH_STRATEGY_STATS_SUCCESS,
  FETCH_STRATEGY_STATS_FAILURE,
  FETCH_STRATEGY_TOP_METRICS_BEGIN,
  FETCH_STRATEGY_TOP_METRICS_SUCCESS,
  FETCH_STRATEGY_TOP_METRICS_FAILURE,
  FETCH_STRATEGY_WOW_SCORES_BEGIN,
  FETCH_STRATEGY_WOW_SCORES_SUCCESS,
  FETCH_STRATEGY_WOW_SCORES_FAILURE,
  FETCH_STRATEGY_WOW_SCORES_END,
  FETCH_STRATEGY_ORDER_TABLE_METRICS_BEGIN,
  FETCH_STRATEGY_ORDER_TABLE_METRICS_SUCCESS,
  FETCH_STRATEGY_ORDER_TABLE_METRICS_FAILURE,
  FETCH_STRATEGY_INSERTION_ORDER_BEGIN,
  FETCH_STRATEGY_INSERTION_ORDER_SUCCESS,
  FETCH_STRATEGY_INSERTION_ORDER_FAILURE,
  FETCH_STRATEGY_LINE_ITEM_BEGIN,
  FETCH_STRATEGY_LINE_ITEM_SUCCESS,
  FETCH_STRATEGY_LINE_ITEM_FAILURE,
  STRATEGY_SET_FILTERS,
  STRATEGY_SET_LOADED,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { wowTransformData } from "./../../util/Helpers";
import { applyGlobalFilters } from "./../../util/Filters";

export const setLoaded = () => {
  return {
    type: STRATEGY_SET_LOADED,
  };
};

export const setFilters = (data) => {
  return {
    type: STRATEGY_SET_FILTERS,
    payload: data,
  };
};

export const strategyWelcomeData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STRATEGY_STATS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/strategy/counts", "strategyData", {
          campaignId: true,
          lineItem: true,
          insertionOrder: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_STRATEGY_STATS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_STRATEGY_STATS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const strategyTopMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STRATEGY_TOP_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/strategy/metrics/top", "strategyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({
          type: FETCH_STRATEGY_TOP_METRICS_SUCCESS,
          payload: data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_STRATEGY_TOP_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const strategyWowScoresData = (arr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_STRATEGY_WOW_SCORES_BEGIN });
    let index = 0;

    const fullArray = arr.map((item) => {
      let queryString = applyGlobalFilters("api/strategy/scores", "strategyData", {
        period: true,
        selected: item.query,
        lineItem: true,
        insertionOrder: true,
        campaignId: true,
      });
      return {
        ...item,
        url: queryString,
      };
    });

    for (let i = 0; i < fullArray.length; i++) {
      axios
        .get(fullArray[i].url)
        .then(({ data }) => {
          index++;

          dispatch({
            type: FETCH_STRATEGY_WOW_SCORES_SUCCESS,
            payload: { [fullArray[i].index]: wowTransformData(data) },
          });
          if (index >= fullArray.length) {
            dispatch({
              type: FETCH_STRATEGY_WOW_SCORES_END,
            });
          }
        })
        .catch(function (error) {
          console.log(i);
          dispatch({
            type: FETCH_STRATEGY_WOW_SCORES_FAILURE,
            payload: error.message,
          });
        });
    }
  };
};

export const orderTableMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STRATEGY_ORDER_TABLE_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/strategy/metrics/campaign/table", "strategyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_STRATEGY_ORDER_TABLE_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_STRATEGY_ORDER_TABLE_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const insertionOrderMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STRATEGY_INSERTION_ORDER_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/strategy/metrics/insertion-order", "strategyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_STRATEGY_INSERTION_ORDER_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_STRATEGY_INSERTION_ORDER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const lineItemMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_STRATEGY_LINE_ITEM_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/strategy/metrics/line-item", "strategyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_STRATEGY_LINE_ITEM_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_STRATEGY_LINE_ITEM_FAILURE,
          payload: error.message,
        });
      });
  };
};
