import React, { useEffect } from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { userSignIn, getUserDefaultPage } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import Error from "./../components/custom/Error";
import logo from "./../assets/images/logo-dark.png";
import logoWhite from "./../assets/images/logo-white.png";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push(getUserDefaultPage());
    }
  }, [token, props.history]);

  return (
    <>
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <img src={logo} alt="" className="main-logo" />
            <img src={logoWhite} alt="" className="main-logo main-logo-white" />
          </div>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-form">
              <div>
                <h1 style={{ marginBottom: "40px" }}>Login to Your Account</h1>
                <Error />
                <Form
                  form={form}
                  initialValues={{ agreement: true }}
                  onFinish={handleSubmit}
                  className="gx-signin-form gx-form-row0"
                >
                  <Form.Item
                    form={form}
                    name="username"
                    rules={[{ required: true, message: "The input can not be empty!" }]}
                  >
                    <Input placeholder="Username" />
                  </Form.Item>
                  <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
                    <Input type="password" placeholder="Password" />
                  </Form.Item>

                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        transform: (value) => value || undefined,
                        type: "boolean",
                        message: "Please agree the terms and conditions.",
                      },
                    ]}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Checkbox>
                      <IntlMessages id="appModule.iAccept" />
                      <Link
                        style={{ marginLeft: "4px" }}
                        to="/terms-and-conditions"
                        className="gx-signup-form-forgot gx-link"
                      >
                        <IntlMessages id="appModule.termAndCondition" />
                      </Link>
                    </Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" className="gx-mb-0 gx-px-5 btn-primary-alter" htmlType="submit">
                      <IntlMessages id="app.userAuth.signIn" />
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <span>
                      <IntlMessages id="app.userAuth.or" />
                    </span>
                    <br />
                    <br />
                    <Link to="/forgot-password">
                      <IntlMessages id="app.userAuth.forgotPassword" />
                    </Link>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="gx-app-login-gradient">
              <div>
                <h1>New Here?</h1>
                <p>Sign up and discover the full features of our services</p>
                <Link to="/signup" className="ant-btn  gx-mb-0 gx-px-5 gx-mt-3 btn-alter">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const WrappedNormalLoginForm = Form.create()(SignIn);

export default SignIn;
