import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Select } from "antd";
import { advertisers, createAdvertiserDataset, getOrganizationDataset } from "../../appRedux/actions/Advertiser";
import { getUser } from "../../appRedux/actions";
import { getClientDataset } from "../../appRedux/actions/Filters";

const AddAdvertisers = () => {
  const dispatch = useDispatch();
  const advertisersData = useSelector(({ advertisersData }) => advertisersData.advertisers);
  const partners = useSelector(({ advertisersData }) => advertisersData.organizationPartners);
  const userOrganizations = getUser().organizations;
  const [organizationId, setOrganizationId] = useState(0 === userOrganizations.length ? 0 : userOrganizations[0].id);
  const [partnerId, setPartnerId] = useState(false);
  let advertisersToCreate = [];

  const fetchAdvertisers = () => {
    dispatch(advertisers(organizationId, partnerId));
  };

  const createAdvertisers = () => {
    dispatch(createAdvertiserDataset(organizationId, partnerId, advertisersToCreate));
    dispatch(getClientDataset());
  };

  const handleOrganizationChange = (value) => {
    dispatch(getOrganizationDataset(value));
    setOrganizationId(value);
  };

  const handleAdvertiserSelect = (advertiser) => {
    advertisersToCreate = advertiser;
  };

  useEffect(() => {
    if (userOrganizations.length > 1) {
      dispatch(getOrganizationDataset(userOrganizations[0].id));
    }
  }, []);

  useEffect(() => {
    if (partners.status === "success") {
      setPartnerId(partners.data[0].partnerId);
    }
  }, [partners]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-login-content">
          {"created" === advertisersData.status ? <div className="ant-alert-success">Advertisers created</div> : ""}
          <div className="gx-mb-4">
            <h2>Add Your Advertisers to MAP</h2>
          </div>
          {1 < userOrganizations.length ? (
            <>
              <div className="gx-mb-4">
                <label>
                  <p style={{ marginBottom: "2px" }}>Select organization:</p>
                  <select
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      handleOrganizationChange(e.target.value);
                    }}
                  >
                    {userOrganizations.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="gx-mb-4">
                {partners.status === "success" && (
                  <label>
                    <p style={{ marginBottom: "2px" }}>Select partner:</p>
                    <select
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setPartnerId(e.target.value);
                      }}
                    >
                      {partners.data.map((item, index) => (
                        <option key={index} value={item.partnerId}>
                          {item.displayName}
                        </option>
                      ))}
                    </select>
                  </label>
                )}
                {partners.loading && "Loading..."}
                {partners.status === "failure" && "No partners found"}
              </div>
              <Button onClick={fetchAdvertisers}>Fetch Advertisers</Button>
            </>
          ) : (
            ""
          )}
          {false === advertisersData.loading && "success" === advertisersData.status ? (
            <>
              <div className="gx-mb-4">
                <label>
                  <p style={{ marginBottom: "2px" }}>Select Advertisers to create:</p>

                  <Select
                    style={{ width: "100%" }}
                    labelInValue
                    mode="multiple"
                    dropdownMatchSelectWidth={false}
                    placeholder="Advertisers"
                    optionFilterProp="children"
                    onChange={handleAdvertiserSelect}
                  >
                    {advertisersData.data.map((item, index) => (
                      <Select.Option key={index} value={item.advertiserId}>
                        {item.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </label>
              </div>
              <Button onClick={createAdvertisers}>Create Advertisers</Button>
            </>
          ) : (
            ""
          )}
          {null !== advertisersData.error ? <div className="ant-alert-error">{advertisersData.error}</div> : ""}
        </div>
      </div>
    </div>
  );
};

export default AddAdvertisers;
