import React from "react";
import { Row, Col } from "antd";
import Card from "../../components/CardBox";

const PrivacyPolicyContent = () => {
  return (
    <Row className="gx-mt-4">
      <Col xl={24} xs={2} className="gx-col-full">
        <Card className="gx-card" heading="PRIVACY POLICY">
          <div className="adocs-section-content ng-binding content">
            <p>MAPlatform Ltd (“we”) are committed to protecting and respecting your privacy.</p>
            <ol>
              <li>
                SCOPE OF POLICY
                <ol>
                  <li>
                    This policy (together with our terms of use and any other documents referred to on it) sets out the
                    basis on which any personal data we collect from you, or that you provide to us, will be processed
                    by us. Please read the following carefully to understand our views and practices regarding your
                    personal data and how we will treat it. By visiting{" "}
                    <a href="https://www.the-map.io" target="_blank">
                      https://www.the-map.io
                    </a>{" "}
                    or{" "}
                    <a href="https://mediaauditplatform.com.com" target="_blank">
                      https://mediaauditplatform.com.com
                    </a>{" "}
                    you are accepting and consenting to the practices described in this policy.
                  </li>
                  <li>
                    For the purpose of the Data Protection Act 1998 (the “Act”), the data controller is MAPlatform Ltd
                    of 48-49 Princes Place, Notting Hill, London W11 4QA.
                  </li>
                </ol>
              </li>
              <li>
                INFORMATION WE MAY COLLECT FROM YOU
                <ol>
                  <li>
                    We may collect and process the following data about you:
                    <ul>
                      <li>
                        Information you give us. You may give us information about you by filling in forms on our site
                        <a href="https://www.the-map.io" target="_blank">
                          https://www.the-map.io
                        </a>{" "}
                        or{" "}
                        <a href="https://mediaauditplatform.com.com" target="_blank">
                          https://mediaauditplatform.com.com
                        </a>{" "}
                        (“our sites”) or by corresponding with us by phone, e-mail or otherwise. This includes
                        information you provide when you register to use our site or subscribe to our service and when
                        you report a problem with our sites. The information you give us may include your name, address,
                        e-mail address and phone number, financial and credit card information and personal description.
                      </li>
                      <li>
                        Information we collect about you. With regard to each of your visits to our site we may
                        automatically collect the following information:
                        <ul>
                          <li type="i">
                            technical information, including the internet protocol (IP) address used to connect your
                            computer to the internet, your login information, browser type and version, time zone
                            setting, browser plug-in types and versions, operating system and platform;
                          </li>
                          <li type="i">
                            information about your visit, including the full Uniform Resource Locators (URL) clickstream
                            to, through and from our site (including date and time); products you viewed or searched
                            for; page response times, download errors, length of visits to certain pages, page
                            interaction information (such as scrolling, clicks, and mouse-overs), and methods used to
                            browse away from the page and any phone number used to call our customer service number.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Information we receive from other sources. We may receive information about you if you use any
                        of the other websites we operate or the other services we provide. We are also working closely
                        with third parties (including, for example, business partners, sub-contractors in technical,
                        payment and delivery services, advertising networks, analytics providers, search information
                        providers, credit reference agencies) and may receive information about you from them.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                COOKIES
                <p>
                  Our website uses cookies to distinguish you from other users of our website. This helps us to provide
                  you with a good experience when you browse our website and also allows us to improve our site. For
                  detailed information on the cookies we use and the purposes for which we use them see our Cookie
                  policy here.
                </p>
              </li>
              <li>
                USES MADE OF THE INFORMATION
                <ol>
                  <li>
                    We use information held about you in the following ways:
                    <ul>
                      <li>
                        We will use the information you give us to:
                        <ul>
                          <li type="i">
                            to carry out our obligations arising from any contracts entered into between you and us and
                            to provide you with the information, products and services that you request from us;
                          </li>
                          <li type="i">
                            to provide you with information about other goods and services we offer that are similar to
                            those that you have already purchased or enquired about;
                          </li>
                          <li type="i">
                            to provide you, or permit selected third parties to provide you, with information about
                            goods or services we feel may interest you. If you are an existing customer, we will only
                            contact you by electronic means (e-mail or SMS) with information about goods and services
                            similar to those which were the subject of a previous sale or negotiations of a sale to you.
                            If you are a new customer, and where we permit selected third parties to use your data, we
                            (or they) will contact you by electronic means only if you have consented to this. If you do
                            not want us to use your data in this way, or to pass your details on to third parties for
                            marketing purposes, please tick the relevant box situated on the form on which we collect
                            your data (the “registration form”);
                          </li>
                          <li type="i">to notify you about changes to our service; and</li>
                          <li type="i">
                            to ensure that content from our site is presented in the most effective manner for you and
                            for your computer.
                          </li>
                        </ul>
                      </li>
                      <li>
                        We will use the information we collect about you to:
                        <ul>
                          <li type="i">
                            to administer our site and for internal operations, including troubleshooting, data
                            analysis, testing, research, statistical and survey purposes;
                          </li>
                          <li type="i">
                            to improve our site to ensure that content is presented in the most effective manner for you
                            and for your computer;
                          </li>
                          <li type="i">
                            to allow you to participate in interactive features of our service, when you choose to do
                            so;
                          </li>
                          <li type="i">as part of our efforts to keep our site safe and secure;</li>
                          <li type="i">
                            to measure or understand the effectiveness of advertising we serve to you and others, and to
                            deliver relevant advertising to you; and
                          </li>
                          <li type="i">
                            to make suggestions and recommendations to you and other users of our site about goods or
                            services that may interest you or them.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Should we receive information from other sources, we may combine this information with
                        information you give to us and information we collect about you. We may us this information and
                        the combined information for the purposes set out above (depending on the types of information
                        we receive).
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                DISCLOSURE OF YOUR INFORMATION
                <ol>
                  <li>
                    We may share your personal information with any member of our group, which means our subsidiaries,
                    our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies
                    Act 2006.
                  </li>
                  <li>
                    We may share your information with selected third parties including:
                    <ul>
                      <li>
                        Business partners, suppliers and sub-contractors for the performance of any contract we enter
                        into with them or you.
                      </li>
                      <li>
                        Advertisers and advertising networks that require the data to select and serve relevant adverts
                        to you and others.
                      </li>
                      <li>
                        Analytics and search engine providers that assist us in the improvement and optimisation of our
                        site.
                      </li>
                      <li>
                        Credit reference agencies for the purpose of assessing your credit score where this is a
                        condition of us entering into a contract with you.
                      </li>
                    </ul>
                  </li>
                  <li>
                    We may disclose your personal information to third parties:
                    <ul>
                      <li>
                        In the event that we sell or buy any business or assets, in which case we may disclose your
                        personal data to the prospective seller or buyer of such business or assets.
                      </li>
                      <li>
                        If MAPlatform Ltd or substantially all of its assets are acquired by a third party, in which
                        case personal data held by it about its customers will be one of the transferred assets.
                      </li>
                      <li>
                        If we are under a duty to disclose or share your personal data in order to comply with any legal
                        obligation.
                      </li>
                      <li>
                        In order to:
                        <ul>
                          <li type="i">enforce or apply our terms of use and other agreements; or</li>
                          <li type="i">
                            protect the rights, property, or safety of MAPlatformLtd, our customers, or others. This
                            includes exchanging information with other companies and organisations for the purposes of
                            fraud protection and credit risk reduction.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                WHERE WE STORE YOUR PERSONAL DATA
                <ol>
                  <li>
                    The data that we collect from you may be transferred to, and stored at, a destination outside the
                    European Economic Area (“EEA“). It may also be processed by staff operating outside the EEA who work
                    for us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment
                    of your order, the processing of your payment details and the provision of support services. By
                    submitting your personal data, you agree to this transfer, storing or processing. We will take all
                    steps reasonably necessary to ensure that your data is treated securely and in accordance with this
                    privacy policy.
                  </li>
                  <li>
                    All information you provide to us is stored on our secure servers. Any payment transactions will be
                    encrypted using SSL technology.
                  </li>
                  <li>
                    Where we have given you (or where you have chosen) a password which enables you to access certain
                    parts of our site, you are responsible for keeping this password confidential. We ask you not to
                    share a password with anyone.
                  </li>
                  <li>
                    Unfortunately, the transmission of information via the internet is not completely secure. Although
                    we will do our best to protect your personal data, we cannot guarantee the security of your data
                    transmitted to our site; any transmission is at your own risk. Once we have received your
                    information, we will use strict procedures and security features to try to prevent unauthorised
                    access.
                  </li>
                </ol>
              </li>
              <li>
                YOUR RIGHTS
                <ol>
                  <li>
                    You have the right to ask us not to process your personal data for marketing purposes. We will
                    usually inform you (before collecting your data) if we intend to use your data for such purposes or
                    if we intend to disclose your information to any third party for such purposes. You can exercise
                    your right to prevent such processing by checking certain boxes on the forms we use to collect your
                    data. You can also exercise the right at any time by contacting us at MAPlatform Ltd of 48-49
                    Princes Place, Notting Hill, London W11 4QA or by email to{" "}
                    <a href="mailto:info@the-map.io">info@the-map.io</a>.
                  </li>
                  <li>
                    Our site may, from time to time, contain links to and from the websites of our partner networks,
                    advertisers and affiliates. If you follow a link to any of these websites, please note that these
                    websites have their own privacy policies and that we do not accept any responsibility or liability
                    for these policies. Please check these policies before you submit any personal data to these
                    websites.
                  </li>
                </ol>
              </li>
              <li>
                ACCESS TO INFORMATION
                <p>
                  The Act gives you the right to access information held about you. Your right of access can be
                  exercised in accordance with the Act. Any access request may be subject to a fee of £10 to meet our
                  costs in providing you with details of the information we hold about you.
                </p>
              </li>
              <li>
                CHANGES TO OUR PRIVACY POLICY
                <p>
                  Any changes we may make to our privacy policy in the future will be posted on this page and, where
                  appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to
                  our privacy policy.
                </p>
              </li>
              <li>
                CONTACT
                <p>
                  Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to
                  MAPlatform Ltd of 48-49 Princes Place, Notting Hill, London W11 4QA or by email to{" "}
                  <a href="mailto:info@the-map.io">info@the-map.io</a>
                </p>
              </li>
            </ol>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default PrivacyPolicyContent;
