import { FETCH_REFRESH_BEGIN, FETCH_REFRESH_SUCCESS, FETCH_REFRESH_FAILURE } from "../../constants/ActionTypes";

const INIT_STATE = {
  refresh: {
    loading: false,
    status: null,
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_REFRESH_BEGIN: {
      return {
        ...state,
        refresh: {
          loading: true,
          status: null,
          error: null,
        },
      };
    }
    case FETCH_REFRESH_SUCCESS: {
      return {
        ...state,
        refresh: {
          loading: false,
          status: "success",
          error: null,
        },
      };
    }

    case FETCH_REFRESH_FAILURE: {
      return {
        ...state,
        refresh: {
          loading: false,
          status: "failure",
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
