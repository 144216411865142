import {
  FETCH_CAMPAIGN_STATS_OVERVIEW_BEGIN,
  FETCH_CAMPAIGN_STATS_OVERVIEW_SUCCESS,
  FETCH_CAMPAIGN_STATS_OVERVIEW_FAILURE,
  FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_BEGIN,
  FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_SUCCESS,
  FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_FAILURE,
  CAMPAIGN_SET_LOADED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  welcomeStats: {
    loading: true,
    data: {},
    error: {},
  },
  settingsSum: {
    loading: true,
    data: {},
    error: {},
  },
  
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CAMPAIGN_STATS_OVERVIEW_BEGIN: {
      return {
        ...state,
        welcomeStats: {
          loading: true,
          error: {...state.welcomeStats.error},
          data: {...state.welcomeStats.data},
        },
      };
    }
    case FETCH_CAMPAIGN_STATS_OVERVIEW_SUCCESS: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: {...state.welcomeStats.error},
          data: {...state.welcomeStats.data, ...action.payload},
        },
      };
    }

    case FETCH_CAMPAIGN_STATS_OVERVIEW_FAILURE: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: {...state.welcomeStats.error, ...action.payload},
          data: {...state.welcomeStats.data},
        },
      };
    }

    case FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_BEGIN: {
      return {
        ...state,
        settingsSum: {
          loading: true,
          error: {...state.settingsSum.error},
          data: {...state.settingsSum.data}
        },
      };
    }
    case FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_SUCCESS: {
      return {
        ...state,
        settingsSum: {
          loading: false,
          error: {...state.settingsSum.error},
          data: {...state.settingsSum.data, ...action.payload},
        },
      };
    }

    case FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_FAILURE: {
      return {
        ...state,
        settingsSum: {
          loading: false,
          error: {...state.settingsSum.error, ...action.payload},
          data: {...state.settingsSum.data},
        },
      };
    }

    

    default:
      return state;
  }
};
