import {
  FETCH_CAMPAIGN_STATS_BEGIN,
  FETCH_CAMPAIGN_STATS_SUCCESS,
  FETCH_CAMPAIGN_STATS_FAILURE,
  FETCH_CAMPAIGN_STATS_CANCEL,
  FETCH_CAMPAIGN_SETTINGS_SUM_BEGIN,
  FETCH_CAMPAIGN_SETTINGS_SUM_SUCCESS,
  FETCH_CAMPAIGN_SETTINGS_SUM_FAILURE,
  FETCH_CAMPAIGN_SETTINGS_SUM_CANCEL,
  FETCH_CAMPAIGN_CATEGORIES_BEGIN,
  FETCH_CAMPAIGN_CATEGORIES_SUCCESS,
  FETCH_CAMPAIGN_CATEGORIES_FAILURE,
  FETCH_CAMPAIGN_CATEGORIES_CANCEL,
  CAMPAIGN_SET_FILTERS,
  CAMPAIGN_SET_LOADED,
  CAMPAIGN_SET_DOWNLOADING,
  FETCH_CAMPAIGN_LAST_MODIFIED_BEGIN,
  FETCH_CAMPAIGN_LAST_MODIFIED_SUCCESS,
  FETCH_CAMPAIGN_LAST_MODIFIED_FAILURE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loaded: false,
  downloading: false,
  filters: {
    campaignInsertionOrder: "",
  },
  welcomeStats: {
    loading: true,
    data: [],
    error: null,
  },
  settingsSum: {
    loading: true,
    data: [],
    error: null,
  },
  categories: {
    loading: true,
    data: [],
    error: null,
  },
  lastModified: {
    loading: true,
    data: "N/A",
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAMPAIGN_SET_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }
    case CAMPAIGN_SET_DOWNLOADING: {
      return {
        ...state,
        downloading: action.payload,
      };
    }
    case CAMPAIGN_SET_FILTERS: {
      return {
        ...state,
        filters: Object.assign(state.filters, action.payload),
      };
    }
    case FETCH_CAMPAIGN_STATS_BEGIN: {
      return {
        ...state,
        welcomeStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_CAMPAIGN_STATS_SUCCESS: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_CAMPAIGN_STATS_FAILURE: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_CAMPAIGN_STATS_CANCEL: {
      return {
        ...state,
        welcomeStats: {
          ...state.welcomeStats,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_CAMPAIGN_SETTINGS_SUM_BEGIN: {
      return {
        ...state,
        settingsSum: {
          loading: true,
          error: null,
          data: {},
        },
      };
    }
    case FETCH_CAMPAIGN_SETTINGS_SUM_SUCCESS: {
      return {
        ...state,
        settingsSum: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_CAMPAIGN_SETTINGS_SUM_CANCEL: {
      return {
        ...state,
        settingsSum: {
          ...state.settingsSum,
          data: [],
        },
      };
    }

    case FETCH_CAMPAIGN_SETTINGS_SUM_FAILURE: {
      return {
        ...state,
        settingsSum: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_CAMPAIGN_CATEGORIES_BEGIN: {
      return {
        ...state,
        categories: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_CAMPAIGN_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_CAMPAIGN_CATEGORIES_FAILURE: {
      return {
        ...state,
        categories: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_CAMPAIGN_CATEGORIES_CANCEL: {
      return {
        ...state,
        categories: {
          ...state.categories,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_CAMPAIGN_LAST_MODIFIED_BEGIN: {
      return {
        ...state,
        lastModified: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_CAMPAIGN_LAST_MODIFIED_SUCCESS: {
      return {
        ...state,
        lastModified: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_CAMPAIGN_LAST_MODIFIED_FAILURE: {
      return {
        ...state,
        lastModified: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};
