import React from "react";
import { useDispatch, useSelector } from "react-redux";

import AmCharts from "@amcharts/amcharts3-react";
import { valueWithUnits } from "./../util/Helpers";
import RemoveFilter from "./../components/custom/RemoveFilter";

const ZoomableBubbleChart = ({ data, axis, options, setFilters }) => {
  const dispatch = useDispatch();

  const handleClick = (event) => {
    dispatch(
      setFilters({
        charts: [...options.filters.charts, { [options.chart.filter]: event.item.dataContext[options.chart.key] }],
      })
    );
  };

  const config = {
    type: "xy",
    theme: "none",
    marginRight: 0,
    marginTop: 0,
    dataProvider: data,
    valueAxes: [
      {
        position: "bottom",
        axisAlpha: 0,
        minimum: 0,
        title: axis.x.label,
      },
      {
        minMaxMultiplier: 1.2,
        axisAlpha: 0,
        position: "left",
        minimum: 0,
        title: axis.y.label,
      },
    ],
    startDuration: 1.5,
    graphs: [
      {
        balloonText: `
        <b>[[${axis.title}]]</b>
        ${axis.x.label}:<b>${valueWithUnits("[[x]]", axis.x.units)}</b>
        ${axis.y.label}:<b>${valueWithUnits("[[y]]", axis.y.units)}</b>
        Total spend:<b>${valueWithUnits("[[value]]", "currency")}</b>`,
        bullet: "round",
        lineAlpha: 0,
        valueField: "TotalSpend",
        xField: axis.x.label,
        yField: axis.y.label,
        fillAlphas: 0,
        bulletBorderAlpha: 0.1,
        maxBulletSize: 80,
        animationPlayed: true,
        bulletAlpha: 0.7,
        lineColor: "#ED7D31",
      },
    ],
    marginLeft: 0,
    marginBottom: 0,
    chartScrollbar: {},
    chartCursor: {},
    balloon: {
      fixedPosition: true,
    },
    listeners: [
      {
        event: "clickGraphItem",
        method: handleClick,
      },
    ],
  };

  return (
    <div className="App">
      {options && options.filters.charts.find((filter) => options.chart.filter in filter) ? (
        <RemoveFilter
          setFilters={setFilters}
          filtersData={options.filters.charts}
          removedFilter={options.chart.filter}
        />
      ) : (
        false
      )}
      <AmCharts.React style={{ width: "100%", height: "500px" }} options={config} />
    </div>
  );
};

export default ZoomableBubbleChart;
