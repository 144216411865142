import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { oauthLink, saveToken } from "../../appRedux/actions/OAuth";
import { getUser, hasUserRole } from "../../appRedux/actions";
import { ROLE_ADMIN } from "../../constants/Roles";
import { useHistory } from "react-router-dom";

const OAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const linkData = useSelector(({oauthLinkData}) => oauthLinkData.oAuth.data);
  const urlParams = new URLSearchParams(window.location.search);
  const oAuthCode = urlParams.get('code');
  const oAuthCodeState = urlParams.get('state');
  const userOrganizations = getUser().organizations;
  let organizationId = 0 === userOrganizations.length ? 0 : userOrganizations[0].id;

  const onClick = () => {
    dispatch(oauthLink(organizationId));
  }

  useEffect(() => {
    if (typeof linkData !== "undefined" && linkData.length !== 0) {
      window.location.assign(linkData.authUrl);
    }
  }, [linkData]);

  useEffect(() => {
    if (null !== oAuthCode && 0 !== oAuthCode.length && null !== oAuthCodeState && 0 !== oAuthCodeState.length) {
      dispatch(saveToken(oAuthCode, oAuthCodeState));
    }
  }, []);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-login-content">
          <div className="gx-mb-4">
            <h2>Grant OAuth Access</h2>
          </div>
          {1 < userOrganizations.length && !oAuthCode ?
            <>
              <div className="gx-mb-4">
                <label>
                  Select organization to grant access
                  <select onChange={(e) => {
                    organizationId = e.target.value;
                  }}>
                    {userOrganizations.map((item, index) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </label>
              </div>
              <Button onClick={onClick}>
                Grant access
              </Button>
            </>
            : ''
          }
          {
            oAuthCode && 0 !== oAuthCode.length ?
              <>
                <div className="ant-alert-success">Access granted</div>
                <div>
                  <Button onClick={() => history.push("/user/add-advertisers")} hidden={!hasUserRole(ROLE_ADMIN)}>Add
                    Advertisers</Button>
                </div>
              </>
              :
              ''
          }
        </div>
      </div>
    </div>
  );
};

export default OAuth;
