import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import NavigationTop from "./../Navigation";
import SeeKpi from "./tabs/SeeKpi";
import DoKpi from "./tabs/DoKpi";
import ThinkKpi from "./tabs/ThinkKpi";
import { push } from "connected-react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Error404 from "../Error404";
import PageTop from "../Top";
import Loading from "../../components/Loading";
import { getLastUrlPart } from "./../../util/Helpers";

import {
  inventoryWelcomeData,
  inventoryWowScoresData,
  inventoryAouTableMetricsData,
  inventoryTopMetricsData,
  inventoryAouMetricsData,
  inventoryCreativeSizeMetricsData,
  inventoryExchangeMetricsData,
  setFilters,
  setLoaded,
} from "../../appRedux/actions/Inventory";
import { LaptopOutlined, CodeSandboxOutlined, ArrowsAltOutlined } from "@ant-design/icons";

const navigationItems = [
  { label: "See KPIs", path: "/inventory/see" },
  { label: "Think KPIs", path: "/inventory/think" },
  { label: "Do KPIs", path: "/inventory/do" },
];

const Inventory = ({ navigateTo }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(location.pathname);
  const [welcomeData, setWelcomeData] = useState();
  const [lastUrlPart, setLasUrlPart] = useState();

  const loadingWelcome = useSelector(({ inventoryData }) => inventoryData.welcomeStats.loading);
  const welcomeStats = useSelector(({ inventoryData }) => inventoryData.welcomeStats.data);
  const loadingWowScores = useSelector(({ inventoryData }) => inventoryData.wowScoresStats.loading);
  const wowScores = useSelector(({ inventoryData }) => inventoryData.wowScoresStats.data);
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const filterPeriod = useSelector(({ filtersData }) => filtersData.period.current);
  const filterLineItem = useSelector(({ filtersData }) => filtersData.lineItem.current);
  const filterInsertionOrder = useSelector(({ filtersData }) => filtersData.insertionOrder.current);
  const filterCampaignId = useSelector(({ filtersData }) => filtersData.campaignId.current);
  const filterTimeRange = useSelector(({ filtersData }) => filtersData.timeRange.current);
  const currentFilters = useSelector(({ inventoryData }) => inventoryData.filters);
  const loaded = useSelector(({ inventoryData }) => inventoryData.loaded);
  const filterCharts = useSelector(({ inventoryData }) => inventoryData.filters.charts);

  useEffect(() => {
    setCurrent(location.pathname);
    setLasUrlPart(getLastUrlPart().toUpperCase());
  }, [location]);

  useEffect(() => {
    if (!loaded || (loaded && filterClientDataset && filterClientDataset !== currentFilters.clientDataset)) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (
      loaded &&
      filterClientDataset &&
      filterClientDataset !== currentFilters.clientDataset &&
      currentFilters.clientDataset
    ) {
      getData();
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
    if (!currentFilters.clientDataset && filterClientDataset) {
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
  }, [filterClientDataset]);

  const getData = () => {
    dispatch(inventoryWelcomeData());
    dispatch(
      inventoryWowScoresData([
        {
          index: "map",
          query: "Device,AppURL,CreativeSize",
        },
        {
          index: "device",
          query: "Device",
        },
        {
          index: "appUrl",
          query: "AppURL",
        },
        {
          index: "creativeSize",
          query: "CreativeSize",
        },
      ])
    );

    dispatch(inventoryAouTableMetricsData());
    dispatch(inventoryTopMetricsData());
    dispatch(inventoryAouMetricsData());
    dispatch(inventoryCreativeSizeMetricsData());
    dispatch(inventoryExchangeMetricsData());
    dispatch(setLoaded());
  };

  useEffect(() => {
    if (loaded && filterCharts) {
      dispatch(inventoryAouTableMetricsData());
      dispatch(inventoryAouMetricsData());
      dispatch(inventoryCreativeSizeMetricsData());
      dispatch(inventoryExchangeMetricsData());
      dispatch(inventoryTopMetricsData());
    }
  }, [filterCharts]);

  useEffect(() => {
    if (loaded && filterLineItem && currentFilters.lineItem !== filterLineItem) {
      dispatch(setFilters({ lineItem: filterLineItem }));
      getData();
    }

    if (loaded && filterInsertionOrder && currentFilters.lineItem !== filterInsertionOrder) {
      dispatch(setFilters({ lineItem: filterInsertionOrder }));
      getData();
    }

    if (loaded && filterTimeRange && currentFilters.timeRange !== filterTimeRange) {
      dispatch(setFilters({ timeRange: filterTimeRange }));
      getData();
    }

    if (loaded && filterCampaignId && currentFilters.campaignId !== filterCampaignId) {
      dispatch(setFilters({ campaignId: filterCampaignId }));
      getData();
    }
  }, [filterLineItem, filterInsertionOrder, filterTimeRange, filterCampaignId]);

  useEffect(() => {
    if (loaded && filterPeriod && currentFilters.period !== filterPeriod) {
      dispatch(setFilters({ period: filterPeriod }));
      dispatch(
        inventoryWowScoresData([
          {
            index: "map",
            query: "Device,AppURL,CreativeSize",
          },
          {
            index: "device",
            query: "Device",
          },
          {
            index: "appUrl",
            query: "AppURL",
          },
          {
            index: "creativeSize",
            query: "CreativeSize",
          },
        ])
      );
    }
  }, [filterPeriod]);

  useEffect(() => {
    if (typeof welcomeStats !== "undefined" && welcomeStats.length !== 0) {
      setWelcomeData({
        title: "Welcome!",
        subTitle: "The inventory review is of",
        items: [
          {
            icon: <LaptopOutlined />,
            title: { singular: "Device" },
            count: welcomeStats.device,
          },
          {
            icon: <CodeSandboxOutlined />,
            title: { singular: "App or URL", plural: "Apps or URLs" },
            count: welcomeStats.app_url,
          },
          {
            icon: <ArrowsAltOutlined />,
            title: {
              singular: "Creative Size",
            },
            count: welcomeStats.creative_size,
          },
        ],
      });
    }
  }, [loadingWelcome]);

  return (
    <div>
      <NavigationTop navigationItems={navigationItems} navigateTo={navigateTo} current={current} />
      {!loadingWelcome && !loadingWowScores && welcomeData ? (
        <PageTop
          welcomeData={welcomeData}
          wowScores={{
            data: wowScores,
            mainChart: { part: "map", section: "MAP" },
            asideCharts: [
              { part: "device", section: lastUrlPart, name: "Device" },
              { part: "appUrl", section: lastUrlPart, name: "App Or URL" },
              { part: "creativeSize", section: lastUrlPart, name: "Creative Size" },
            ],
          }}
          chartAxes={true}
        />
      ) : (
        <Loading card />
      )}

      <Route exact path="/inventory">
        <Redirect to="/inventory/see" />
      </Route>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={300}>
          <Switch location={location}>
            <Route path="/inventory/see" children={() => <SeeKpi />} />
            <Route path="/inventory/think" children={() => <ThinkKpi />} />
            <Route path="/inventory/do" children={() => <DoKpi />} />
            <Route component={Error404} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(Inventory);
