import React, { useState, useEffect } from "react";
import AmCharts from "@amcharts/amcharts3-react";

const Map = ({ name, data }) => {
  const [minBulletSize] = useState(1);
  const [maxBulletSize] = useState(30);
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(100);
  const [maxSquare] = useState(maxBulletSize * maxBulletSize * 2 * Math.PI);
  const [minSquare] = useState(minBulletSize * minBulletSize * 2 * Math.PI);
  const [localizations, setLocalizations] = useState(null);

  const [dataProvider, setDataProvider] = useState({
    map: "worldLow",
    zoomLevel: 25,
    zoomLongitude: data[1] ? data[1].longitude : data[0].longitude,
    zoomLatitude: data[1] ? data[1].latitude : data[0].latitude,
    images: [],
  });

  useEffect(() => {
    let filteredData = data.filter((item) => item.latitude);

    let tempMin = Infinity;
    let tempMax = -Infinity;
    for (var i = 0; i < filteredData.length; i++) {
      var value = filteredData[i][name];
      if (value < tempMin) {
        tempMin = value;
      }
      if (value > tempMax) {
        tempMax = value;
      }
    }

    setMin(tempMin);
    setMax(tempMax);

    var data2 = filteredData.map((item) => {
      return {
        zoomLevel: 5,
        scale: 0.5,
        title: item.City,
        ...item,
      };
    });
    setLocalizations(data2);
  }, []);

  return (
    <>
      {localizations ? (
        <AmCharts.React
          className={name}
          style={{
            width: "100%",
            height: "217px",
          }}
          options={{
            type: "map",
            theme: "none",

            dataProvider: dataProvider,
            imagesSettings: {
              labelPosition: "middle",
              labelFontSize: 8,
            },

            areasSettings: {
              unlistedAreasColor: "#8f98a6",
              outlineThickness: 0.1,
            },

            listeners: [
              {
                event: "init",
                method: function (e) {
                  var map = e.chart;

                  setTimeout(function () {
                    map.dataProvider.images = [];
                    for (var x in localizations) {
                      var dataItem = localizations[x];
                      var value = dataItem[name];

                      var square = ((value - min) / (max - min)) * (maxSquare - minSquare) + minSquare;
                      if (square < minSquare) {
                        square = minSquare;
                      }
                      var size = Math.sqrt(square / (Math.PI * 2));

                      var area = localizations[x];
                      area.groupId = area.id;
                      var image = new AmCharts.MapImage();
                      image.type = "circle";
                      image.width = size;
                      image.height = size;
                      image.color = "#1f3742";
                      image.latitude = localizations[x].latitude;
                      image.longitude = localizations[x].longitude;
                      image.label = value;
                      image.labelColor = "#ffffff";
                      image.title = area.title;
                      image.linkToObject = area;
                      image.groupId = area.id;
                      map.dataProvider.images.push(image);
                    }
                    map.validateData();
                  }, 500);
                },
              },
            ],
          }}
        />
      ) : (
        "loading"
      )}
    </>
  );
};

export default Map;
