import {
  FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_BEGIN,
  FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_FAILURE,
  FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_SUCCESS,
  FETCH_CAMPAIGN_STATS_OVERVIEW_BEGIN,
  FETCH_CAMPAIGN_STATS_OVERVIEW_FAILURE,
  FETCH_CAMPAIGN_STATS_OVERVIEW_SUCCESS,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { applyGlobalFilters } from "../../util/Filters";


export const campaignStatsOverviewData = (dataset) => {

  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN_STATS_OVERVIEW_BEGIN });
    let obj = {}
    axios
      .get(applyGlobalFilters("api/sdf/counts", "campaignData"), {
        headers: {
          'Map-Dataset': dataset.name
        }
      })
      .then(({ data }) => {
        obj[dataset.name] = data;
        dispatch({ type: FETCH_CAMPAIGN_STATS_OVERVIEW_SUCCESS, payload: obj });
      })
      .catch(function (error) {
        obj[dataset.name] = error.message;
        dispatch({
          type: FETCH_CAMPAIGN_STATS_OVERVIEW_FAILURE,
          payload: obj,
        });
      });
  };
};

export const campaignSettingsSumOverviewData = (dataset) => {

  
  return (dispatch) => {

    dispatch({ type: FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_BEGIN });
    let obj = {}
    axios
      .get(applyGlobalFilters("api/sdf/settings", "campaignData"), {
        headers: {
          'Map-Dataset': dataset.name
        }
      })
      .then(({ data }) => {
        obj[dataset.name] = data;
        dispatch({ type: FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_SUCCESS, payload: obj });
      })
      .catch(function (error) {
        obj[dataset.name] = error.message;
        dispatch({
          type: FETCH_CAMPAIGN_SETTINGS_SUM_OVERVIEW_FAILURE,
          payload: obj,
        });
      });
  };
};


