import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ResponsiveContainer } from "recharts";
import moment from "moment";

import { Row, Col } from "antd";
import Card from "../../../components/CardBox";

import XyChartWithDateBasedAxis from "./../XyChartWithDateBasedAxis";
import DurationOnValueAxis from "./../DurationOnValueAxis";
import PageTopSummary from "./../../TopCharts";
import Loading from "../../../components/Loading";

const DoKpi = () => {
  const [summaryData, setSummaryData] = useState([]);
  const [parsedDowMetrics, setParsedDowMetrics] = useState([]);

  const topMetrics = useSelector(({ temporalData }) => temporalData.topMetrics.data);
  const loadingTopMetrics = useSelector(({ temporalData }) => temporalData.topMetrics.loading);

  const loadingTodMetrics = useSelector(({ temporalData }) => temporalData.todMetrics.loading);
  const todMetrics = useSelector(({ temporalData }) => temporalData.todMetrics.data);

  const loadingDowMetrics = useSelector(({ temporalData }) => temporalData.dowMetrics.loading);
  const dowMetrics = useSelector(({ temporalData }) => temporalData.dowMetrics.data);

  const loadingDowTodMetrics = useSelector(({ temporalData }) => temporalData.dowTodMetrics.loading);
  const dowTodMetrics = useSelector(({ temporalData }) => temporalData.dowTodMetrics.data);

  useEffect(() => {
    if (typeof topMetrics !== "undefined" && topMetrics.length !== 0) {
      setSummaryData([
        {
          title: "TOTAL SPEND",
          value: topMetrics.total_spend,
          units: "currency",
        },
        {
          title: "IMPRESSIONS",
          value: topMetrics.impressions_sum,
        },
        {
          title: "VIEWABLE IMPRESSIONS",
          value: topMetrics.viewable_impressions_sum,
        },
        {
          title: "CONVERSIONS",
          value: topMetrics.total_conversions_sum,
        },
        { title: "CR", value: topMetrics.CR, units: "%" },
        { title: "CPA", value: topMetrics.CPA, units: "currency" },
      ]);
    }
  }, [loadingTopMetrics]);

  useEffect(() => {
    if (dowMetrics) {
      setParsedDowMetrics(
        dowMetrics.map((item) => {
          item.DOW = moment().day(item["DOW"]).format("ddd");
          return item;
        })
      );
    }
  }, [dowMetrics]);

  return (
    <div>
      {!loadingTopMetrics ? <PageTopSummary summaryData={summaryData} /> : <Loading card />}

      <Row>
        <Col xs={24} className="gx-col-full">
          <Card className="gx-card">
            {!loadingDowTodMetrics ? (
              <XyChartWithDateBasedAxis
                data={dowTodMetrics}
                attr={{ value: "CPA", x: "TOD", y: "DOW", units: "currency" }}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="Time of day">
            <ResponsiveContainer>
              {!loadingTodMetrics ? (
                <DurationOnValueAxis
                  data={todMetrics}
                  attr={{
                    main: "TOD",
                    left: { label: "CPA", units: "currency" },
                    right: { label: "CR", units: "%" },
                  }}
                />
              ) : (
                <Loading />
              )}
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="Day of week">
            {!loadingDowMetrics && parsedDowMetrics ? (
              <DurationOnValueAxis
                data={parsedDowMetrics}
                attr={{
                  main: "DOW",
                  left: { label: "CPA", units: "currency" },
                  right: { label: "CR", units: "%" },
                }}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DoKpi;
