import {
  FETCH_DATASET_SCORE_BEGIN,
  FETCH_DATASET_SCORE_SUCCESS,
  FETCH_DATASET_SCORE_FAILURE,
} from "../../constants/ActionTypes";

import axios from "util/Api";

export const getDatasetScore = (organizationId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DATASET_SCORE_BEGIN });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/client/dataset/info/${organizationId}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_DATASET_SCORE_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_DATASET_SCORE_FAILURE,
          payload: error.message,
        });
      });
  };
};
