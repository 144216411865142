import { store } from "./../NextApp";

export const applyGlobalFilters = (query, dataPart, filters) => {
  let filtersString = `${query}`;

  if (filters) {
    Object.keys(filters).map((filter) => {
      switch (filter) {
        case "period":
          filtersString = `${filtersString}/${store.getState().filtersData[filter].current}`;
          break;
        case "selected":
          filtersString = `${filtersString}/${filters[filter]}`;
          break;
        case "lineItem":
          let lineItemFilter = store.getState().filtersData[filter].current;

          if (lineItemFilter && lineItemFilter.length > 0) {
            lineItemFilter = lineItemFilter.map((item) => item.key);
            filtersString = `${filtersString}&lineItemId=${lineItemFilter.toString()}`;
          }
          break;
        case "insertionOrder":
          let insertionOrderFilter = store.getState().filtersData[filter].current;

          if (insertionOrderFilter && insertionOrderFilter.length > 0) {
            insertionOrderFilter = insertionOrderFilter.map((item) => item.key);
            filtersString = `${filtersString}&insertionOrderId=${insertionOrderFilter.toString()}`;
          }
          break;

        case "campaignId":
          let campaignIdFilter = store.getState().filtersData[filter].current;

          if (campaignIdFilter && campaignIdFilter.length > 0) {
            campaignIdFilter = campaignIdFilter.map((item) => item.key);
            filtersString = `${filtersString}&campaignId=${campaignIdFilter.toString()}`;
          }
        break;
        case "campaignInsertionOrder":
          let campaignInsertionOrderFilter = store.getState().campaignFiltersData[filter].current;

          if (campaignInsertionOrderFilter && campaignInsertionOrderFilter.length > 0) {
            campaignInsertionOrderFilter = campaignInsertionOrderFilter.map((item) => item.key);
            filtersString = `${filtersString}&campaignInsertionOrderId=${campaignInsertionOrderFilter.toString()}`;
          }
          break;
        case "timeRange":
          let timeRangeFilter = store.getState().filtersData[filter].current;

          if (timeRangeFilter && timeRangeFilter.length > 0) {
            filtersString = `${filtersString}&dateFrom=${timeRangeFilter[0].format(
              "YYYY-MM-DD"
            )}&dateTo=${timeRangeFilter[1].format("YYYY-MM-DD")}`;
          }
          break;
        case "charts":
          let chartsFilter = store.getState()[dataPart].filters[filter];

          chartsFilter.map((item) => {
            return Object.keys(item).forEach((key) => {
              return (filtersString = `${filtersString}&${key}=${item[key]}`);
            });
          });

          break;
        default:
          filtersString = filtersString;
      }

      return filtersString;
    });
  }

  return filtersString.replace(/&/, "?");
};
