import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, notification, Row } from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import FilterCampaignInsertionOrder from "../components/custom/FilterCampaignInsertionOrder";
import FilterLineItem from "../components/custom/FilterLineItem";
import FilterCampaignId from "../components/custom/FilterCampaignId";
import { refresh } from "../appRedux/actions/Refresh";
import { campaignCSVFile } from "../appRedux/actions/Campaign";

const CampaignNavigationTop = ({ sdf = false }) => {
  const dispatch = useDispatch();
  const loadingRefresh = useSelector(({ refreshData }) => refreshData.refresh.loading);
  const loadingStatus = useSelector(({ refreshData }) => refreshData.refresh.status);
  const lastModified = useSelector(({ campaignData }) => campaignData.lastModified);
  const isDownloading = useSelector(({ campaignData }) => campaignData.downloading);
  const [isSelectedID, setIsSelectedID] = useState(false);
  const [isSelectedOrder, setIsSelectedOrder] = useState(false);
  const [isSelectedLind, setIsSelectedLine] = useState(false);

  const onRefresh = () => {
    dispatch(refresh());
  };

  const onDownloadCSV = () => {
    dispatch(campaignCSVFile());
  };

  useEffect(() => {
    if (loadingStatus == "success") {
      notification["success"]({
        message: "Data update request has been sent",
        description: "Update process takes about 8-10 minutes for refresh.",
      });
    } else if (loadingStatus == "failure") {
      notification["error"]({
        message: "Refresh",
        description: "Refresh failure",
      });
    }
  }, [loadingRefresh]);

  return (
    <>
      <Row type="flex" gutter={[16, 0]} align="bottom" className="gx-mt-4 gx-mx-0 gx-w-100">
        <Col className="gx-mr-2 gx-px-0 gx-my-lg-0 gx-w-100">
          <Row type="flex" justify="space-between" className="gx-my-1 gx-mx-0">
            <Col
              className="gx-mr-3 gx-px-0 gx-my-0 gx-my-lg-0"
              style={{
                display: "flex",
                width: isSelectedLind || isSelectedOrder || isSelectedID ? "100%" : "auto",
                justifyContent: "flex-end",
              }}
            >
              <FilterCampaignId sdf={sdf} setIsSelected={setIsSelectedID} />
            </Col>
            <Col
              className="gx-mr-3 gx-px-0 gx-my-0 gx-my-lg-0"
              style={{
                display: "flex",
                width: isSelectedLind || isSelectedOrder || isSelectedID ? "100%" : "auto",
                justifyContent: "flex-end",
              }}
            >
              <FilterCampaignInsertionOrder setIsSelected={setIsSelectedOrder} />
            </Col>
            <Col
              className="gx-mr-3 gx-px-0 gx-my-0 gx-my-lg-0"
              style={{
                display: "flex",
                width: isSelectedLind || isSelectedOrder || isSelectedID ? "100%" : "auto",
                justifyContent: "flex-end",
              }}
            >
              <FilterLineItem sdf={sdf} setIsSelected={setIsSelectedLine} />
            </Col>

            <Col
              className=" gx-px-0 gx-my-lg-0 gx-ml-auto"
              style={{
                display: "flex",
                width: isSelectedLind || isSelectedOrder || isSelectedID ? "100%" : "auto",
                justifyContent: "flex-end",
              }}
            >
              <Button
                size="middle"
                className="gx-my-0"
                onClick={() => onDownloadCSV()}
                title={"Request for data update"}
                disabled={loadingRefresh}
              >
                {isDownloading ? <LoadingOutlined /> : "Download CSV"}
              </Button>
              <div style={{ display: "flex", flexWrap: "nowrap" }}>
                <Button
                  className="gx-my-0"
                  onClick={() => onRefresh()}
                  title={"Request for data update"}
                  disabled={loadingRefresh}
                >
                  {loadingRefresh ? <LoadingOutlined /> : <ReloadOutlined />}
                </Button>
                <div>
                  <div>Last modified:</div>
                  {lastModified.loading ? (
                    <div>Loading</div>
                  ) : (
                    <div style={{ fontSize: "9px" }}>{lastModified.data}</div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CampaignNavigationTop;
