import axios from "axios";
import { refreshToken, userSignOut } from "../appRedux/actions/Auth";
import { useSelector } from "react-redux";
import reduxStore from "../appRedux/store/index";
import { store } from "../NextApp";

import { SET_DATASET_VISIBLE } from "../constants/ActionTypes";

const store2 = reduxStore;

const setDefaultAuthorizationHeader = () => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
  };
};

if (localStorage.token) {
  setDefaultAuthorizationHeader();
}

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    if (localStorage.token) {
      setDefaultAuthorizationHeader();
    }

    if ("Map-Dataset" in config.headers) return config;

    let filterClientDataset =
      localStorage.getItem("dataSetCurrent") !== null
        ? JSON.parse(localStorage.dataSetCurrent)
        : store.getState().filtersData.clientDataset.current;

    if (filterClientDataset) {
      if (typeof filterClientDataset !== "string") filterClientDataset = filterClientDataset.name;
      if (filterClientDataset !== undefined) config.headers["Map-Dataset"] = filterClientDataset;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.config.url === "api/login_check") {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      if (error.config && !error.config.__isRetryRequest) {
        return new Promise((resolve, reject) => {
          const originalReq = error.config;
          originalReq._retry = true;
          refreshToken(originalReq, resolve, store.dispatch);

          return Promise.reject(error);
        });
      }
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
