import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import Loading from "../Loading";
import {
  getClientDataset,
  setClientDataset,
  getInsertionOrder,
  getLineItem,
  getCampaignId,
  getTimeRange,
  getCampaignGroupedInsertionOrder,
  setClientOrganization,
} from "../../appRedux/actions/Filters";
import { getDatasetScore } from "../../appRedux/actions/DataSetScore";

const FilterClients = () => {
  const dispatch = useDispatch();
  const clientDataset = useSelector(({ filtersData }) => filtersData.clientDataset);
  const clientOrganization = useSelector(({ filtersData }) => filtersData.clientOrganization);
  const [datasetList, setDatasetList] = useState(null);

  const onOrganisationChange = (value) => {
    dispatch(setClientOrganization(value));
    setDatasetList(clientOrganization.data.find((org) => org.name === value).datasets);
  };

  useEffect(() => {
    if (
      clientDataset &&
      clientDataset.data &&
      ((clientDataset.data.datasets && !!clientDataset.data.datasets.length) || !!clientDataset.data.length)
    ) {
      setDatasetList(clientDataset.data.datasets || clientDataset.data);
    }
  }, [clientDataset]);

  const onDatasetChange = (value) => {
    let sdf = false;
    if (window.location.href.includes("settings-qa")) {
      sdf = true;
    }
    var result = datasetList.find((obj) => {
      return obj.name === value;
    });

    dispatch(setClientDataset(value));
    dispatch(getInsertionOrder());
    dispatch(getLineItem(sdf));
    dispatch(getCampaignId(sdf));
    dispatch(getTimeRange());
    dispatch(getCampaignGroupedInsertionOrder());

    dispatch(getDatasetScore(result.id));
  };

  //old way unefficient
  // useEffect(() => {
  //   if (!clientDataset.data) {
  //     dispatch(getClientDataset());
  //   }
  // }, []);

  const getCurrentOrganisation = () => {
    return localStorage.organisationCurrent
      ? JSON.parse(localStorage.organisationCurrent)
      : clientOrganization.current.name;
  };

  const getCurrentClient = () => {
    return localStorage.dataSetCurrent
      ? JSON.parse(localStorage.dataSetCurrent)
      : clientDataset.current
      ? clientDataset.current.name
      : "";
  };

  return (
    <>
      {clientDataset.loading ? (
        <Loading className="gx-my-2" />
      ) : clientDataset.data ? (
        <>
          <Select
            onChange={onOrganisationChange}
            defaultValue={getCurrentOrganisation()}
            style={{ minWidth: 140, width: "100%" }}
          >
            {clientOrganization &&
              clientOrganization.data.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>

          <Select
            onChange={onDatasetChange}
            defaultValue={getCurrentClient()}
            style={{ minWidth: 140, width: "100%", marginTop: "10px" }}
          >
            {datasetList &&
              datasetList.length > 0 &&
              datasetList.map((item, index) => (
                <Select.Option key={index} value={item.name}>
                  {item.readableName}
                </Select.Option>
              ))}
          </Select>
        </>
      ) : (
        <div className="gx-my-2">No data</div>
      )}
    </>
  );
};

export default FilterClients;
