import {
  FETCH_TEMPORAL_STATS_BEGIN,
  FETCH_TEMPORAL_STATS_SUCCESS,
  FETCH_TEMPORAL_STATS_FAILURE,
  FETCH_TEMPORAL_WOW_SCORES_BEGIN,
  FETCH_TEMPORAL_WOW_SCORES_SUCCESS,
  FETCH_TEMPORAL_WOW_SCORES_FAILURE,
  FETCH_TEMPORAL_WOW_SCORES_END,
  FETCH_TEMPORAL_TOP_METRICS_BEGIN,
  FETCH_TEMPORAL_TOP_METRICS_SUCCESS,
  FETCH_TEMPORAL_TOP_METRICS_FAILURE,
  FETCH_TEMPORAL_TOD_METRICS_BEGIN,
  FETCH_TEMPORAL_TOD_METRICS_SUCCESS,
  FETCH_TEMPORAL_TOD_METRICS_FAILURE,
  FETCH_TEMPORAL_DOW_METRICS_BEGIN,
  FETCH_TEMPORAL_DOW_METRICS_SUCCESS,
  FETCH_TEMPORAL_DOW_METRICS_FAILURE,
  FETCH_TEMPORAL_DOW_TOD_METRICS_BEGIN,
  FETCH_TEMPORAL_DOW_TOD_METRICS_SUCCESS,
  FETCH_TEMPORAL_DOW_TOD_METRICS_FAILURE,
  TEMPORAL_SET_FILTERS,
  TEMPORAL_SET_LOADED,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { wowTransformData } from "./../../util/Helpers";
import { applyGlobalFilters } from "./../../util/Filters";

export const setLoaded = () => {
  return {
    type: TEMPORAL_SET_LOADED,
  };
};

export const setFilters = (data) => {
  return {
    type: TEMPORAL_SET_FILTERS,
    payload: data,
  };
};

export const temporalWelcomeData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_STATS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/temporal/counts", "temporalData", {
          lineItem: true,
          insertionOrder: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_TEMPORAL_STATS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_TEMPORAL_STATS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const temporalWowScoresData = (arr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_WOW_SCORES_BEGIN });
    let index = 0;

    const fullArray = arr.map((item) => {
      let queryString = applyGlobalFilters("api/temporal/scores", "temporalData", {
        period: true,
        selected: item.query,
        lineItem: true,
        insertionOrder: true,
        campaignId: true,
      });
      return {
        ...item,
        url: queryString,
      };
    });

    for (let i = 0; i < fullArray.length; i++) {
      axios
        .get(fullArray[i].url)
        .then(({ data }) => {
          index = index + 1;

          dispatch({
            type: FETCH_TEMPORAL_WOW_SCORES_SUCCESS,
            payload: { [fullArray[i].index]: wowTransformData(data) },
          });
          if (index >= fullArray.length) {
            dispatch({
              type: FETCH_TEMPORAL_WOW_SCORES_END,
            });
          }
        })
        .catch(function (error) {
          console.log(i);
          dispatch({
            type: FETCH_TEMPORAL_WOW_SCORES_FAILURE,
            payload: error.message,
          });
        });
    }
  };
};

export const temporalTopMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_TOP_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/temporal/metrics/top", "temporalData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_TEMPORAL_TOP_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_TEMPORAL_TOP_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const temporalTodMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_TOD_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/temporal/metrics/tod", "temporalData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_TEMPORAL_TOD_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_TEMPORAL_TOD_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const temporalDowMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_DOW_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/temporal/metrics/dow", "temporalData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_TEMPORAL_DOW_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_TEMPORAL_DOW_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const temporalDowTodMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_TEMPORAL_DOW_TOD_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/temporal/metrics/dow-tod", "temporalData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_TEMPORAL_DOW_TOD_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_TEMPORAL_DOW_TOD_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};
