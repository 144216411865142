import { valueWithUnits } from "./../util/Helpers";

export const columns = [
  {
    title: "Device type",
    dataIndex: "Device",
    key: "Device",
    sorter: (a, b) => {
      return a.Device.localeCompare(b.Device);
    },
  },
  {
    title: "City",
    dataIndex: "City",
    key: "City",
    sorter: (a, b) => {
      return a.City.localeCompare(b.City);
    },
  },
  {
    title: "Campaign",
    dataIndex: "InsertionOrder",
    key: "InsertionOrder",
    sorter: (a, b) => {
      return a.InsertionOrder.localeCompare(b.InsertionOrder);
    },
  },
  {
    title: "URL",
    dataIndex: "AppURL",
    key: "AppURL",
    sorter: (a, b) => {
      return a.AppURL.localeCompare(b.AppURL);
    },
  },
  {
    title: "Total spend",
    dataIndex: "TotalSpend",
    key: "TotalSpend",
    render: (text) => valueWithUnits(text, "currency"),
    sorter: (a, b) => {
      return a.TotalSpend - b.TotalSpend;
    },
  },
  {
    title: "Impressions",
    dataIndex: "Impressions",
    key: "Impressions",
    render: (text) => text.toLocaleString("en-GB"),
    sorter: (a, b) => {
      return a.Impressions - b.Impressions;
    },
  },
  {
    title: "viewable lmps",
    dataIndex: "ViewableImpressions",
    key: "ViewableImpressions",
    render: (text) => text.toLocaleString("en-GB"),
    sorter: (a, b) => {
      return a.ViewableImpressions - b.ViewableImpressions;
    },
  },
  {
    title: "Clicks",
    dataIndex: "Clicks",
    key: "Clicks",
    sorter: (a, b) => {
      return a.Clicks - b.Clicks;
    },
  },
  {
    title: "Total Conversions",
    dataIndex: "TotalConversions",
    key: "TotalConversions",
    render: (text) => text.toLocaleString("en-GB"),
    sorter: (a, b) => {
      return a.TotalConversions - b.TotalConversions;
    },
  },
  {
    title: "CPM",
    dataIndex: "CPM",
    key: "CPM",
    render: (text) => valueWithUnits(text, "currency"),
    sorter: (a, b) => {
      return a.CPM - b.CPM;
    },
  },
  {
    title: "vCPM",
    dataIndex: "vCPM",
    key: "vCPM",
    render: (text) => valueWithUnits(text, "currency"),
    sorter: (a, b) => {
      return a.vCPM - b.vCPM;
    },
  },

  {
    title: "CTR",
    dataIndex: "CTR",
    key: "CTR",
    render: (text) => valueWithUnits(text, "%"),
    sorter: (a, b) => {
      return a.CTR - b.CTR;
    },
  },
  {
    title: "CPC",
    dataIndex: "CPC",
    key: "CPC",
    render: (text) => valueWithUnits(text, "currency"),
    sorter: (a, b) => {
      return a.CPC - b.CPC;
    },
  },
  {
    title: "CR",
    dataIndex: "CR",
    key: "CR",
    render: (text) => valueWithUnits(text, "%"),
    sorter: (a, b) => {
      return a.CR - b.CR;
    },
  },
  {
    title: "CPA",
    dataIndex: "CPA",
    key: "CPA",
    render: (text) => valueWithUnits(text, "currency"),
    sorter: (a, b) => {
      return a.CPA - b.CPA;
    },
  },
];

export const getColumns = (filters) => {
  return columns.filter((column) => {
    return filters.some((filter) => {
      return column.key === filter;
    });
  });
};
