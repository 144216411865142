import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { connect } from "react-redux";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import NavigationTop from "./../Navigation";
import SeeKpi from "./tabs/SeeKpi";
import { push } from "connected-react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Error404 from "../Error404";
import PageTop from "../Top";
import ThinkKpi from "./tabs/ThinkKpi";
import DoKpi from "./tabs/DoKpi";
import Loading from "../../components/Loading";
import { getLastUrlPart } from "./../../util/Helpers";
import { HomeOutlined } from "@ant-design/icons";

import {
  geographyWelcomeData,
  geographyTopMetricsData,
  geographyWowScoresData,
  geographyCityTableMetricsData,
  geographyCityMetricsData,
  setFilters,
  setLoaded,
} from "../../appRedux/actions/Geography";

const navigationItems = [
  { label: "See KPIs", path: "/geography/see" },
  { label: "Think KPIs", path: "/geography/think" },
  { label: "Do KPIs", path: "/geography/do" },
];

const Creative = ({ navigateTo }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInitialMount = useRef(true);

  const [current, setCurrent] = useState(location.pathname);
  const [welcomeData, setWelcomeData] = useState();
  const [lastUrlPart, setLasUrlPart] = useState();

  const loadingWowScores = useSelector(({ geographyData }) => geographyData.wowScoresStats.loading);
  const wowScores = useSelector(({ geographyData }) => geographyData.wowScoresStats.data);
  const loadingWelcome = useSelector(({ geographyData }) => geographyData.welcomeStats.loading);
  const welcomeStats = useSelector(({ geographyData }) => geographyData.welcomeStats.data);
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const filterPeriod = useSelector(({ filtersData }) => filtersData.period.current);
  const filterLineItem = useSelector(({ filtersData }) => filtersData.lineItem.current);
  const filterInsertionOrder = useSelector(({ filtersData }) => filtersData.insertionOrder.current);
  const filterCampaignId = useSelector(({ filtersData }) => filtersData.campaignId.current);
  const filterTimeRange = useSelector(({ filtersData }) => filtersData.timeRange.current);
  const currentFilters = useSelector(({ geographyData }) => geographyData.filters);
  const loaded = useSelector(({ geographyData }) => geographyData.loaded);
  const filterCharts = useSelector(({ geographyData }) => geographyData.filters.charts);

  useEffect(() => {
    setCurrent(location.pathname);
    setLasUrlPart(getLastUrlPart().toUpperCase());
  }, [location]);

  useEffect(() => {
    if (!loaded || (loaded && filterClientDataset && filterClientDataset !== currentFilters.clientDataset)) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (
      loaded &&
      filterClientDataset &&
      filterClientDataset !== currentFilters.clientDataset &&
      currentFilters.clientDataset
    ) {
      getData();
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
    if (!currentFilters.clientDataset && filterClientDataset) {
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
  }, [filterClientDataset]);

  const getData = () => {
    dispatch(geographyWelcomeData());
    dispatch(geographyTopMetricsData());
    dispatch(
      geographyWowScoresData([
        {
          index: "map",
          query: "City",
        },
        {
          index: "city",
          query: "City",
        },
      ])
    );
    dispatch(geographyCityTableMetricsData());
    dispatch(geographyCityMetricsData());
    dispatch(setLoaded());
  };

  useEffect(() => {
    if (loaded && filterCharts) {
      dispatch(geographyCityTableMetricsData());
      dispatch(geographyCityMetricsData());
      dispatch(geographyTopMetricsData());
    }
  }, [filterCharts]);

  useEffect(() => {
    if (loaded && filterLineItem && currentFilters.lineItem !== filterLineItem) {
      dispatch(setFilters({ lineItem: filterLineItem }));
      getData();
    }

    if (loaded && filterInsertionOrder && currentFilters.lineItem !== filterInsertionOrder) {
      dispatch(setFilters({ lineItem: filterInsertionOrder }));
      getData();
    }

    if (loaded && filterTimeRange && currentFilters.timeRange !== filterTimeRange) {
      dispatch(setFilters({ timeRange: filterTimeRange }));
      getData();
    }

    if (loaded && filterCampaignId && currentFilters.campaignId !== filterCampaignId) {
      dispatch(setFilters({ campaignId: filterCampaignId }));
      getData();
    }
  }, [filterLineItem, filterInsertionOrder, filterTimeRange, filterCampaignId]);

  useEffect(() => {
    if (loaded && filterPeriod && currentFilters.period !== filterPeriod) {
      dispatch(setFilters({ period: filterPeriod }));
      dispatch(
        geographyWowScoresData([
          {
            index: "map",
            query: "City",
          },
          {
            index: "city",
            query: "City",
          },
        ])
      );
    }
  }, [filterPeriod]);

  useEffect(() => {
    if (typeof welcomeStats !== "undefined" && welcomeStats.length !== 0) {
      setWelcomeData({
        title: "Welcome!",
        subTitle: "The geography review is of",
        items: [
          {
            icon: <HomeOutlined />,
            title: { singular: "City", plural: "Cities" },
            count: welcomeStats.city,
          },
        ],
      });
    }
  }, [loadingWelcome]);

  return (
    <div>
      <NavigationTop navigationItems={navigationItems} navigateTo={navigateTo} current={current} />

      {!loadingWelcome && !loadingWowScores && welcomeData ? (
        <PageTop
          welcomeData={welcomeData}
          wowScores={{
            data: wowScores,
            mainChart: { part: "map", section: "MAP" },
            asideCharts: [{ part: "city", section: lastUrlPart, name: "City" }],
          }}
          chartAxes={true}
        />
      ) : (
        <Loading card />
      )}
      <Route exact path="/geography">
        <Redirect to="/geography/see" />
      </Route>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={300}>
          <Switch location={location}>
            <Route path="/geography/see" children={() => <SeeKpi />} />
            <Route path="/geography/think" children={() => <ThinkKpi />} />
            <Route path="/geography/do" children={() => <DoKpi />} />
            <Route component={Error404} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(Creative);
