import {
  FETCH_STRATEGY_STATS_BEGIN,
  FETCH_STRATEGY_STATS_SUCCESS,
  FETCH_STRATEGY_STATS_FAILURE,
  FETCH_STRATEGY_TOP_METRICS_BEGIN,
  FETCH_STRATEGY_TOP_METRICS_SUCCESS,
  FETCH_STRATEGY_TOP_METRICS_FAILURE,
  FETCH_STRATEGY_WOW_SCORES_BEGIN,
  FETCH_STRATEGY_WOW_SCORES_SUCCESS,
  FETCH_STRATEGY_WOW_SCORES_FAILURE,
  FETCH_STRATEGY_WOW_SCORES_END,
  FETCH_STRATEGY_ORDER_TABLE_METRICS_BEGIN,
  FETCH_STRATEGY_ORDER_TABLE_METRICS_SUCCESS,
  FETCH_STRATEGY_ORDER_TABLE_METRICS_FAILURE,
  FETCH_STRATEGY_INSERTION_ORDER_BEGIN,
  FETCH_STRATEGY_INSERTION_ORDER_SUCCESS,
  FETCH_STRATEGY_INSERTION_ORDER_FAILURE,
  FETCH_STRATEGY_LINE_ITEM_BEGIN,
  FETCH_STRATEGY_LINE_ITEM_SUCCESS,
  FETCH_STRATEGY_LINE_ITEM_FAILURE,
  STRATEGY_SET_FILTERS,
  STRATEGY_SET_LOADED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loaded: false,
  filters: {
    clientDataset: null,
    period: "Week",
    charts: [],
  },
  welcomeStats: {
    loading: false,
    data: [],
    error: null,
  },
  topMetrics: {
    loading: false,
    data: [],
    error: null,
  },
  wowScoresStats: {
    loading: true,
    data: [],
    error: null,
  },
  orderTableMetrics: {
    loading: true,
    data: [],
    error: null,
  },
  insertionOrderMetrics: {
    loading: true,
    data: [],
    error: null,
  },
  lineItemMetrics: {
    loading: true,
    data: [],
    error: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STRATEGY_SET_LOADED: {
      return {
        ...state,
        loaded: true,
      };
    }
    case STRATEGY_SET_FILTERS: {
      return {
        ...state,
        filters: Object.assign(state.filters, action.payload),
      };
    }
    case FETCH_STRATEGY_LINE_ITEM_BEGIN: {
      return {
        ...state,
        lineItemMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_STRATEGY_LINE_ITEM_SUCCESS: {
      return {
        ...state,
        lineItemMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_STRATEGY_LINE_ITEM_FAILURE: {
      return {
        ...state,
        lineItemMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case FETCH_STRATEGY_INSERTION_ORDER_BEGIN: {
      return {
        ...state,
        insertionOrderMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_STRATEGY_INSERTION_ORDER_SUCCESS: {
      return {
        ...state,
        insertionOrderMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_STRATEGY_INSERTION_ORDER_FAILURE: {
      return {
        ...state,
        insertionOrderMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case FETCH_STRATEGY_STATS_BEGIN: {
      return {
        ...state,
        welcomeStats: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_STRATEGY_STATS_SUCCESS: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_STRATEGY_STATS_FAILURE: {
      return {
        ...state,
        welcomeStats: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_STRATEGY_WOW_SCORES_BEGIN: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: {},
        },
      };
    }
    case FETCH_STRATEGY_WOW_SCORES_SUCCESS: {
      return {
        ...state,
        wowScoresStats: {
          loading: true,
          error: null,
          data: Object.assign(state.wowScoresStats.data, action.payload),
        },
      };
    }

    case FETCH_STRATEGY_WOW_SCORES_FAILURE: {
      return {
        ...state,
        wowScoresStats: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    }

    case FETCH_STRATEGY_WOW_SCORES_END: {
      return {
        ...state,
        wowScoresStats: {
          ...state.wowScoresStats,
          loading: false,
          error: action.payload,
        },
      };
    }

    case FETCH_STRATEGY_TOP_METRICS_BEGIN: {
      return {
        ...state,
        topMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_STRATEGY_TOP_METRICS_SUCCESS: {
      return {
        ...state,
        topMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_STRATEGY_TOP_METRICS_FAILURE: {
      return {
        ...state,
        topMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }

    case FETCH_STRATEGY_ORDER_TABLE_METRICS_BEGIN: {
      return {
        ...state,
        orderTableMetrics: {
          loading: true,
          error: null,
        },
      };
    }
    case FETCH_STRATEGY_ORDER_TABLE_METRICS_SUCCESS: {
      return {
        ...state,
        orderTableMetrics: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    }

    case FETCH_STRATEGY_ORDER_TABLE_METRICS_FAILURE: {
      return {
        ...state,
        orderTableMetrics: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    default:
      return state;
  }
};
