import React from "react";
import Pluralize from "react-pluralize";

const WelComeCard = ({ data }) => {
  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h1 className="gx-mb-3">{data.title}</h1>
      <p className="gx-fs-sm gx-text-uppercase">{data.subTitle}</p>
      <ul className="gx-list-group">
        {data.items
          ? data.items.map((item, index) => {
              return (
                <li key={index}>
                  {item.icon}
                  <span>
                    <Pluralize
                      singular={item.title.singular}
                      plural={item.title.plural ? item.title.plural : false}
                      count={item.count}
                    />
                  </span>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default WelComeCard;
