import React from "react";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setThemeType } from "appRedux/actions/Setting";

import { THEME_TYPE_DARK, THEME_TYPE_LITE } from "../../constants/ThemeSetting";

const ThemeSwitcher = () => {
  const dispatch = useDispatch();
  const themeType = useSelector(({ settings }) => settings.themeType);

  const onThemeTypeChange = (value) => {
    dispatch(setThemeType(value ? "THEME_TYPE_LITE" : "THEME_TYPE_DARK"));
  };

  const themeChange = () => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      let link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = "/css/dark_theme.css";
      link.className = "style_dark_theme";
      document.body.appendChild(link);
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      const children = document.getElementsByClassName("style_dark_theme");
      if (children.length > 1) {
        for (let index = 0; index < children.length; index++) {
          if (index < children.length) {
            const child = children[index];
            child.parentNode.removeChild(child);
          }
        }
      }
    }
  };

  return (
    <>
      {themeChange()}
      <Switch
        onChange={onThemeTypeChange}
        checkedChildren="Light"
        unCheckedChildren="Dark"
        defaultChecked
      />
    </>
  );
};

export default ThemeSwitcher;
