import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { userSignUp } from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import logo from "../assets/images/logo-dark.png";
import logoWhite from "./../assets/images/logo-white.png";

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    // dispatch(userSignUp(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push("/");
    }
  });

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} alt="" className="main-logo" />
          <img src={logoWhite} alt="" className="main-logo main-logo-white" />
        </div>
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-form">
            <div>
              <h1>
                <IntlMessages id="app.userAuth.signUp" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
              <Form
                form={form}
                onFinish={handleSubmit}
                className="gx-signup-form gx-form-row0"
                initialValues={{ remember: true }}
              >
                <Form.Item name="name" rules={[{ required: true, message: "Please input your username!" }]}>
                  <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[{ required: true, type: "email", message: "The input is not valid E-mail!" }]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
                  <Input type="password" placeholder="Password" />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                    <IntlMessages id="appModule.iAccept" />
                  </Checkbox>
                  <Link to="/terms-and-conditions" className="gx-link gx-signup-form-forgot">
                    <IntlMessages id="appModule.termAndCondition" />
                  </Link>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" className="gx-mb-0 gx-px-5 btn-primary-alter" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="gx-app-login-gradient">
            <div>
              <h1>Already registered?</h1>
              <Link to="/signin" className="ant-btn  gx-mb-0 gx-px-5 gx-mt-3 btn-alter">
                Sign in
              </Link>
            </div>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

// const WrappedSignUpForm = Form.create()(SignUp);

export default SignUp;
