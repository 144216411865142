import {
  FETCH_OVERVIEW_STATS_BEGIN,
  FETCH_OVERVIEW_STATS_SUCCESS,
  FETCH_OVERVIEW_STATS_FAILURE,
  FETCH_OVERVIEW_DIMENSION_BEGIN,
  FETCH_OVERVIEW_DIMENSION_SUCCESS,
  FETCH_OVERVIEW_DIMENSION_FAILURE,
  FETCH_OVERVIEW_MAP_SCORE_BEGIN,
  FETCH_OVERVIEW_MAP_SCORE_SUCCESS,
  FETCH_OVERVIEW_MAP_SCORE_FAILURE,
  FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_BEGIN,
  FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_SUCCESS,
  FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_FAILURE,
  FETCH_OVERVIEW_TOP_CHART_BEGIN,
  FETCH_OVERVIEW_TOP_CHART_SUCCESS,
  FETCH_OVERVIEW_TOP_CHART_FAILURE,
  FETCH_OVERVIEW_WOW_SCORES_BEGIN,
  FETCH_OVERVIEW_WOW_SCORES_SUCCESS,
  FETCH_OVERVIEW_WOW_SCORES_FAILURE,
  OVERVIEW_SET_FILTERS,
  OVERVIEW_SET_LOADED,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { wowTransformData } from "./../../util/Helpers";
import { applyGlobalFilters } from "./../../util/Filters";

export const setLoaded = () => {
  return {
    type: OVERVIEW_SET_LOADED,
  };
};

export const setFilters = (data) => {
  return {
    type: OVERVIEW_SET_FILTERS,
    payload: data,
  };
};

export const overviewWelcomeData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_STATS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/overview/counts", "overviewData", {
          campaignId: true,
          lineItem: true,
          insertionOrder: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_OVERVIEW_STATS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OVERVIEW_STATS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const overviewTopChartData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_TOP_CHART_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/overview/total-spend/insertion-order", "overviewData", {
          period: true,
          lineItem: true,
          insertionOrder: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_OVERVIEW_TOP_CHART_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OVERVIEW_TOP_CHART_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const overviewWowScoresData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_WOW_SCORES_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/overview/scores", "overviewData", {
          period: true,
          lineItem: true,
          insertionOrder: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_OVERVIEW_WOW_SCORES_SUCCESS, payload: { map: wowTransformData(data) } });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OVERVIEW_WOW_SCORES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const overviewDimensionData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_DIMENSION_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/overview/dimension/scores", "overviewData", {
          campaignId: true,
          lineItem: true,
          insertionOrder: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_OVERVIEW_DIMENSION_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OVERVIEW_DIMENSION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const overviewMapScoreData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_MAP_SCORE_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/overview/map-score/timeperiods", "overviewData", {
          lineItem: true,
          insertionOrder: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_OVERVIEW_MAP_SCORE_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OVERVIEW_MAP_SCORE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const overviewDimensionsMapScoreData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/overview/dimension/map-score", "overviewData", {
          campaignId: true,
          lineItem: true,
          insertionOrder: true,
        })
      )
      .then(({ data }) => {
        dispatch({
          type: FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_SUCCESS,
          payload: data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_OVERVIEW_DIMENSIONS_MAP_SCORE_FAILURE,
          payload: error.message,
        });
      });
  };
};
