import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Route, Redirect, useLocation, Switch } from "react-router-dom";
import NavigationTop from "./../Navigation";
import SeeKpi from "./tabs/SeeKpi";
import DoKpi from "./tabs/DoKpi";
import ThinkKpi from "./tabs/ThinkKpi";
import { push } from "connected-react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PageTop from "../Top";
import Loading from "../../components/Loading";
import { getLastUrlPart } from "./../../util/Helpers";
import { OrderedListOutlined, StockOutlined } from "@ant-design/icons";

import {
  strategyWelcomeData,
  strategyTopMetricsData,
  strategyWowScoresData,
  orderTableMetricsData,
  insertionOrderMetricsData,
  lineItemMetricsData,
  setFilters,
  setLoaded,
} from "../../appRedux/actions/Strategy";

const navigationItems = [
  { label: "See KPIs", path: "/strategy/see" },
  { label: "Think KPIs", path: "/strategy/think" },
  { label: "Do KPIs", path: "/strategy/do" },
];

const Strategy = ({ navigateTo }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [current, setCurrent] = useState(location.pathname);
  const [welcomeData, setWelcomeData] = useState();
  const [lastUrlPart, setLasUrlPart] = useState();

  const loadingWelcome = useSelector(({ strategyData }) => strategyData.welcomeStats.loading);
  const welcomeStats = useSelector(({ strategyData }) => strategyData.welcomeStats.data);
  const loadingWowScores = useSelector(({ strategyData }) => strategyData.wowScoresStats.loading);
  const wowScores = useSelector(({ strategyData }) => strategyData.wowScoresStats.data);
  const filterClientDataset = useSelector(({ filtersData }) => filtersData.clientDataset.current);
  const filterPeriod = useSelector(({ filtersData }) => filtersData.period.current);
  const filterLineItem = useSelector(({ filtersData }) => filtersData.lineItem.current);
  const filterInsertionOrder = useSelector(({ filtersData }) => filtersData.insertionOrder.current);
  const filterCampaignId = useSelector(({ filtersData }) => filtersData.campaignId.current);
  const filterTimeRange = useSelector(({ filtersData }) => filtersData.timeRange.current);
  const currentFilters = useSelector(({ strategyData }) => strategyData.filters);
  const loaded = useSelector(({ strategyData }) => strategyData.loaded);
  const filterCharts = useSelector(({ strategyData }) => strategyData.filters.charts);

  useEffect(() => {
    setCurrent(location.pathname);
    setLasUrlPart(getLastUrlPart().toUpperCase());
  }, [location]);

  useEffect(() => {
    if (!loaded || (loaded && filterClientDataset && filterClientDataset !== currentFilters.clientDataset)) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (
      loaded &&
      filterClientDataset &&
      filterClientDataset !== currentFilters.clientDataset &&
      currentFilters.clientDataset
    ) {
      getData();
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
    if (!currentFilters.clientDataset && filterClientDataset) {
      dispatch(setFilters({ clientDataset: filterClientDataset }));
    }
  }, [filterClientDataset]);

  const getData = () => {
    dispatch(strategyWelcomeData());
    dispatch(strategyTopMetricsData());
    dispatch(
      strategyWowScoresData([
        {
          index: "map",
          query: "InsertionOrder,LineItem",
        },
        {
          index: "insertionOrder",
          query: "InsertionOrder",
        },
        {
          index: "lineItem",
          query: "LineItem",
        },
      ])
    );

    dispatch(orderTableMetricsData());
    dispatch(insertionOrderMetricsData());
    dispatch(lineItemMetricsData());
    dispatch(setLoaded());
  };

  useEffect(() => {
    if (loaded && filterCharts) {
      dispatch(orderTableMetricsData());
      dispatch(insertionOrderMetricsData());
      dispatch(lineItemMetricsData());
      dispatch(strategyTopMetricsData());
    }
  }, [filterCharts]);

  useEffect(() => {
    if (loaded && filterLineItem && currentFilters.lineItem !== filterLineItem) {
      dispatch(setFilters({ lineItem: filterLineItem }));
      getData();
    }

    if (loaded && filterInsertionOrder && currentFilters.lineItem !== filterInsertionOrder) {
      dispatch(setFilters({ lineItem: filterInsertionOrder }));
      getData();
    }

    if (loaded && filterTimeRange && currentFilters.timeRange !== filterTimeRange) {
      dispatch(setFilters({ timeRange: filterTimeRange }));
      getData();
    }

    if (loaded && filterCampaignId && currentFilters.campaignId !== filterCampaignId) {
      dispatch(setFilters({ campaignId: filterCampaignId }));
      getData();
    }
  }, [filterLineItem, filterInsertionOrder, filterTimeRange, filterCampaignId]);

  useEffect(() => {
    if (loaded && filterPeriod && currentFilters.period !== filterPeriod) {
      dispatch(setFilters({ period: filterPeriod }));
      dispatch(
        strategyWowScoresData([
          {
            index: "map",
            query: "InsertionOrder,LineItem",
          },
          {
            index: "insertionOrder",
            query: "InsertionOrder",
          },
          {
            index: "lineItem",
            query: "LineItem",
          },
        ])
      );
    }
  }, [filterPeriod]);

  useEffect(() => {
    if (typeof welcomeStats !== "undefined" && welcomeStats.length !== 0) {
      setWelcomeData({
        title: "Welcome!",
        subTitle: "The strategy review is of",
        items: [
          {
            icon: <OrderedListOutlined />,
            title: { singular: "Insertion Order" },
            count: welcomeStats.insertion_order,
          },
          {
            icon: <StockOutlined />,
            title: { singular: "Line Item" },
            count: welcomeStats.line_item,
          },
        ],
      });
    }
  }, [loadingWelcome]);

  return (
    <div>
      <NavigationTop navigationItems={navigationItems} navigateTo={navigateTo} current={current} />

      {!loadingWelcome && !loadingWowScores && welcomeData ? (
        <PageTop
          welcomeData={welcomeData}
          wowScores={{
            data: wowScores,
            mainChart: { part: "map", section: "MAP" },
            asideCharts: [
              { part: "insertionOrder", section: lastUrlPart, name: "Insertion Order" },
              { part: "lineItem", section: lastUrlPart, name: "Line Item" },
            ],
          }}
          chartAxes={true}
        />
      ) : (
        <Loading card />
      )}

      <Route exact path="/strategy">
        <Redirect to="/strategy/see" />
      </Route>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={300}>
          <Switch location={location}>
            <Route path="/strategy/see" children={() => <SeeKpi />} />
            <Route path="/strategy/think" children={() => <ThinkKpi />} />
            <Route path="/strategy/do" children={() => <DoKpi />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(Strategy);
