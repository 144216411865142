import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "antd";
import Table from "../../Table";
import Card from "../../../components/CardBox";
import Loading from "../../../components/Loading";
import ZoomableBubbleChart from "./../../ZoomableBubbleChart";
import PageTopSummary from "./../../TopCharts";
import { creativeDeviceTableMetricsData, setFilters } from "../../../appRedux/actions/Creative";
import { prepareTableData, valueWithCurrency } from "../../../util/Helpers";
import { getColumns } from "../../../util/Table";

const SeeKpi = () => {
  const dispatch = useDispatch();
  const [summaryData, setSummaryData] = useState([]);
  const [tableColumns, setTableColumns] = useState();

  const loadingDeviceTable = useSelector(({ creativeData }) => creativeData.deviceTableMetrics.loading);
  const deviceTableMetrics = useSelector(({ creativeData }) => creativeData.deviceTableMetrics.data);
  const loadingTopMetrics = useSelector(({ creativeData }) => creativeData.topMetrics.loading);
  const topMetrics = useSelector(({ creativeData }) => creativeData.topMetrics.data);

  const loadingDeviceTypeMetrics = useSelector(({ creativeData }) => creativeData.deviceTypeMetrics.loading);
  const deviceTypeMetrics = useSelector(({ creativeData }) => creativeData.deviceTypeMetrics.data);

  const loadingCreativeSizeMetrics = useSelector(({ creativeData }) => creativeData.creativeSizeMetrics.loading);
  const creativeSizeMetrics = useSelector(({ creativeData }) => creativeData.creativeSizeMetrics.data);

  const loadingCreativeTypeMetrics = useSelector(({ creativeData }) => creativeData.creativeTypeMetrics.loading);
  const creativeTypeMetrics = useSelector(({ creativeData }) => creativeData.creativeTypeMetrics.data);

  const currentFilters = useSelector(({ creativeData }) => creativeData.filters);

  useEffect(() => {
    if (typeof topMetrics !== "undefined" && topMetrics.length !== 0) {
      setSummaryData([
        {
          title: "TOTAL SPEND",
          value: topMetrics.total_spend,
          units: "currency",
        },
        {
          title: "IMPRESSIONS",
          value: topMetrics.impressions_sum,
        },
        {
          title: "VIEWABLE IMPRESSIONS",
          value: topMetrics.viewable_impressions_sum,
        },
        {
          title: "CLICKS",
          value: topMetrics.clicks_sum,
        },
        {
          title: "CPM",
          value: topMetrics.CPM,
          units: "currency",
        },
        {
          title: "vCPM",
          value: topMetrics.vCPM,
          units: "currency",
        },
      ]);
    }
  }, [loadingTopMetrics]);

  useEffect(() => {
    if (typeof deviceTableMetrics === "undefined" || deviceTableMetrics.length === 0) {
      dispatch(creativeDeviceTableMetricsData());
    }

    setTableColumns(getColumns(["Device", "TotalSpend", "Impressions", "ViewableImpressions", "CPM", "vCPM"]));
  }, []);

  return (
    <div>
      {!loadingTopMetrics ? <PageTopSummary summaryData={summaryData} /> : <Loading card />}
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card">
            {!loadingDeviceTable ? (
              <Table
                columns={tableColumns}
                data={prepareTableData(deviceTableMetrics, "Device", "CreativeSize")}
                options={{
                  filters: currentFilters,
                  chart: { key: ["Device", "CreativeSize"], filter: ["deviceType", "creativeSize"] },
                }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="Device Type">
            {!loadingDeviceTypeMetrics ? (
              <ZoomableBubbleChart
                data={deviceTypeMetrics}
                axis={{
                  x: { label: "CPM", units: "currency" },
                  y: { label: "vCPM", units: "currency" },
                  title: "Device",
                }}
                options={{ filters: currentFilters, chart: { key: "Device", filter: "deviceType" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="Creative Type">
            {!loadingCreativeTypeMetrics ? (
              <ZoomableBubbleChart
                data={creativeTypeMetrics}
                axis={{
                  x: { label: "CPM", units: "currency" },
                  y: { label: "vCPM", units: "currency" },
                  title: "CreativeType",
                }}
                options={{ filters: currentFilters, chart: { key: "CreativeType", filter: "creativeType" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="gx-col-full">
          <Card className="gx-card" heading="Creative Size">
            {!loadingCreativeSizeMetrics ? (
              <ZoomableBubbleChart
                data={creativeSizeMetrics}
                axis={{
                  x: { label: "CPM", units: "currency" },
                  y: { label: "vCPM", units: "currency" },
                  title: "CreativeSize",
                }}
                options={{ filters: currentFilters, chart: { key: "CreativeSize", filter: "creativeSize" } }}
                setFilters={setFilters}
              />
            ) : (
              <Loading />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SeeKpi;
