import React, { useState, useEffect } from "react";
import { Select, Row, Col, Input, Button, Card, Tooltip, Radio, AutoComplete, Form, Space, DatePicker } from "antd";
import { FacebookFilled, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import AdvNamingGenerator from "./AdvNamingGenerator";
import CampaignNamingGenerator from "./CampaignNamingGenerator";
import InsertionNamingGenerator from "./InsertionNamingGenerator";
import LineNamingGenerator from "./LineNamingGenerator";

const { Option } = Select;

const goals = ["GoalCTR", "GoalCPM", "GoalCPC", "GoalVR%", "GoalCPA", "GoalCPI", "GoalROI", "GoalRev"];

const environments = ["Desktop", "Mobile", "Tablet", "TVConnect", "Youtube"];
const css = [
  "120x600",
  "160x600",
  "320x50",
  "320x100",
  "300x50",
  "300x100",
  "336x250",
  "300x250",
  "336x336",
  "468x60",
  "728x90",
  "970x250",
  "1800x1000",
];
const searchKeywords = ["Broad", "Exact", "Phrase", "Modified Broad"];
const audienceTypes = ["Affinity", "Interest", "In Market", "Behavioral", "3rdPD", "1stPD", "CRM", "Intent"];
const recencies = [
  "<1h",
  "<2h",
  "<6h",
  "<12h",
  "<18h",
  "<1d",
  "<2d",
  "<3d",
  "<4d",
  "<5d",
  "<6d",
  "<7d",
  "<10d",
  "<15d",
  "<30d",
  "<45d",
  "<60d",
  "<90d",
];
const creativeFormatTypes = [
  "Std",
  "Prem",
  "Skin",
  "Native",
  "Pre-Roll",
  "InStream",
  "Podcast",
  "TrueView",
  "Bumper",
  "SequencialAds",
];

const NameGenerator = () => {
  const [canGenerate, setCanGenerate] = useState({
    adv: false,
    campaign: false,
    insertion: false,
    line: false,
  });

  const unlockGenerator = (name, value) => {
    let temp = { ...canGenerate };
    temp[name] = value;
    setCanGenerate(temp);
  };

  const sanitizeName = (name) => {
    return name.replace(/\s+/g, "_").toLowerCase();
  };

  const copyToClipboard = (event, text) => {
    let el = event.currentTarget.querySelector("span");
    let tempText = el.innerHTML;
    navigator.clipboard.writeText(text);
    el.innerHTML = "Copied!";
    setTimeout(() => {
      el.innerHTML = tempText;
    }, 2000);
  };

  return (
    <>
      <Form>
        <Row>
          <Col xs={24}>
            <AdvNamingGenerator
              copyToClipboard={copyToClipboard}
              sanitizeName={sanitizeName}
              unlockGenerator={unlockGenerator}
              canGenerate={canGenerate}
            />
            <CampaignNamingGenerator
              copyToClipboard={copyToClipboard}
              sanitizeName={sanitizeName}
              unlockGenerator={unlockGenerator}
              canGenerate={canGenerate}
            />
            <InsertionNamingGenerator
              copyToClipboard={copyToClipboard}
              sanitizeName={sanitizeName}
              unlockGenerator={unlockGenerator}
              canGenerate={canGenerate}
            />
            <LineNamingGenerator
              copyToClipboard={copyToClipboard}
              sanitizeName={sanitizeName}
              unlockGenerator={unlockGenerator}
              canGenerate={canGenerate}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NameGenerator;
