import React, { useEffect } from "react";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "util/Auxiliary";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "appRedux/actions/Common";
import { Alert } from "antd";

const InfoView = () => {
  const dispatch = useDispatch();

  const error = useSelector(({ commonData }) => commonData.error);

  const loading = useSelector(({ commonData }) => commonData.loading);

  const message = useSelector(({ commonData }) => commonData.message);

  const displayMessage = message;

  useEffect(() => {
    if (error || message) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 5000);
    }
  }, [error, message, dispatch]);

  return error ? <Alert message={error || displayMessage} type="error" /> : null;
};

export default InfoView;
