import {
  FETCH_GEOGRAPHY_STATS_BEGIN,
  FETCH_GEOGRAPHY_STATS_SUCCESS,
  FETCH_GEOGRAPHY_STATS_FAILURE,
  FETCH_GEOGRAPHY_TOP_METRICS_BEGIN,
  FETCH_GEOGRAPHY_TOP_METRICS_SUCCESS,
  FETCH_GEOGRAPHY_TOP_METRICS_FAILURE,
  FETCH_GEOGRAPHY_WOW_SCORES_BEGIN,
  FETCH_GEOGRAPHY_WOW_SCORES_SUCCESS,
  FETCH_GEOGRAPHY_WOW_SCORES_FAILURE,
  FETCH_GEOGRAPHY_WOW_SCORES_END,
  FETCH_GEOGRAPHY_CITY_TABLE_METRICS_BEGIN,
  FETCH_GEOGRAPHY_CITY_TABLE_METRICS_SUCCESS,
  FETCH_GEOGRAPHY_CITY_TABLE_METRICS_FAILURE,
  FETCH_GEOGRAPHY_CITY_METRICS_BEGIN,
  FETCH_GEOGRAPHY_CITY_METRICS_SUCCESS,
  FETCH_GEOGRAPHY_CITY_METRICS_FAILURE,
  GEOGRAPHY_SET_FILTERS,
  GEOGRAPHY_SET_LOADED,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { wowTransformData } from "./../../util/Helpers";
import { applyGlobalFilters } from "./../../util/Filters";

export const setFilters = (data) => {
  return {
    type: GEOGRAPHY_SET_FILTERS,
    payload: data,
  };
};

export const setLoaded = () => {
  return {
    type: GEOGRAPHY_SET_LOADED,
  };
};

export const geographyWelcomeData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_GEOGRAPHY_STATS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/geography/counts", "geographyData", {
          campaignId: true,
          lineItem: true,
          insertionOrder: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_GEOGRAPHY_STATS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_GEOGRAPHY_STATS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const geographyTopMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_GEOGRAPHY_TOP_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/creative/metrics/top", "geographyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({
          type: FETCH_GEOGRAPHY_TOP_METRICS_SUCCESS,
          payload: data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_GEOGRAPHY_TOP_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const geographyWowScoresData = (arr) => {
  return (dispatch) => {
    dispatch({ type: FETCH_GEOGRAPHY_WOW_SCORES_BEGIN });
    let index = 0;

    const fullArray = arr.map((item) => {
      let queryString = applyGlobalFilters("api/geography/scores", "geographyData", {
        period: true,
        selected: item.query,
        lineItem: true,
        insertionOrder: true,
        campaignId: true,
      });
      return {
        ...item,
        url: queryString,
      };
    });

    for (let i = 0; i < fullArray.length; i++) {
      axios
        .get(fullArray[i].url)
        .then(({ data }) => {
          index++;

          dispatch({
            type: FETCH_GEOGRAPHY_WOW_SCORES_SUCCESS,
            payload: { [fullArray[i].index]: wowTransformData(data) },
          });
          if (index >= fullArray.length) {
            dispatch({
              type: FETCH_GEOGRAPHY_WOW_SCORES_END,
            });
          }
        })
        .catch(function (error) {
          dispatch({
            type: FETCH_GEOGRAPHY_WOW_SCORES_FAILURE,
            payload: error.message,
          });
        });
    }
  };
};

export const geographyCityTableMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_GEOGRAPHY_CITY_TABLE_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/geography/metrics/city/table", "geographyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_GEOGRAPHY_CITY_TABLE_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_GEOGRAPHY_CITY_TABLE_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const geographyCityMetricsData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_GEOGRAPHY_CITY_METRICS_BEGIN });

    axios
      .get(
        applyGlobalFilters("api/geography/metrics/city", "geographyData", {
          lineItem: true,
          insertionOrder: true,
          timeRange: true,
          charts: true,
          campaignId: true,
        })
      )
      .then(({ data }) => {
        dispatch({ type: FETCH_GEOGRAPHY_CITY_METRICS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_GEOGRAPHY_CITY_METRICS_FAILURE,
          payload: error.message,
        });
      });
  };
};
