import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { getUser, hasUserRole, userSignOut } from "../../appRedux/actions/Auth";
import { useHistory } from "react-router-dom";
import {ROLE_ADMIN} from "../../constants/Roles";

const UserProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => history.push("/user/change-password")}>Change password</li>
      <li onClick={() => history.push("/user/oauth")} hidden={!hasUserRole(ROLE_ADMIN)}>Grant access</li>
      <li onClick={() => history.push("/user/add-advertisers")} hidden={!hasUserRole(ROLE_ADMIN)}>Add Advertisers</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar
          src={process.env.REACT_APP_API_URL + getUser().photo}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {getUser().firstName} {getUser().lastName}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
