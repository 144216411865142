import {
  FETCH_ADVERTISERS_BEGIN,
  FETCH_ADVERTISERS_FAILURE,
  FETCH_ADVERTISERS_SUCCESS,
  FETCH_ADVERTISERS_CREATE_SUCCESS,
  FETCH_ORGANIZATION_PARTNERS_BEGIN,
  FETCH_ORGANIZATION_PARTNERS_FAILURE,
  FETCH_ORGANIZATION_PARTNERS_SUCCESS,
} from "../../constants/ActionTypes";

import axios from "util/Api";

export const advertisers = (organizationId, partnerId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ADVERTISERS_BEGIN });

    axios
      .get("api/client/advertisers/" + organizationId + "/" + partnerId)
      .then(({ data }) => {
        dispatch({ type: FETCH_ADVERTISERS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: FETCH_ADVERTISERS_FAILURE,
            payload: error.response.data.errors.message,
          });
        } else if (error.request) {
          dispatch({
            type: FETCH_ADVERTISERS_FAILURE,
            payload: error.message,
          });
        } else {
          dispatch({
            type: FETCH_ADVERTISERS_FAILURE,
            payload: error.message,
          });
        }
      });
  };
};

export const getOrganizationDataset = (organizationId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ORGANIZATION_PARTNERS_BEGIN });

    axios
      .get("api/client/advertisers/" + organizationId)
      .then(({ data }) => {
        dispatch({ type: FETCH_ORGANIZATION_PARTNERS_SUCCESS, payload: data });
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: FETCH_ORGANIZATION_PARTNERS_FAILURE,
            payload: error.response.data.ttile,
          });
        } else if (error.request) {
          dispatch({
            type: FETCH_ORGANIZATION_PARTNERS_FAILURE,
            payload: error.message,
          });
        } else {
          dispatch({
            type: FETCH_ORGANIZATION_PARTNERS_FAILURE,
            payload: error.message,
          });
        }
      });
  };
};

export const createAdvertiserDataset = (organizationId, partnerId, advertisers) => {
  return (dispatch) => {
    axios
      .post("api/client/dataset/create/" + organizationId + "/" + partnerId, advertisers)
      .then(({ data }) => {
        dispatch({ type: FETCH_ADVERTISERS_CREATE_SUCCESS });
      })
      .catch(function (error) {});
  };
};
