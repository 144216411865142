import React from "react";
import { Col, Row } from "antd";
import Card from "../../components/CardBox";

const TermsContent = () => {
  return (
    <Row className="gx-mt-4">
      <Col xl={24} xs={2} className="gx-col-full">
        <Card className="gx-card" heading="TERMS AND CONDITIONS OF SOFTWARE SUPPLY">
          <div className="adocs-section-content ng-binding content">
            <p>
              MAPLATFORM LTD incorporated and registered in England and Wales with company number{" "}
              <strong>12526008</strong> whose registered office is at MAPlatform Ltd. 48-49 Princes Place, Notting Hill,
              London W11 4QA (Supplier). BACKGROUND
            </p>
            <p>
              The Supplier has developed certain software applications and platforms which it makes available to
              subscribers via the internet on a subscription basis for the purpose of auditing and/or managing a
              Customer’s Digital marketing spend.
            </p>
            <p>
              The Supplier has agreed to provide and the Customer has agreed to take and pay for the Supplier’s service
              subject to the terms and conditions of this agreement.
            </p>
            <ol>
              <li>
                DEFINITIONS
                <div>
                  <p>In this agreement the following expressions shall have the following meanings:</p>
                  <p>
                    <strong>Authorised Account:</strong> the Customer’s Media Buying Platform(s) to which a User
                    Subscription for the provision of the Service is “linked”.
                  </p>
                  <p>
                    <strong>Authorised Users:</strong> those employees, agents and independent contractors of the
                    Customer who are authorised by the Customer to use the Services and the Documentation.
                  </p>
                  <p>
                    <strong>Confidential Information:</strong> information that is proprietary or confidential and is
                    either clearly labelled as such or identified as Confidential Information in clause 11.
                  </p>
                  <p>
                    <strong>Customer Data:</strong> the data inputted by the Customer, Authorised Users, or the Supplier
                    on the Customer’s behalf for the purpose of using the Services or facilitating the Customer’s use of
                    the Services.
                  </p>
                  <p>
                    <strong>Customer Personal Data:</strong> any personal data provided by, or on behalf of, the
                    Customer to the Supplier for the purpose of using the Services or facilitating the Customer’s use of
                    the Services.
                  </p>
                  <p>
                    <strong>Data Protection Legislation:</strong> the General Data Protection Regulation (EU) 2016/679)
                    (GDPR) and any national implementing laws, regulations and secondary legislation, as amended or
                    updated from time to time, in the UK, as well as any successor legislation to the GDPR and the Data
                    Protection Act 2018.
                  </p>
                  <p>
                    <strong>
                      Controller, data subject, personal data, personal data breach, processor, process and supervisory
                      authority:
                    </strong>{" "}
                    shall have the meanings given to them in the Data Protection Legislation.
                  </p>
                  <p>
                    <strong>Data Processing Schedule:</strong> the data processing schedule contained in Appendix 1 to
                    this agreement.
                  </p>
                  <p>
                    <strong>Documentation:</strong> the document(s) made available to the Customer by the Supplier from
                    time to time which sets out a description of the Services and the user instructions for the
                    Services.
                  </p>
                  <p>
                    <strong>Effective Date:</strong> the date of this agreement.
                  </p>
                  <p>
                    <strong>Google DV360:</strong> the online advertising service provided by Google.
                  </p>
                  <p>
                    <strong>Google DV360 Account(s):</strong> the Customer’s registered account(s) with Google DV360 and
                    all Customer Data registered with such account(s).
                  </p>
                  <p>
                    <strong>Initial Subscription Term:</strong> the initial term of this agreement, commencing on
                    expiration of the Trial Period and as set out in this page.
                  </p>
                  <p>
                    <strong>Renewal Period:</strong> the renewal period, as described in clause 13.3.
                  </p>
                  <p>
                    <strong>Services:</strong> the subscription services provided by the Supplier to the Customer via
                    https://app.squared.io or any other website notified to the Customer by the Supplier from time to
                    time, as more particularly described in the Documentation.
                  </p>
                  <p>
                    <strong>Software:</strong> the online software applications provided by the Supplier as part of the
                    Services.
                  </p>
                  <p>
                    <strong>Subscription Fees:</strong> the subscription fees payable by the Customer to the Supplier
                    for the User Subscriptions, as set out here.
                  </p>
                  <p>
                    <strong>Subscription Term:</strong> has the meaning given in clause 13.3 (being the Initial
                    Subscription Term together with any subsequent Renewal Periods).
                  </p>
                  <p>
                    <strong>Supplier:</strong> MAPlatform Ltd incorporated and registered in England and Wales with
                    company number 12526008 whose registered office is at 48 – 49 Princes Place, Notting Hill, London,
                    W11 4QA.
                  </p>
                  <p>
                    <strong>Trial Period:</strong> 14 days unless otherwise agreed.
                  </p>
                  <p>
                    <strong> User Subscriptions:</strong> the user subscriptions purchased by the Customer pursuant to
                    clause 9.1 which entitle Authorised Users to access and use the Services and the Documentation in
                    respect of the Authorised Account(s) in accordance with this agreement.
                  </p>
                </div>
              </li>
              <li>
                TRIAL PERIOD
                <ol>
                  <li>
                    For the duration of the Trial Period the terms of this clause 2 and clause 8 of this agreement shall
                    apply.
                  </li>
                  <li>
                    For the duration of the Trial Period:
                    <ul>
                      <li>
                        the Customer grants a non-exclusive, non-transferable right to permit the Authorised Users to
                        use the Services and the Documentation on the Authorised Accounts for the Customer’s internal
                        business operations free of charge;
                      </li>
                      <li>
                        this agreement may be terminated immediately by the Supplier giving written notice if the
                        Customer is in breach of any of its obligations contained in clause 8;
                      </li>
                      <li>this agreement may be terminated by the Customer upon 7 (seven) days’ written notice; and</li>
                      <li>
                        in consideration of the Supplier’s provision of a free of charge Service, save for death and
                        personal injury caused by the Supplier’s negligence, the Supplier shall have no liability of any
                        kind in any circumstances whatever to the Customer in respect of the Software, Documents or the
                        Services provided pursuant to this agreement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Upon expiration of the Trial Period, unless this agreement has been cancelled pursuant to clause
                    2.2(b) or 2.2(c), the remainder of the terms of this agreement shall automatically apply and the
                    applicable User Subscription Fees shall apply.
                  </li>
                </ol>
              </li>
              <li>
                USER SUBSCRIPTIONS AND AUTHORISED ACCOUNTS
                <ol>
                  <li>
                    The Supplier hereby grants to the Customer a non-exclusive, non-transferable right to permit the
                    Authorised Users to use the Services and the Documentation during the Subscription Term on the
                    Authorised Account(s) solely for the Customer’s internal business operations.
                  </li>
                  <li>
                    If the Supplier ascertains that any Service provided by the Software has been used on a Google DV360
                    Account that is not an Authorised Account under this agreement, then:
                    <ul>
                      <li>
                        without prejudice to the Supplier’s other rights, the Customer shall promptly disable the
                        Software and the Supplier shall not be obliged to continue providing the Services to the
                        Customer; and
                      </li>
                      <li>
                        without prejudice to the Supplier’s other rights, the Customer shall pay to the Supplier an
                        amount equal to such underpayment as calculated in accordance with the Subscription Fees within
                        10 (ten) days of the date of the Supplier notifying the Customer of such underpayment in
                        writing.
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Customer shall not access, store, distribute or transmit any material during the course of its
                    use of the Services that are unlawful, harmful, threatening, defamatory, obscene, infringing,
                    harassing or racially or ethnically offensive; facilitate illegal activity; depict sexually explicit
                    images; promote unlawful violence; could be deemed to be discriminatory; or are done in a manner
                    that is otherwise illegal or causes damage or injury to any person or property, and the Supplier
                    reserves the right, without liability or prejudice to its other rights to the Customer, to disable
                    the Customer’s access to any material that breaches the provisions of this clause.
                  </li>
                  <li>
                    The Customer shall not:
                    <ul>
                      <li>
                        except to the extent expressly permitted under this agreement and as may be allowed by any
                        applicable law (which is incapable of exclusion by agreement between the parties):
                        <ul>
                          <li type="i">
                            attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish,
                            download, display, transmit, or distribute all or any portion of the Software and/or
                            Documentation (as applicable) in any form or media or by any means (other than screenshots
                            of reports which the Customer shall be entitled to take and share with its clients provided
                            that the logo and other branding of the Supplier is clearly visible in such screenshot); or
                          </li>
                          <li type="i">
                            attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to
                            human-perceivable form all or any part of the Software; or
                          </li>
                        </ul>
                      </li>
                      <li>
                        access all or any part of the Services and Documentation in order to build a product or service
                        which competes with the Services and/or the Documentation; or
                      </li>
                      <li>
                        license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise
                        commercially exploit, or otherwise make the Services and/or Documentation available to any third
                        party except the Authorised Users.
                      </li>
                    </ul>
                  </li>
                  <li>
                    The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of,
                    the Services and/or the Documentation and, in the event of any such unauthorised access or use,
                    shall promptly notify the Supplier.
                  </li>
                </ol>
              </li>
              <li>
                SERVICES
                <ol>
                  <li>
                    The Supplier shall, during the Subscription Term, provide the Services and make available the
                    Documentation to the Customer on and subject to the terms of this agreement.
                  </li>
                  <li>
                    The Supplier will, as part of the Services and at no additional cost to the Customer, provide the
                    Customer with email support and respond to email queries within one working day. The Customer may
                    purchase enhanced support services separately at the Supplier’s then current rates.
                  </li>
                </ol>
              </li>
              <li>
                SOFTWARE ACCESS TO GOOGLE ADS ACCOUNT
                <ol>
                  <li>
                    The Customer grants permission for the Software to access and control the Authorised Account(s) (if
                    any), including access to all Customer Data registered with said account(s), in order to provide the
                    Services, pursuant to the Customer Obligations as set out in clause 8.
                  </li>
                  <li>
                    The Customer agrees and acknowledges that if the Customer restricts access to its Authorised
                    Account(s) in any way at any time, the Software may not function in accordance with this agreement
                    and the delivery of the Services may be impaired.
                  </li>
                </ol>
              </li>
              <li>
                CUSTOMER DATA
                <ol>
                  <li>
                    The Customer shall own all right, title and interest in and to all of the Customer Data and shall
                    have sole responsibility for the legality, reliability, integrity, accuracy and quality of the
                    Customer Data. Without prejudice to the generality of clause 6.2, the Customer will ensure that it
                    has all necessary and appropriate consents and notices in place to enable lawful transfer of the
                    Customer Personal Data to the Supplier, as required by the Data Protection Legislation.
                  </li>
                  <li>
                    The Supplier and the Customer acknowledge that for the purposes of the Data Protection Legislation,
                    the Customer is the data controller and the Supplier is the data processor.
                  </li>
                  <li>
                    The Data Processing Schedule sets out the scope, nature and purpose of the processing by the
                    Supplier, the duration of the processing and the types of personal data and categories of data
                    subject.
                  </li>
                  <li>
                    The parties shall both comply with all applicable requirements of the Data Protection Legislation.
                    This clause 6 is in addition to, and does not relieve, remove or replace, either party’s obligations
                    under the Data Protection Legislation.
                  </li>
                  <li>
                    In respect of the Customer Personal Data, unless otherwise required by applicable laws or other
                    regulatory requirements, the Suppler shall:
                    <ul>
                      <li>
                        process the Customer Personal Data only in accordance with the Customer’s lawful written
                        instructions, in order to provide the Customer with the Services and in accordance with the Data
                        Protection Legislation;
                      </li>
                      <li>
                        disclose the Customer Personal Data to courts, government agencies and other third parties as
                        and to the extent required by law;
                      </li>
                      <li>
                        maintain commercially reasonable and appropriate security measures including administrative,
                        physical and technical safeguards to protect against unauthorised or unlawful processing of any
                        Customer Personal Data and against accidental loss or destruction of, or damage to, such
                        Customer Personal Data;
                      </li>
                      <li>
                        at the choice of the Customer, return or delete all the Customer Personal Data upon the
                        termination of this agreement;
                      </li>
                      <li>
                        ensure that only those personnel who need to have access to the Customer Personal Data are
                        granted access to it and that all of the personnel authorised to process the Customer Personal
                        Data are bound by a duty of confidentiality;
                      </li>
                      <li>
                        only transfer the Customer Personal Data to a country or territory outside the European Economic
                        Area in accordance with Data Protection Legislation;
                      </li>
                      <li>
                        at the Customer’s cost, allow for and contribute to audits, including inspections in respect of
                        the same, conducted by the Customer or another auditor mandated by the Customer, provided that:
                        <ul>
                          <li type="i">
                            the Customer shall be permitted to conduct no more than one such audit or inspection in any
                            calendar year; and
                          </li>
                          <li type="i">
                            the Customer shall provide reasonable written notice of such audit which shall take place at
                            a date and time agreed by the parties.
                          </li>
                        </ul>
                      </li>
                      <li>
                        notify the Customer if the Supplier appoints a sub-processor (but only if the Customer given the
                        Supplier its prior written consent, such consent not to be reasonably withheld or delayed) and
                        ensure any agreement entered into with the relevant sub-processor includes similar terms as the
                        terms set out in this clause 6;
                      </li>
                      <li>
                        notify the Customer , without undue delay, in the event that the Supplier reasonably believes
                        that there has been a personal data breach in respect of the Customer Personal Data; and
                      </li>
                      <li>
                        assist the Customer, at the Customer’s cost, in responding to any request from a relevant data
                        subject and in ensuring compliance with its obligations under the Data Protection Legislation
                        with respect to security, breach notifications, impact assessments and consultations with
                        supervisory authorities or regulators.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                SUPPLIER’S OBLIGATIONS
                <ol>
                  <li>
                    The Supplier undertakes that the Services will be performed substantially in accordance with the
                    Documentation and with reasonable skill and care.
                  </li>
                  <li>
                    The Supplier does not warrant that the Customer’s use of the Services will be uninterrupted or
                    error-free; or that the Services, Documentation and/or the information obtained by the Customer
                    through the Services will meet the Customer’s requirements.
                  </li>
                </ol>
              </li>
              <li>
                CUSTOMER OBLIGATIONS
                <ol>
                  <li>
                    The Customer shall:
                    <ul>
                      <li>
                        provide the Supplier with:
                        <ul>
                          <li type="i">all necessary co-operation in relation to this agreement;</li>
                          <li type="i">
                            all necessary access to such information as may be required by the Supplier, in particular
                            Software access to the Authorised Account(s) as detailed in clause 5, in order to provide
                            the Services, including but not limited to Customer Data, Authorised Account(s) information,
                            security access information and configuration services;
                          </li>
                        </ul>
                      </li>
                      <li>
                        comply with all applicable laws and regulations with respect to its activities under this
                        agreement;
                      </li>
                      <li>
                        comply with the Google terms of service which are applicable to its Google DV360 Account(s);
                      </li>
                      <li>
                        carry out all other Customer responsibilities set out in this agreement in a timely and
                        efficient manner. In the event of any delays in the Customer’s provision of such assistance as
                        agreed by the parties, the Supplier may adjust any agreed timetable or delivery schedule as
                        reasonably necessary;
                      </li>
                      <li>
                        ensure that the Authorised Users use the Services and the Documentation in accordance with the
                        terms and conditions of this agreement and shall be responsible for any Authorised User’s breach
                        of the same;
                      </li>
                      <li>
                        obtain and shall maintain all necessary licences, consents, and permissions necessary for the
                        Supplier, its contractors and agents to perform their obligations under this agreement,
                        including without limitation the Services;
                      </li>
                      <li>
                        ensure that its network and systems comply with the relevant specifications provided by the
                        Supplier from time to time;
                      </li>
                      <li>
                        be solely responsible for procuring and maintaining its network connections and
                        telecommunications links from its systems to the Supplier’s data centres, and all problems,
                        conditions, delays, delivery failures and all other loss or damage arising from or relating to
                        the Customer’s network connections or telecommunications links or caused by the internet; and
                      </li>
                      <li>
                        not resell the Services or otherwise seek to gain commercial advantage from the same other than
                        as specified in this agreement.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                CHARGES AND PAYMENT
                <ol>
                  <li>
                    On expiration of the Trial Period, the Customer shall pay the Subscription Fees to the Supplier for
                    the User Subscriptions in respect of the Initial Subscription Term in accordance with this clause 9.
                  </li>
                  <li>
                    The Customer shall on the Effective Date provide to the Supplier valid, up-to-date and complete
                    credit card details or approved purchase order information acceptable to the Supplier and any other
                    relevant valid, up-to-date and complete contact and billing details and, if the Customer provides:
                    <ul>
                      <li>
                        its credit card details to the Supplier, the Customer hereby authorises the Supplier to bill
                        such credit card:
                        <ul>
                          <li type="i">
                            on the expiration of the Trial Period, and in accordance with clause 2.2, for the
                            Subscription Fees payable in respect of the Initial Subscription Term; and
                          </li>
                          <li type="i">
                            subject to clause 13.1, on each anniversary of the Effective Date, for the Subscription Fees
                            payable in respect of the next Renewal Period; or
                          </li>
                        </ul>
                      </li>
                      <li>
                        its approved purchase order information to the Supplier, the Supplier shall invoice the
                        Customer:
                        <ul>
                          <li type="i">
                            on the expiration of the Trial Period, for the Subscription Fees payable in respect of the
                            Initial Subscription Term; and
                          </li>
                          <li type="i">
                            subject to clause 13.1, at least 30 days prior to each anniversary of the Effective Date for
                            the Subscription Fees payable in respect of the next Renewal Period, and the Customer shall
                            pay each invoice within 30 days after the date of such invoice.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    If the Supplier has not received payment in accordance with clause 9.2, and without prejudice to any
                    other rights and remedies of the Supplier:
                    <ul>
                      <li>
                        the Supplier may, without liability to the Customer, disable the Customer’s password, account
                        and access to all or part of the Services and the Supplier shall be under no obligation to
                        provide any or all of the Services while the invoice(s) concerned remain unpaid; and
                      </li>
                      <li>
                        interest shall accrue on a daily basis on such due amounts at an annual rate equal to 3% over
                        the then current base lending rate of the Supplier’s bankers in the UK from time to time,
                        commencing on the due date and continuing until fully paid, whether before or after judgment.
                      </li>
                    </ul>
                  </li>
                  <li>
                    All amounts and fees stated or referred to in this agreement shall be payable in pounds sterling;
                    are, subject to clause 12.3(b), non-cancellable and non-refundable; and are exclusive of value added
                    tax, which shall be added (where applicable) to the Supplier’s invoice(s) at the appropriate rate.
                  </li>
                </ol>
              </li>
              <li>
                PROPRIETARY RIGHTS
                <p>
                  The Customer acknowledges and agrees that the Supplier and/or its licensors own all intellectual
                  property rights in the Services and the Documentation. Except as expressly stated herein, this
                  agreement does not grant the Customer any rights to, or in, patents, copyright, database right, trade
                  secrets, trade names, trade marks (whether registered or unregistered), or any other rights or
                  licences in respect of the Services or the Documentation.
                </p>
              </li>
              <li>
                CONFIDENTIALITY
                <ol>
                  <li>
                    Each party may be given access to Confidential Information from the other party in order to perform
                    its obligations under this agreement. A party’s Confidential Information shall not be deemed to
                    include information that is or becomes publicly known other than through any act or omission of the
                    receiving party; was in the other party’s lawful possession before the disclosure; is lawfully
                    disclosed to the receiving party by a third party without restriction on disclosure; or is required
                    to be disclosed by law, by any court of competent jurisdiction or by any regulatory or
                    administrative body.
                  </li>
                  <li>
                    Each party shall hold the other’s Confidential Information in confidence and, unless required by
                    law, not make the other’s Confidential Information available to any third party, or use the other’s
                    Confidential Information for any purpose other than the implementation of agreement.
                  </li>
                  <li>
                    Each party shall take all reasonable steps to ensure that the other’s Confidential Information to
                    which it has access is not disclosed or distributed by its employees or agents in violation of the
                    terms of this agreement.
                  </li>
                  <li>
                    The Customer acknowledges that details of the Services, and the results of any performance tests of
                    the Services, constitute the Supplier’s Confidential Information.
                  </li>
                  <li>
                    The Supplier acknowledges that the Customer Data is the Confidential Information of the Customer.
                  </li>
                  <li>This clause 11 shall survive termination of this agreement, however arising.</li>
                  <li>
                    No party shall make, or permit any person to make, any public announcement concerning this agreement
                    without the prior written consent of the other parties (such consent not to be unreasonably withheld
                    or delayed), except as required by law, any governmental or regulatory authority (including, without
                    limitation, any relevant securities exchange), any court or other authority of competent
                    jurisdiction.
                  </li>
                </ol>
              </li>
              <li>
                LIMITATION OF LIABILITY
                <ol>
                  <li>
                    1. This clause 12 sets out the entire financial liability of the Supplier (including any liability
                    for the acts or omissions of its employees, agents and sub-contractors) to the Customer:
                    <ul>
                      <li>arising under or in connection with this agreement;</li>
                      <li>
                        in respect of any use made by the Customer of the Services and Documentation or any part of
                        them; and
                      </li>
                      <li>
                        in respect of any representation, statement or tortious act or omission (including negligence)
                        arising under or in connection with this agreement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Nothing in this agreement excludes the liability of the Supplier:
                    <ul>
                      <li>for death or personal injury caused by the Supplier’s negligence; or</li>
                      <li>for fraud or fraudulent misrepresentation.</li>
                    </ul>
                  </li>
                  <li>
                    Subject to clause 12.2 and clause 12.3:
                    <ul>
                      <li>
                        the Supplier shall not be liable whether in tort (including for negligence or breach of
                        statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits,
                        loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or
                        information, or pure economic loss, or for any special, indirect or consequential loss, costs,
                        damages, charges or expenses however arising under this agreement including through a defect or
                        error in the functionality of the Software or Software code; and
                      </li>
                      <li>
                        the Supplier’s total aggregate liability in contract, tort (including negligence or breach of
                        statutory duty), misrepresentation, restitution or otherwise, arising in connection with the
                        performance or contemplated performance of this agreement shall be limited to the total
                        Subscription Fees paid or payable for the User Subscriptions during the 12 months immediately
                        preceding the date on which the claim arose (or a pro-rata amount thereof for a Subscription
                        Term that is less than 12 months long).
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                TERM AND TERMINATION
                <ol>
                  <li>
                    Subject to clause 2 (Trial Period), this agreement shall commence on the Effective Date and shall
                    continue for the Initial Subscription Term and, thereafter, this agreement shall be automatically
                    renewed for successive periods of 12 months (each a “Renewal Period”), unless terminated by either
                    party:
                    <ul>
                      <li>
                        after the expiration of the Initial Subscription Term by giving to the other not less than 60
                        (sixty) days’ prior written notice; or
                      </li>
                      <li>
                        otherwise in accordance with the provisions of this agreement, and the Initial Subscription Term
                        together with any subsequent Renewal Period(s) shall constitute the Subscription Term.
                      </li>
                    </ul>
                  </li>
                  <li>
                    On termination of this agreement howsoever caused the rights and duties created by clauses 6
                    (Customer Data), 11 (Confidentiality) and 12 (Limitation on Liability) and this clause 13.2 shall
                    survive and any rights of either party which arose on or before termination shall also be
                    unaffected.
                  </li>
                  <li>
                    Either party may terminate agreement with immediate effect by giving written notice to the other if:
                    <ul>
                      <li>
                        the other party commits a material breach of this agreement and (if such breach is remediable)
                        fails to remedy that breach within 14 days of being notified to do so; or
                      </li>
                      <li>
                        the other party is deemed unable to pay its debts as they fall due, or suspends or ceases (or
                        threatens to suspend or cease) to carry on a significant part of its business.
                      </li>
                    </ul>
                  </li>
                  <li>
                    On termination of this agreement for any reason:
                    <ul>
                      <li>all licences granted under this agreement shall immediately terminate;</li>
                      <li>
                        any sums due under this agreement shall become immediately payable or, as the case may be,
                        relevant sums shall be refunded in accordance with this agreement;
                      </li>
                      <li>
                        each party shall return and make no further use of any equipment, property, Documentation and
                        other items (and all copies of them) belonging to the other party pursuant to this agreement;
                      </li>
                      <li>
                        any rights, remedies, obligations or liabilities of the parties that have accrued up to the date
                        of termination, including the right to claim damages in respect of any breach of this agreement
                        which existed at or before the date of termination shall not be affected or prejudiced.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                FORCE MAJEURE
                <p>
                  The Supplier shall have no liability to the Customer under this agreement, in particular for any
                  delays, delivery failures, or any other loss or damage resulting from the transfer of data over
                  communications networks and facilities, including the internet, if it is prevented from or delayed in
                  performing its obligations under this agreement, or from carrying on its business, by acts, events,
                  omissions or accidents beyond its reasonable control, including, without limitation, strikes,
                  lock-outs or other industrial disputes (whether involving the workforce of the Supplier or any other
                  party), failure of a utility service or transport or telecommunications network, act of God, war,
                  riot, civil commotion, malicious damage, compliance with any law or governmental order, rule,
                  regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of
                  suppliers or sub-contractors, provided that the Customer is notified of such an event and its expected
                  duration.
                </p>
              </li>
              <li>
                VARIATION
                <p>
                  No variation of this agreement shall be effective unless it is in writing and signed by the parties
                  (or their authorised representatives).
                </p>
              </li>
              <li>
                WAIVER
                <p>
                  No failure or delay by a party to exercise any right or remedy provided under this agreement or by law
                  shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the
                  further exercise of that or any other right or remedy. No single or partial exercise of such right or
                  remedy shall prevent or restrict the further exercise of that or any other right or remedy.
                </p>
              </li>
              <li>
                RIGHTS AND REMEDIES
                <p>
                  Except as expressly provided in this agreement the rights and remedies provided under this agreement
                  are in addition to, and not exclusive of, any rights or remedies provided by law.
                </p>
              </li>
              <li>
                SEVERANCE
                <ol>
                  <li>
                    1. If any provision (or part of a provision) of agreement is found by any court or administrative
                    body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall
                    remain in force.
                  </li>
                  <li>
                    If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part
                    of it were deleted, the provision shall apply with whatever modification is necessary to give effect
                    to the commercial intention of the parties.
                  </li>
                </ol>
              </li>
              <li>
                ENTIRE AGREEMENT
                <p>
                  This agreement entered into hereunder supersedes any prior contracts, arrangements and undertakings
                  between the parties in relation to its or their subject matter and constitute the entire contract
                  between the parties relating to the subject matter. The Customer warrants that it has not entered into
                  this agreement, in reliance on any warranty or representation unless set out therein. No party shall
                  have any claim for innocent or negligent misrepresentation based upon any statement in this agreement.
                </p>
              </li>
              <li>
                ASSIGNMENT
                <ol>
                  <li>
                    The Customer shall not, without the prior written consent of the Supplier, assign, transfer, charge,
                    sub-contract or deal in any other manner with all or any of its rights or obligations under this
                    agreement.
                  </li>
                  <li>
                    The Supplier may at any time assign, transfer, charge, sub-contract or deal in any other manner with
                    all or any of its rights or obligations under this agreement.
                  </li>
                </ol>
              </li>
              <li>
                THIRD PARTY RIGHTS
                <p>
                  The parties hereby exclude to the fullest extent permitted by law any rights of third parties to
                  enforce or rely upon any of the provisions of this agreement.
                </p>
              </li>
              <li>
                NOTICES
                <ol>
                  <li>
                    Any notice required to be given under this agreement (as applicable) shall be in writing and shall
                    be delivered by hand or sent by pre-paid first-class post or recorded delivery post to the other
                    party at its address set out in this agreement or such other address as may have been notified by
                    that party for such purposes, or sent by fax to the other party’s fax number as may have been
                    notified by that party for such purposes, or by read-receipt requested email to a nominated email
                    address.
                  </li>
                  <li>
                    A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is
                    not in business hours, at 9 am on the first day following delivery). A correctly addressed notice
                    sent by pre-paid first-class post or recorded delivery post shall be deemed to have been received at
                    the time at which it would have been delivered in the normal course of post. A notice sent by fax
                    shall be deemed to have been received at the time of transmission (as shown by the timed printout
                    obtained by the sender). A notice sent by email shall be deemed to have been received upon
                    acceptance of the read request.
                  </li>
                  <li>
                    Nothing contained in this clause 22 shall apply in the service of proceedings or other documents in
                    any legal action.
                  </li>
                </ol>
              </li>
              <li>
                GOVERNING LAW & JURISDICTION
                <p>
                  This agreement shall be governed and construed in accordance with the laws of England and any dispute
                  arising under this agreement shall be submitted to the exclusive jurisdiction of the English Courts.
                </p>
              </li>
            </ol>
            <h1>APPENDIX 1</h1>
            <p>Data Processing Schedule</p>
            <p>
              This Appendix 1 includes certain details of the processing of Customer Personal Data as required by
              Article 28(3) of the GDPR.
            </p>
            <ol>
              <li>
                <strong>Subject matter and duration of the processing of Customer Personal Data</strong>
                <p>
                  The subject matter and duration of the processing of the Customer Personal Data are set out in this
                  agreement.
                </p>
              </li>
              <li>
                <strong>The nature and purpose of the processing of Customer Personal Data</strong>
                <p>
                  The full name and email addresses of the Authorised Users are required to enable such Authorised Users
                  to access the ‘run-script.com’. The email addresses associated with the Authorised Accounts are
                  required to provide the Services to the Customer’s Google DV360 Account(s) to which a User
                  Subscription is “linked”.
                </p>
              </li>
              <li>
                <strong>The types of Customer Personal Data to be Processed</strong>
                <ol>
                  <li>
                    Personal Data:
                    <ul>
                      <li>The full name and email addresses of the Authorised Users.</li>
                      <li>Email addresses associated with the Authorised Accounts.</li>
                    </ul>
                  </li>
                  <li>
                    Special Personal Data:
                    <ul>
                      <li>None.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <strong>The categories of data subject to whom the Customer Personal Data relates</strong>
                <p>
                  Employees, agents and independent contractors of the Customer who are authorised by the Customer to
                  use the Services and the Documentation.
                </p>
              </li>
              <li>
                <strong>The obligations and rights of the Customer</strong>
                <p>The obligations and rights of the Customer are set out in this agreement.</p>
              </li>
            </ol>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default TermsContent;
